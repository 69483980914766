@font-face {
    font-family: "iconfont";
    src: url('../icons/font/iconfont.woff2') format('woff2'),
    url('../icons/font/iconfont.woff') format('woff'),
    url('../icons/font/iconfont.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.icon {
    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

.icon-lg {
    font-size: 1.3333333333333333em;
    line-height: 0.75em;
    vertical-align: -15%;
}
.icon-2x { font-size: 2em; }
.icon-3x { font-size: 3em; }
.icon-4x { font-size: 4em; }
.icon-5x { font-size: 5em; }
.icon-fw {
    width: 1.2857142857142858em;
    text-align: center;
}

.icon-ul {
    padding-left: 0;
    margin-left: 2.14285714em;
    list-style-type: none;
}
.icon-ul > li {
    position: relative;
}
.icon-li {
    position: absolute;
    left: -2.14285714em;
    width: 2.14285714em;
    top: 0.14285714em;
    text-align: center;
}
.icon-li.icon-lg {
    left: -1.85714286em;
}

.icon-rotate-90 {
    transform: rotate(90deg);
}
.icon-rotate-180 {
    transform: rotate(180deg);
}
.icon-rotate-270 {
    transform: rotate(270deg);
}
.icon-flip-horizontal {
    transform: scale(-1, 1);
}
.icon-flip-vertical {
    transform: scale(1, -1);
}
:root .icon-rotate-90,
:root .icon-rotate-180,
:root .icon-rotate-270,
:root .icon-flip-horizontal,
:root .icon-flip-vertical {
  filter: none;
}

.icon-altitude-up-down:before { content: "\EA01" }
.icon-altitude:before { content: "\EA02" }
.icon-apple:before { content: "\EA03" }
.icon-arrow-down-circle:before { content: "\EA04" }
.icon-arrow-down:before { content: "\EA05" }
.icon-arrow-left-circle:before { content: "\EA06" }
.icon-arrow-left-dash:before { content: "\EA07" }
.icon-arrow-left:before { content: "\EA08" }
.icon-arrow-right-circle:before { content: "\EA09" }
.icon-arrow-right-dash:before { content: "\EA0A" }
.icon-audience:before { content: "\EA0B" }
.icon-barrierefrei:before { content: "\EA0C" }
.icon-bed-2:before { content: "\EA0D" }
.icon-bed:before { content: "\EA0E" }
.icon-beginn:before { content: "\EA0F" }
.icon-bergstation:before { content: "\EA10" }
.icon-board:before { content: "\EA11" }
.icon-brand-form:before { content: "\EA12" }
.icon-calendar:before { content: "\EA13" }
.icon-camara:before { content: "\EA14" }
.icon-check:before { content: "\EA15" }
.icon-checkmark:before { content: "\EA16" }
.icon-class:before { content: "\EA17" }
.icon-clock:before { content: "\EA18" }
.icon-close-thin:before { content: "\EA19" }
.icon-close:before { content: "\EA1A" }
.icon-closed:before { content: "\EA1B" }
.icon-cocktail:before { content: "\EA1C" }
.icon-culture:before { content: "\EA1D" }
.icon-data:before { content: "\EA1E" }
.icon-difficulty:before { content: "\EA1F" }
.icon-difficulty01:before { content: "\EA20" }
.icon-difficulty02:before { content: "\EA21" }
.icon-difficulty03:before { content: "\EA22" }
.icon-doorHeight:before { content: "\EA23" }
.icon-down:before { content: "\EA24" }
.icon-download:before { content: "\EA25" }
.icon-eating:before { content: "\EA26" }
.icon-email-new:before { content: "\EA27" }
.icon-email:before { content: "\EA28" }
.icon-end:before { content: "\EA29" }
.icon-events:before { content: "\EA2A" }
.icon-facebook:before { content: "\EA2B" }
.icon-family:before { content: "\EA2C" }
.icon-file:before { content: "\EA2D" }
.icon-filter:before { content: "\EA2E" }
.icon-flower:before { content: "\EA2F" }
.icon-galaDinner:before { content: "\EA30" }
.icon-google-plus:before { content: "\EA31" }
.icon-heart-filled:before { content: "\EA32" }
.icon-heart:before { content: "\EA33" }
.icon-highest-point:before { content: "\EA34" }
.icon-highlights:before { content: "\EA35" }
.icon-horseshoe:before { content: "\EA36" }
.icon-info-circle:before { content: "\EA37" }
.icon-info:before { content: "\EA38" }
.icon-instagram:before { content: "\EA39" }
.icon-kabinenbahn:before { content: "\EA3A" }
.icon-kastanie:before { content: "\EA3B" }
.icon-kerze:before { content: "\EA3C" }
.icon-key:before { content: "\EA3D" }
.icon-length:before { content: "\EA3E" }
.icon-lift:before { content: "\EA3F" }
.icon-link:before { content: "\EA40" }
.icon-linked-in:before { content: "\EA41" }
.icon-list:before { content: "\EA42" }
.icon-list2:before { content: "\EA43" }
.icon-map:before { content: "\EA44" }
.icon-menu:before { content: "\EA45" }
.icon-mixed-lift:before { content: "\EA46" }
.icon-mobile:before { content: "\EA47" }
.icon-moon:before { content: "\EA48" }
.icon-mouse:before { content: "\EA49" }
.icon-nachhaltigkeit:before { content: "\EA4A" }
.icon-open:before { content: "\EA4B" }
.icon-paperclip:before { content: "\EA4C" }
.icon-paperplane:before { content: "\EA4D" }
.icon-pdf:before { content: "\EA4E" }
.icon-peak:before { content: "\EA4F" }
.icon-person:before { content: "\EA50" }
.icon-persons:before { content: "\EA51" }
.icon-pfeil-kreis:before { content: "\EA52" }
.icon-pflanze:before { content: "\EA53" }
.icon-phone:before { content: "\EA54" }
.icon-pin:before { content: "\EA55" }
.icon-pinterest:before { content: "\EA56" }
.icon-pisten-km:before { content: "\EA57" }
.icon-play:before { content: "\EA58" }
.icon-plus-medium:before { content: "\EA59" }
.icon-plus-thin:before { content: "\EA5A" }
.icon-plus:before { content: "\EA5B" }
.icon-rollstuhlfahrer:before { content: "\EA5C" }
.icon-schraegaufzug:before { content: "\EA5D" }
.icon-scroll:before { content: "\EA5E" }
.icon-search:before { content: "\EA5F" }
.icon-seilbahn:before { content: "\EA60" }
.icon-sessellift:before { content: "\EA61" }
.icon-share:before { content: "\EA62" }
.icon-shop:before { content: "\EA63" }
.icon-shopping:before { content: "\EA64" }
.icon-single-person:before { content: "\EA65" }
.icon-size:before { content: "\EA66" }
.icon-skifahrer:before { content: "\EA67" }
.icon-snow:before { content: "\EA68" }
.icon-speech:before { content: "\EA69" }
.icon-squaremeter:before { content: "\EA6A" }
.icon-star-filled:before { content: "\EA6B" }
.icon-star:before { content: "\EA6C" }
.icon-stopwatch:before { content: "\EA6D" }
.icon-sun:before { content: "\EA6E" }
.icon-tag:before { content: "\EA6F" }
.icon-tellerlift:before { content: "\EA70" }
.icon-tip:before { content: "\EA71" }
.icon-twitter:before { content: "\EA72" }
.icon-umlaufbahn:before { content: "\EA73" }
.icon-unterirdische-bahn:before { content: "\EA74" }
.icon-up:before { content: "\EA75" }
.icon-weather-sun:before { content: "\EA76" }
.icon-website:before { content: "\EA77" }
.icon-wellness:before { content: "\EA78" }
.icon-whatsapp:before { content: "\EA79" }
.icon-wind:before { content: "\EA7A" }
.icon-www:before { content: "\EA7B" }
.icon-youtube:before { content: "\EA7C" }
.icon-zahnradbahn:before { content: "\EA7D" }


:root {
--icon-altitude-up-down: "\EA01";
    --icon-altitude: "\EA02";
    --icon-apple: "\EA03";
    --icon-arrow-down-circle: "\EA04";
    --icon-arrow-down: "\EA05";
    --icon-arrow-left-circle: "\EA06";
    --icon-arrow-left-dash: "\EA07";
    --icon-arrow-left: "\EA08";
    --icon-arrow-right-circle: "\EA09";
    --icon-arrow-right-dash: "\EA0A";
    --icon-audience: "\EA0B";
    --icon-barrierefrei: "\EA0C";
    --icon-bed-2: "\EA0D";
    --icon-bed: "\EA0E";
    --icon-beginn: "\EA0F";
    --icon-bergstation: "\EA10";
    --icon-board: "\EA11";
    --icon-brand-form: "\EA12";
    --icon-calendar: "\EA13";
    --icon-camara: "\EA14";
    --icon-check: "\EA15";
    --icon-checkmark: "\EA16";
    --icon-class: "\EA17";
    --icon-clock: "\EA18";
    --icon-close-thin: "\EA19";
    --icon-close: "\EA1A";
    --icon-closed: "\EA1B";
    --icon-cocktail: "\EA1C";
    --icon-culture: "\EA1D";
    --icon-data: "\EA1E";
    --icon-difficulty: "\EA1F";
    --icon-difficulty01: "\EA20";
    --icon-difficulty02: "\EA21";
    --icon-difficulty03: "\EA22";
    --icon-doorHeight: "\EA23";
    --icon-down: "\EA24";
    --icon-download: "\EA25";
    --icon-eating: "\EA26";
    --icon-email-new: "\EA27";
    --icon-email: "\EA28";
    --icon-end: "\EA29";
    --icon-events: "\EA2A";
    --icon-facebook: "\EA2B";
    --icon-family: "\EA2C";
    --icon-file: "\EA2D";
    --icon-filter: "\EA2E";
    --icon-flower: "\EA2F";
    --icon-galaDinner: "\EA30";
    --icon-google-plus: "\EA31";
    --icon-heart-filled: "\EA32";
    --icon-heart: "\EA33";
    --icon-highest-point: "\EA34";
    --icon-highlights: "\EA35";
    --icon-horseshoe: "\EA36";
    --icon-info-circle: "\EA37";
    --icon-info: "\EA38";
    --icon-instagram: "\EA39";
    --icon-kabinenbahn: "\EA3A";
    --icon-kastanie: "\EA3B";
    --icon-kerze: "\EA3C";
    --icon-key: "\EA3D";
    --icon-length: "\EA3E";
    --icon-lift: "\EA3F";
    --icon-link: "\EA40";
    --icon-linked-in: "\EA41";
    --icon-list: "\EA42";
    --icon-list2: "\EA43";
    --icon-map: "\EA44";
    --icon-menu: "\EA45";
    --icon-mixed-lift: "\EA46";
    --icon-mobile: "\EA47";
    --icon-moon: "\EA48";
    --icon-mouse: "\EA49";
    --icon-nachhaltigkeit: "\EA4A";
    --icon-open: "\EA4B";
    --icon-paperclip: "\EA4C";
    --icon-paperplane: "\EA4D";
    --icon-pdf: "\EA4E";
    --icon-peak: "\EA4F";
    --icon-person: "\EA50";
    --icon-persons: "\EA51";
    --icon-pfeil-kreis: "\EA52";
    --icon-pflanze: "\EA53";
    --icon-phone: "\EA54";
    --icon-pin: "\EA55";
    --icon-pinterest: "\EA56";
    --icon-pisten-km: "\EA57";
    --icon-play: "\EA58";
    --icon-plus-medium: "\EA59";
    --icon-plus-thin: "\EA5A";
    --icon-plus: "\EA5B";
    --icon-rollstuhlfahrer: "\EA5C";
    --icon-schraegaufzug: "\EA5D";
    --icon-scroll: "\EA5E";
    --icon-search: "\EA5F";
    --icon-seilbahn: "\EA60";
    --icon-sessellift: "\EA61";
    --icon-share: "\EA62";
    --icon-shop: "\EA63";
    --icon-shopping: "\EA64";
    --icon-single-person: "\EA65";
    --icon-size: "\EA66";
    --icon-skifahrer: "\EA67";
    --icon-snow: "\EA68";
    --icon-speech: "\EA69";
    --icon-squaremeter: "\EA6A";
    --icon-star-filled: "\EA6B";
    --icon-star: "\EA6C";
    --icon-stopwatch: "\EA6D";
    --icon-sun: "\EA6E";
    --icon-tag: "\EA6F";
    --icon-tellerlift: "\EA70";
    --icon-tip: "\EA71";
    --icon-twitter: "\EA72";
    --icon-umlaufbahn: "\EA73";
    --icon-unterirdische-bahn: "\EA74";
    --icon-up: "\EA75";
    --icon-weather-sun: "\EA76";
    --icon-website: "\EA77";
    --icon-wellness: "\EA78";
    --icon-whatsapp: "\EA79";
    --icon-wind: "\EA7A";
    --icon-www: "\EA7B";
    --icon-youtube: "\EA7C";
    --icon-zahnradbahn: "\EA7D";
    
}