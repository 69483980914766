.text-secondary { color: var(--color-secondary); }
.text-green { color: var(--color-green); }
.text-white,
a.text-white:hover,
a.text-white:focus {
    color: #ffffff;
}
.bg-white { background-color: #ffffff; }

.text-trustYou { color: #009b00; }
.bg-trustYou { 
    background-color: #009b00;
    color: #FFFFFF;
}

.bg-trip-planer {
    background: #ce1141;
}



/* südtirol brand colors */
.text-default {color:var(--color-text-default);}
.text-primary {color: var(--color-primary)}
.text-highlight {color: var(--color-highlight) }
.text-green {color: var(--color-green) }
.text-lightblue {color: var(--color-light-blue) }
.text-lightgreen {color: var(--color-light-green) }
.text-yellow {color: var(--color-yellow) }
.text-lila {color: var(--color-lila) }
