.number-spinner .number-spinner__input {
    padding: calc(5rem / 16);
    text-align: center;
    width: calc(38rem / 16);
    font-family: var(--font-default-bold);
    background: transparent;
    border: 0;

    @media screen and (min-width: 768px) {
        width: calc(50rem / 16);
    }
}
.number-spinner input::-webkit-outer-spin-button,
.number-spinner input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
/* Firefox */
.number-spinner input[type=number] {
    -moz-appearance: textfield;
}
.btn.btn-number-spinner,
.btn-number-spinner {
    text-indent: unset;
    min-width: unset;
    margin-right: 0;
    background: transparent;
    padding: 0;
    color: var(--color-dark);
    overflow: hidden;
    position: relative;
    width: calc(38rem / 16);
    height: calc(38rem / 16);

    @media screen and (min-width: 768px) {
        width: calc(48rem / 16);
        height: calc(48rem / 16);
    }
}
.btn.btn-number-spinner:after {
    content: var(--icon-brand-form);
    font-family: iconfont;
    color: var(--color-light-grey);
    z-index: -1;
    width: auto;
    height: auto;
    right: auto;
    font-size: calc(38rem / 16);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media screen and (min-width: 768px) {
        font-size: calc(48rem / 16);
    }
}
.btn.btn-number-spinner--md:after {
    font-size: calc(32rem / 16);
    @media screen and (min-width: 768px) {
        font-size: calc(40rem / 16);
    }
}
.btn.btn-number-spinner--white:after {
    color: #fff;
}
.btn-number-spinner__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    font-size: calc(28rem / 16);
}
.btn-number-spinner:hover {
    color: var(--color-primary);
}
.btn.btn-number-spinner[disabled],
.btn.btn-number-spinner[disabled]:hover {
    opacity: .8;
    color: var(--color-dark);
}