.filter-form .form-control-icon {
    right: 24px;
    font-size: 1.5rem;

    @media screen and (max-width: 767px) {
        font-size: 1.15rem;
        right: 1rem;
    }
}

.filter-form .filter-input {
    padding:.75rem 1.5rem;

    @media screen and (max-width: 767px) {
        padding-right: 3rem;
    }
}

@media screen and (max-width: 767px) {
    .filter-form .filter-input--typeahead-xs {
        padding-left: 0;
        border:0;
        color:#fff;
        background: transparent !important;
    }
    .filter-form .filter-input--typeahead-xs::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color:#fff;
    }

    .filter-form .filter-input--typeahead-xs:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color:#fff;
    }

    .filter-form .filter-input--typeahead-xs::-ms-input-placeholder { /* Microsoft Edge */
        color:#fff;
    }
}

.filter-form .filter-input--dark {
    background-color: #616262;
    color: var(--color-white);
}
.filter-form .filter-input--dark::placeholder {
    color: var(--color-white);
}
.filter-form .filter-input--lightgrey {
    background-color: var(--color-light-grey);
    color: var(--color-dark);
}
.filter-form .filter-input--lightgrey::placeholder {
    color: var(--color-dark);
}

.filter-form .btn-filter{
    height: auto;
    padding: .75rem 1rem;
    font-family: var(--font-special);
    font-size: 1.25rem;
}
html:not(.has-preloaded-fonts) .filter-form .btn-filter {
    font-size: 19px;
    line-height: 1.58;
    letter-spacing: 0;
}

.available-accos {
    color:var(--color-green);
}
/*.filter-form .form-control:focus,
.filter-form .form-control:active {
    opacity: .9;
}*/

.filter-form .filter-select {
    padding-left: 1.5rem;
    text-align: center;
    background-color: var(--color-light-grey);
    color: var(--color-dark);
    font-size: 1.25rem;
    @media screen and (max-width: 767px) {
        padding: .9375rem 2.25rem .5rem 0rem;
        font-size: 1.1rem;
        background-color: transparent;
        color: #fff;
        border-color:transparent;
    }
}

@media screen and (max-width: 767px) {
    .filter-form .custom-select__select:after {
        color: #fff;
    }
}

.filter-form .filter-select option {
    @media screen and (max-width: 767px) {
        color: var(--color-dark)
    }
}

.filter-form .accordion {
    padding: 0;
    @media screen and (max-width: 767px) {
        color:#fff;
        background:transparent;
    }
}
.filter-form .accordion .card-header>a {
    padding: .5rem 1.5rem .5rem 0;
    font-size: 1.25rem !important;

    @media screen and (max-width: 767px) {
        padding:.25rem 0;
        color:inherit;
    }
}

.filter-form .accordion .card-header>a:after {
    top: .75rem;
    right:0;
    @media screen and (max-width: 767px) {
        width: 10px;
        height: 10px;
        border-color:#fff;
    }
}
.search-towns .card-body,
.filter-form .accordion .card-body {
    padding: 0;
    background:transparent;
}
.search-towns .card,
.filter-form .accordion .card {
    border: none !important;
    font-size: 1.1rem;
    max-width: 100%;
    padding:0;
    background:transparent;
    @media screen and (max-width: 991px) {
        font-size: .95rem;
    }
}


@media screen and (max-width: 767px) {
    .filter-form .custom-checkbox__box {
        border: 1px solid #fff;
        background: transparent;
    }
    .filter-form .custom-checkbox__text{
        color:#fff;
    }
}

.filter-form-results-number {
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
    margin-bottom: 1rem;

    @media screen and (max-width: 767px) {
        margin-top:.5rem;
    }
}


/* accomodation leaflet infobox */
.acco-infobox {
    position: relative;
    padding-bottom: 20px;
}
.acco-infobox__images {
    position: relative;
    margin:-15px -15px 15px -15px;
    width: 331px;
}