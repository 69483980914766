.slider:not(.slick-slider)>div+div{
    display: none;
}

.slider {
    position: relative;
}
.accordion .slider {
    visibility: hidden;
}
.slider--lh1,
.slider--acco {
    line-height:1px;
}

.slider--with-padding .slick-list {
    margin:0 -0.25rem;
}
.slider--with-padding .slick-slide {
    margin:0 0.25rem;
}

/* arrows */
.slider .slick-arrow {
    position: absolute;
    right:-10px;
    top:50%;
    transform: translateY(-50%);
    color: var(--color-text-default);
    width:50px;
    height:50px;
    background: transparent;
    border-radius:0;
    font-size: 0;
    line-height: 0;
    cursor: pointer;
    z-index: 10;
    border:0;
    @media screen and (min-width: 1350px) {
        right:-50px;
    }
}

.slider .slick-arrow.slick-disabled {
    display: none !important;
}
.slider .slick-prev {
    right:auto;
    left:-10px;
    @media screen and (min-width: 1350px) {
        right:auto;
        left:-50px;
    }
}
.slider.slider--arrows-inner .slick-arrow {
    right:0 !important;
}
.slider.slider--arrows-inner .slick-prev {
    left:0 !important;
}
.slider .slick-arrow:before {
    display: block;
    content:'';
    width:20px;
    height:20px;
    border:2px solid var(--color-text-default);
    border-width:2px 2px 0 0;
}

.slider.slider--arrows-white .slick-arrow:before {
    border-color:#fff;
    border-width:3px 3px 0 0;
}
.slider .slick-prev:before {
    position: relative;
    left: 18px;
    transform: rotate(-135deg);
}
.slider .slick-next:before {
    transform: rotate(45deg);
}
.slider.slider--hide-arrows .slick-arrow {
    display: none !important;
}


@media screen and (max-width: 1350px) {
    .slider--region.slider .slick-arrow,
    .teaser-slider .slider .slick-arrow {
        background:var(--color-light-grey);
    }
}

@media screen and (max-width: 1600px) {
    .trip-planer-slider .slick-arrow {
        background:var(--color-light-grey);
    }
    .trip-planer-slider .slick-arrow {
        right:0 !important;
    }
    .trip-planer-slider .slick-prev {
        left:0 !important;
    }
}


/* slider--opening */
.slider.slider--opening .slick-arrow {
    display: none !important;
}
.slider.slider--opening .slick-dots {
    display: block !important;
}


/* dots */
.slick-dots {
    letter-spacing: 0;
    font-size: 0;
    font-family: sans-serif;
}
@media screen and (min-width: 768px) {
    .slick-dots {
        display: none !important;
        transition: all 250ms ease;
        transform: translateY(0);
    }
}
.header-img-container .overlay-dots--qf-opened .slick-dots {
    transform:translateY(-80px);
    @media screen and (min-width: 768px) {
        transform:translateY(-100px);
    }
}
.slick-dots {
    padding: 0;
    list-style: none;
    text-align: center;
    line-height: 0;
    margin:1rem 0 0 0;
    position: relative;
}
.overlay-dots--hidden-xs .slick-dots {
    @media screen and (max-width: 767px) {
        display: none!important;
    }
}
.overlay-dots .slick-dots {
    margin:0;
    left:0;
    right:0;
    bottom:20px;
    position: absolute;
    display: block !important;
}
.slick-dots > li {
    display: inline-block;
    margin:0 5px;
}
.slick-dots > li > button {
    display: inline-block;
    position: relative;
    width:10px;
    height:10px;
    padding: 0;
    overflow: hidden;
    text-indent: -999em;
    background:#C7CED2;
    border:0;
    border-radius: 50%;
    cursor:pointer;
    outline: 0 !important;
}
.slick-dots > .slick-active > button {
    background:var(--color-highlight);
}

@media screen and (min-width: 768px) {
    .slick-dots > li > button {
        width:12px;
        height:12px;
    }
}
