.trust-you__item {
    font-size:1rem;
}

.trust-you__item:after {
    content: '';
    display: block;
    background: url(static/img/logos/trust-you-badge-logo.png) no-repeat center center;
    background-size: contain;
    width: 85px;
    height: 14px;
    margin:0 auto;
}

@media screen and (min-width: 768px) {
    .trust-you__item--lg {
        font-size:1.125rem;
    }
    .trust-you__score--lg {
        font-size:1.5rem;
    }
    .trust-you__item--lg {
        position: relative;
        top:-.375rem;
    }
    .trust-you__item--lg:after {
        margin-top:.25rem;
    }
}