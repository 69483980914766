.loading-overlay-container {
    position: relative;
    overflow: hidden;
}
div[aria-live="polite"] {
    position: relative;
}


.loading-overlay {
    color: #fff;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 999;
    text-align: center;
    background: rgba(0,0,0,.85);
    padding: 0 2rem 2rem 2rem;
    min-width:25rem;
    border-radius: 1rem;

    @media screen and (max-width: 767px) {
        position:fixed;
        top:50%;
        min-width:20rem;
        transform: translateY(-50%) translateX(-50%);
    }
}
.loading-overlay:before {
    content:'';
    position: fixed;
    left:-5000px;
    top:-5000px;
    right:-5000px;
    bottom:-5000px;
    width:auto;
    height:auto;
    background: rgba(0,0,0,.35);
    z-index: -1;
}
.loading-overlay.loading-overlay--not-fullpage {
    position: absolute;
    top: 35px;
    display: inline-block;
    z-index: auto;
    @media screen and (max-width: 767px) {
        top: 30px;
    }
}
.loading-overlay--not-fullpage:before {
    display: none;
}

/* wetter */
.weather-reload__loading .loading-overlay {
    position: absolute;
    top: 0;
    transform: translateX(-50%) translateY(0);
}
.weather-reload__loading .loading-overlay:before {
    display:none;
}
.loading-spinner {
    width: 70px;
    text-align: center;
    margin:0 auto;
}
.loading-spinner__item {
    width: 18px;
    height: 18px;
    background-color: var(--color-primary);

    border-radius: 100%;
    display: inline-block;
    animation: loading-spinner-rotate 1.4s infinite ease-in-out both;
}
.loading-spinner__item--1 {
    animation-delay: -0.32s;
}
.loading-spinner__item--2 {
    animation-delay: -0.16s;
}
@keyframes loading-spinner-rotate {
    0%, 80%, 100% {
        transform: scale(0);
    }
    40% {
        transform: scale(1.0);
    }
}
