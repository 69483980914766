:root {
    --cookie-switch-width: calc(44rem/16);
    --cookie-switch-height: calc(var(--cookie-switch-width)/44*27);
    --cookie-switch-toggle-size: calc(var(--cookie-switch-height)/27*23);
}
.switch {
    position: relative;
    display: inline-block;
    width: var(--cookie-switch-width);
    height: var(--cookie-switch-height);
    vertical-align: -0.4em;
    margin-right: .5rem;
}
.switch-toggle {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: var(--cookie-switch-height);
}
.switch-toggle:before {
    position: absolute;
    content: "";
    height: var(--cookie-switch-toggle-size);
    width: var(--cookie-switch-toggle-size);
    left: calc((var(--cookie-switch-height) - var(--cookie-switch-toggle-size)) / 2);
    bottom: calc((var(--cookie-switch-height) - var(--cookie-switch-toggle-size)) / 2);
    background-color: white;
    transition: .4s;
    border-radius: var(--cookie-switch-height);
}
input:checked + .switch-toggle {
    background-color: var(--color-yellow);
}
input:focus + .switch-toggle {
    box-shadow: 0 0 2px var(--color-yellow);
}
input:checked + .switch-toggle:before {
    transform: translateX(calc(var(--cookie-switch-width) - (var(--cookie-switch-height) - var(--cookie-switch-toggle-size)) - var(--cookie-switch-toggle-size)));
}
