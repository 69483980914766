html {
    overflow-y: scroll;
    -ms-overflow-style: scrollbar; /*prevent scrollbar from overlapping content*/
}
@media screen and (max-width: 767px) {
    html.locked,
    html.locked body {
        overflow: hidden;
    }
    html.locked body {
        position: fixed;
        left: 0;
        right: 0;
    }
}


body {
    position: relative;
    min-height: 100vh;
    background-color: #fff;
    /*background-color: #E2E7E9;*/
    overflow-x: hidden;
}
@media screen and (min-width: 768px) and (max-width: 991px) {
    body {
        width: 982px;
        overflow-x: auto;
    }
}
@media screen and (max-width: 767px) {
    body {
        min-width: 320px;
        max-width: 100%;
    }
}
/*body.nav-open {
    height:100vh;
    overflow:hidden;
}*/

body.no-headerimg .page-wrapper {
    padding-top:50px;
    @media screen and (min-width: 768px) {
        padding-top:80px;
    }
}

body.has-sticky-headerimg .page-wrapper {
    padding-top:80vh;
    @media screen and (max-width: 767px) {
        padding-top:60vh;
    }
}

.page-wrapper {
    position: relative;
    width:100%;
    margin:0 auto;
    max-width:1920px;
    background: var(--color-light-grey);
    box-shadow: 0 0 25px 0 rgba(0,0,0,.1);
}

.container.container{
    width:100%;
    max-width: 1280px;
}
.container.container--wide {
    max-width:1540px;
}
.container.container--narrow{
    max-width: 1140px;
}
.container.container--medium{
    max-width: 1040px;
}
.container.container--x-narrow{
    max-width: 780px;
}

.container.xs-padding-0 {
    @media screen and (max-width: 767px) {
        padding: 0;
    }
}