.expandable-block {
    position: relative;
    overflow: hidden;
}
.expandable-block.is-expandable {
    padding-bottom:2rem;
}
.expandable-block.is-collapsed:not(.is-not-expandable) {
    display: block;
    height: 100px;
    @media screen and (min-width: 768px) {
        height: 330px;
    }
}
.expandable-block--small.is-collapsed:not(.is-not-expandable) {
    height: 100px;
    @media screen and (min-width: 768px) {
        height: 120px;
    }
}
.expandable-block.is-expandable.is-collapsed:after {
    content:'';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 40px;
    background: linear-gradient(to bottom, rgba(241,243,244,0) 0%,rgba(241,243,244,1) 50%);

    @media screen and (min-width: 768px) {
        height: 70px;
    }
}
.expandable-block__more {
    display: none;
    padding:1rem;
    position: absolute;
    bottom: -1rem;
    left: 0;
    right: 0;
    z-index: 1;
    line-height: 1;
    color:#DE7000;
    font-size:8px;
}
.expandable-block.is-not-expandable .expandable-block__content,
.expandable-block.is-not-expandable {
    height: auto;
    overflow: visible;
}
.expandable-block.is-expandable .expandable-block__more {
    display: block;
}

.expandable-block.is-expandable:not(.is-collapsed) .expandable-block__more .icon {
    position: relative;
    transform: rotate(180deg);
}
.expandable-block.is-not-expandable .expandable-block__more {
    display: none;
}
.expandable-block.is-not-expandable:after {
    display: none;
    visibility: hidden;
}

.expandable-block--bg-secondary:after {
    background: linear-gradient(to bottom, rgba(80, 116, 47, 0) 0%, var(--color-secondary) 50%);
}