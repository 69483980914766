.weather-map__region {
    position: absolute;
    pointer-events:none;
    @media screen and (max-width: 767px) {
        display: none;
    }
}

.weather-map__region .region-weather {
    display: block;
    text-align: center;
    /*width: 10%;*/
    position: relative;
    font-family: var(--font-special);
    line-height: 1;
    width: 84px;
}

.region-weather .region-weather__img {
    display: inline-block;
    width:38px;
    height:auto;
    float:left;
    margin-right:6px;
}

.region-weather .region-weather__temp {
    display: inline-block;
    font-size:1.125rem;
    white-space: nowrap;
}

.region-weather--high {
    width: 42px;
    text-align: center;
}
.region-weather--high .region-weather__img {
    display: block;
    float:none;
    margin:0 auto;
}
.region-weather--high .region-weather__temp {
    display: block;
    white-space: normal;
}


.region-weather__temp .temp-color-min {
    color: var(--color-dark);
}

.region-weather__temp .temp-color-max {
    color:var(--color-highlight);
}

/*** region positions ***/
.weather-map__region.weather-map__region--schlanders {
    top: 62%;
    left: 8%;
}
.weather-map__region.weather-map__region--meran {
    top: 32.5%;
    left: 30%;
}
.weather-map__region.weather-map__region--bozen {
    top: 51.5%;
    left: 41%;
}
.weather-map__region.weather-map__region--sterzing {
    top: 11%;
    left: 50%;
}
.weather-map__region.weather-map__region--brixen {
    top: 45.5%;
    left: 55%;
}
.weather-map__region.weather-map__region--bruneck {
    top: 21%;
    left: 70%;
}
.weather-map__region.weather-map__region--dolomiten {
    top: 44%;
    left: auto;
    right: 8%;
}



/* DE */
body.lang-it .weather-map__region.weather-map__region--schlanders,
body.lang-de .weather-map__region.weather-map__region--schlanders {
    top: 58%;
}
body.lang-it .weather-map__region.weather-map__region--meran,
body.lang-de .weather-map__region.weather-map__region--meran {
    top: 39.5%;
}
body.lang-it .weather-map__region.weather-map__region--bozen,
body.lang-de .weather-map__region.weather-map__region--bozen {
    top: 54.5%;
}
body.lang-it .weather-map__region.weather-map__region--sterzing,
body.lang-de .weather-map__region.weather-map__region--sterzing {
    top: 12%;
}
body.lang-de .weather-map__region.weather-map__region--brixen {
    top: 31.5%;
}