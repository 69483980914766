.shariff-toggler{
    font-size:1.5rem;
}

.shariff ul {
    list-style: none;
    padding-left: 0;
}

.shariff ul li {
    display: inline-block;
    margin: .25rem;
}

.shariff ul li:not(:last-child) {
}
.share_text {
    display: none;
}
.shariff-button {
    font-family: iconfont;
    text-decoration: none;
}
.shariff-button.info {display: none}
.shariff-button a {
    position: relative;
    display: inline-block;
    text-align: center;
    width: 70px;
    height: 70px;
    border:0;
    text-decoration: none;

    @media screen and (min-width: 768px) {
        width: 120px;
        height: 90px;
    }
}
.shariff-button .fab:before,
.shariff-button .fab:after {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    font-size:52px;
    transform: translate(-50%, -50%);
    color:var(--color-text-default);
    content: var(--icon-brand-form);
    text-decoration: none;

    @media screen and (min-width: 768px) {
        font-size:66px;
    }
}
.shariff-button .fab:after {
    color:#fff;
    font-size:20px;
    @media screen and (min-width: 768px) {
        font-size:30px;
    }
}
.shariff-button:focus .fab:before,
.shariff-button:hover .fab:before {
    color:var(--color-highlight);
}
.facebook .fab:after {
    content: var(--icon-facebook);
}

.twitter .fab:after {
    content: var(--icon-twitter);
}

.whatsapp .fab:after {
    font-size:24px;
    content: var(--icon-whatsapp);
}