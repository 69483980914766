.search-error {

}
.search-error a {
    font-size: 1.125rem;
    text-decoration: underline;
}
.search-form .form-control--search {
    padding:.875rem;
}

.btn.search-btn {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    color: #fff;
    font-size: 1.5rem;
    padding: .5rem 1rem;
    margin: 0;
    text-indent: 0;
    width: auto;
    height: 54px;
    min-width: 0;
    border:2px solid var(--color-text-muted);
    background: var(--color-text-muted);
}

.btn.search-btn:hover,
.btn.search-btn:focus {
    border:2px solid var(--color-primary);
    background: var(--color-primary);
}

.search-categories {
    margin-top:1.5rem;
}