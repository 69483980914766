:root {
    --color-primary: #de7000;
    --color-highlight: #B31939;
    --color-secondary: #a9bf00;
    --color-text-default: #758592;
    --color-text-muted: #97B3C8;

    --color-default: #758592;
    --color-white: #FFF;
    --color-light-grey: #F1F3F4;
    --color-dark-grey: #E2E7E9;
    --color-dark: #262626;

    --color-black-85:rgba(0,0,0,.92);
    --color-black-60:rgba(0,0,0,.6);

    --color-success: #de7000;
    --color-info: #FFC107;
    --color-warning: #ffc107;
    --color-info-blue: #17A2B8;
    --color-danger: #ab1537;

    --color-primary-bg: #eef3cc;
    --color-spring: var(--color-primary);
    --color-summer: var(--color-yellow);
    --color-autumn: var(--color-lila);
    --color-winter: var(--color-light-blue);

    --color-green: #50742F;
    --color-light-green: #A9BF00;
    --color-light-blue: #A9CDE9;
    --color-yellow: #F4D100;
    --color-lila: #6F273F;

    --color-primary-contrast: #FFF;
    --color-secondary-contrast: #FFF;
    --color-success-contrast: #FFF;
    --color-info-contrast: var(--color-text-default);
    --color-warning-contrast: var(--color-text-default);
    --color-danger-contrast: #FFF;
    --color-light-contrast: var(--color-text-default);
    --color-dark-contrast: #FFF;
    --color-white-contrast: var(--color-text-default);
    --color-default-contrast: #FFF;

    --color-primary-dark: #cb6600;
    --color-secondary-dark: #424D56;
    --color-success-dark: #12862B;
    --color-info-dark: #967102;
    --color-warning-dark: #967102;
    --color-danger-dark: #cc2333;
    --color-light-dark: #e4e8ec;
    --color-dark-dark: #14191E;
    --color-white-dark: #F8F9FA;
    --color-default-dark: #1f232a;

    --color-primary-light: #3A88C4;
    --color-secondary-light: #6E8090;
    --color-success-light: #1EE048;
    --color-info-light: #FBBD03;
    --color-warning-light: #FBBD03;
    --color-danger-light: #e15260;
    --color-light-light: #fff;
    --color-dark-light: #687F96;
    --color-white-light: #FFF;
    --color-default-light: #3b4552;

    --font-default: "KievitWebPro",Arial, sans-serif;
    --font-default-bold: "KievitWebPro-Bold",Arial, sans-serif;
    --font-default-bold-weight: bold;
    --font-special: "suedtirolnext-regular-webfont",Arial, sans-serif;
    --font-default-medium: "KievitWebPro",Arial, sans-serif;

    --font-size-default: 16px;
}

html:not(.has-preloaded-fonts) {
    --font-default: Arial, sans-serif;
    --font-default-bold: Arial, sans-serif;
    --font-default-bold-weight: bold;
    --font-special: Arial, sans-serif;
    --font-default-medium: Arial, sans-serif;
}
