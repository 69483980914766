.category-rating {
    text-indent: 0;
    vertical-align: super;
    font-size: 11px;
    white-space: nowrap;
    display: inline-block;
}
.category-rating--star {
    font-size: 9px;
}
.category-rating--flower > .icon {
    width: 15px;
    height: 13px;
    background-image: url(static/img/flower.svg);
    background-position: center center;
    background-repeat: no-repeat;
}
.category-rating--flower > .icon:before {
    display: none;
}