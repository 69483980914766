/**
    WEATHER TEASER SLIDER
 */

.weather-map-teaser__img-container {
    position: relative;
    /*margin: 0 -.125rem;*/
}

.weather-map-teaser {
    position: relative;
    margin-bottom:1rem;
}

@media screen and (max-width: 767px) {
    .slider .weather-map-teaser {
        padding:0 .25rem;
    }
}


.weather-map-teaser__body {
    position: relative;
    /*top: -.125rem;*/
    padding: 1rem;
    color: var(--color-dark);
    background: #fff;
    font-size: .875rem;
}
.weather-date {
    font-family: var(--font-special);
    margin-top:.875rem;
}

.weather-map-teaser__body .weather-symbol__img {
    margin:0 auto;
    width: 100%;
    max-width:130px;
    position: relative;
}

.weather-map-teaser__body .weather-detail__region {
    font-size: 2rem;
}

.weather-map-teaser__body .weather-detail__temp {
    font-size: 1.625rem;
    line-height: 1;
}

.weather-detail__temp .temp-current {
    color: var(--color-highlight);
}

.weather-detail__temp .temp-current--big {
    font-size: 3.5rem;
}

@media screen and (max-width: 1299px) {
    .weather-map-teaser__body .weather-detail__temp .temp-current--big {
        font-size: 3rem;
    }
}

.weather-map-teaser__label {
    font-family: var(--font-special);
    font-size: 1.5rem;
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    text-align: center;
    white-space: nowrap;

    @media screen and (min-width: 768px) {
            padding: 1rem 1.25rem;
            background: var(--color-light-grey);
            color: var(--color-text-default);
            font-size: 1.375rem;
            border-radius: 3px;
            text-shadow: none;
        }

}

.weather-map-teaser__label > a,
.weather-map-teaser__label > a:hover,
.weather-map-teaser__label > a:focus {
    text-decoration: none;
    color: #fff;
}

/*
    WEATHER FORECAST
*/

.weather-forecast {
    color: var(--color-dark);
}

.weather-forecast .forecast-temp {
    font-size: 1.5rem;
}

.weather-forecast .temp-color-max {
    color:var(--color-highlight);
}

.weather-forecast .forecast-details {
    display: flex;
    align-items: center;
    justify-content: center;
}

.forecast-details .forecast-details__rain {
    display: inline-block;
    width: 30px;
}
.weather-forecast__img {
    display: inline-block;
    width:100%;
    max-width:125px;
    position: relative;
    margin:0 auto;
}

.weather-forecast .forecast-box {
    background-color: #fff;
}

.weather-forecast .forecast-box__hl {
    color: var(--color-text-default);
    font-size: 1.5rem;
}

@media screen and (min-width: 576px) {
    .border-left--weather-forecast {
        border-left: 4px solid var(--color-light-grey);
    }

    .border-right--weather-forecast {
        border-right: 4px solid var(--color-light-grey);
    }
}

@media screen and (min-width: 768px) {
    .border-right--weather-slider {
        border-right: 2px solid var(--color-dark-grey);
    }
}

/*
    WEATHER SLIDER
*/
.weather-slider {
    position: relative;
    background-color: #fff;
}


@media screen and (max-width: 767px) {
    .weather-slider .slick-slider {
        margin-top:2.5rem;
    }
    .weather-slider .slick-slider .slick-dots {
        position: absolute;
        left:50%;
        top:-2.5rem;
        transform: translateX(-50%);
    }
}

/*
    WEATHER REGION
*/
.weather-region .weather-detail__temp {
    font-size: 2rem;
}