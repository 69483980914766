.google-map .infoBox {
    overflow: hidden;
    background: #fff;
    padding: 15px;
    font-size: 16px;
    font-family: var(--font-default);
    /*color: var(--color-body-text);*/
    box-shadow: 0 0 15px rgba(0,0,0,.15);

}
.google-map .infoBox>img {
    width: 33px;
    top: -15px;
    right: -15px;
    padding: 10px;
    opacity: .6;
}
.google-map .infoBox>img:hover {
    opacity: 1;
}

/*winter-landingpage*/
.google-map--winter-lp .infoBox {
    background: var(--color-primary);
    color: #ffffff;
}

.google-map--winter-lp .infoBox h3{
    color: #ffffff;

}