.trip-planer-dummy-teaser {
    padding: calc(40rem / 16) calc(15rem / 16);
    border:1px solid var(--color-dark-grey);
}
.trip-planer-dummy-teaser__heart {
    display: inline-block;
    margin-bottom: 10px;
    font-size: 42px;
}
.trip-planer-dummy-teaser__bubble {
    height: 24px;
    width: 24px;
    font-size: 10px;
    border: 2px solid;
    line-height: 23px;
    margin-top: -38px;
    margin-left: 9px;
}
