.filter-dropdown {
    position: relative;
}

/** Filter Dropdown Btn **/
.filter-dropdown .filter-dropdown__btn,
html:not(.has-preloaded-fonts) .filter-dropdown .filter-dropdown__btn {
    display: block;
    text-indent: 0;
    margin-right: 0;
    position: relative;
    height: auto;
    width: 100%;
    text-align: left !important;
    padding: .75rem 1.5rem;
    font-size: 1.25rem;
    color: var(--color-dark);
    background-color: var(--color-white);
    font-family: var(--font-special);
    line-height: 1.5;
    @media screen and (max-width: 767px) {
        font-size: 1.1rem;
    }
}
.filter-dropdown .filter-dropdown__btn--primary {
    padding-right: .125rem;
    color:#fff;
    background-color: var(--color-primary);
}
.filter-dropdown .filter-dropdown__btn--dark {
    background-color: #616262;
    color: #FFF;
}

@media screen and (max-width: 767px) {
    .filter-dropdown .filter-dropdown__btn {
        margin-bottom: .5rem;
    }
}

.filter-dropdown .filter-dropdown__btn-md--small {
    @media screen and (min-width: 768px) {
        font-size: 1.15rem;
        padding: .875rem 1.5rem;
    }
}

.filter-dropdown__btn:not(.filter-dropdown__btn--dark).show {
    background-color: rgba(97,98,98,.1);
}

.filter-dropdown__icon {
    position: relative;
    top: 4px;
    margin-right:5px;
    font-size: 1.25rem;
}
.filter-dropdown__icon.icon-filter,
.filter-dropdown__icon.icon-map {
    min-width: 1em;
    line-height: 0;
}

.filter-dropdown__arrow {
    position: absolute;
    top: 50%;
    right: 24px;
    content: var(--icon-arrow-left);
    font-size: 1.2rem;
    transform: translateY(-50%);
}

.filter-dropdown__arrow:before {
    display: inline-block;
    transform: rotate(270deg);
    transition: transform 80ms ease;
    transform-origin: center center;
}

.filter-dropdown__btn.show .filter-dropdown__arrow:before {
    transform: rotate(90deg);
}

/** Filter Dropdown Menu **/
.filter-dropdown .dropdown-menu {
    padding: 0;
    background-color: #FFF;
    min-width: 100%;
    box-shadow: 2px 6px 10px 1px rgba(0,0,0,0.2);
    z-index: 99;
}
.filter-dropdown--overlay.show>.dropdown-menu {
    display: block;
}
.filter-dropdown--overlay .dropdown-menu--right {
    left:auto;
    right:0;
}
.filter-dropdown--overlay .dropdown-menu--wide {
    min-width:28rem;
}

.filter-dropdown .dropdown-menu .dropdown-item {
    display: inline-block;
    padding: .5rem 1.5rem;
    font-size: 1.25rem;
    white-space: normal;
    border-top: 1px solid rgba(104, 127,150, .2);

    @media screen and (max-width: 991px) {
        font-size: 1.2rem;
    }
}

.filter-dropdown .dropdown-item {
    @media screen and (max-width: 767px) {
        color:#fff;
    }
}

.dropdown-item .dropdown-item-btn {
    display: block;
    height: auto;
    width: auto;
    font-family: var(--font-special);
    font-size: 1.15rem;
    margin: 0 auto;
    padding: 0;
    text-align: center;
    background-color: transparent;
    color: inherit;

    @media screen and (max-width: 767px) {
        font-size: 1rem;
        color: var(--color-dark);
    }
}

.filter-dropdown .dropdown-menu .dropdown-item:active,
.filter-dropdown .dropdown-menu .dropdown-item:focus,
.filter-dropdown .dropdown-menu .dropdown-item:hover
{
    background-color: inherit;
    color: var(--color-dark);
}

.dropdown-item--title {
    padding: 1.5rem !important;
}

.dropdown-item--border-strong {
    border-width: 3px !important;
}

/*** Filter Overlays (mobile) ***/
@media screen and (max-width: 767px) {
    .filter-dropdown--overlay {
        color:#fff;
        position: fixed;
        overflow-y: scroll;
        top: 0;
        left: 0;
        right:0;
        bottom:0;
        padding: 0 1rem;
        z-index: 999;
        background-color: rgba(0,0,0,.9);
    }


    .dropdown-overlay__content .dropdown-item {
        border-top:1px solid #fff;
        white-space: normal;
        background: transparent;
        font-size: 1.125rem;
        padding: .75rem 0 .75rem 1.125rem;
    }

    .dropdown-overlay__content .dropdown-overlay__close {
        visibility: hidden;
        color:inherit;
        font-size: 1.5rem;
        position: absolute;
        right:0;
        top:-2.5rem;
    }
    .filter-dropdown--overlay.show .dropdown-overlay__close {
        visibility: visible;
    }
    .dropdown-overlay__content {
        position: relative;
        margin: 3.5rem 0;
        top:auto;
        left:auto;
        right:auto;
        bottom:auto;
        /*background: transparent;*/
    }

    /* -- Animations */
    .filter-dropdown--overlay {
        transform: translateX(-100%);
        transition: transform .25s ease;
    }
    .filter-dropdown--overlay.show {
        transform: translateX(0);
    }
}

.filter-dropdown .filter-dropdown__btn--plain {
    background: none;
    padding: 0;
    font-size: calc(14rem / 16);
    position: relative;
    color: var(--color-text-default);
    height: auto;
    min-width: unset;
    margin: 0;
    text-indent: inherit;
}
.filter-dropdown__btn--plain:after {
    content: none;
}
.filter-dropdown__btn--plain .filter-dropdown__arrow {
    right: 0;
    font-size: 12px;
    position: relative;
    margin-left: 5px;
    vertical-align: -0.1em;
    min-width: .6em;
    line-height: 0;
}