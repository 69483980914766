footer {
    position: relative;
    padding:20px 0 0 0;
    overflow: hidden;
    background-color: #E2E7E9;
    border-bottom:60px solid #E2E7E9;
    @media screen and (min-width: 768px) {
        padding:50px 0 0 0;
    }
}
.main-footer {
    color:var(--color-dark);
    position: relative;
    padding:40px 0;

    @media screen and (min-width: 768px) {
        padding:50px 0 30px 0;
    }
}
.main-footer__social {
    position: relative;

    @media screen and (min-width: 768px) {
        margin-top:30px;
    }
}

.main-footer__copy {
    font-size:1rem;
    position: relative;
    top:8px;
    padding-top:85px;
    @media screen and (min-width: 768px) {
        padding-top:0;
    }
}
.embed-responsive-footer {
    @media screen and (max-width: 767px) {
        margin: 0 0 0 -70%;
        width: 250%;
    }
}
.main-footer__copy-inner {
    color:var(--color-dark);
    position: absolute;
    left:0;
    right:0;
    top:0;
    @media screen and (min-width: 768px) {
        top:25%;
    }
}

.main-footer__hl {
    font-size:24px;
    margin-bottom:1.5rem;
    color:#758592;
    @media screen and (min-width: 768px) {
        font-size:40px;
        margin-bottom:0;
    }
}
.main-footer__social a {
    position: relative;
    display: inline-block;
    color:#758592;
    margin:0;
    width:60px;
    height:56px;
    @media screen and (min-width: 768px) {
        margin:0 1.5rem;
    }
}

.main-footer__social a:hover,
.main-footer__social a:focus {
    color:var(--color-highlight);
}
.main-footer__social .main-footer__social-socialicon,
.main-footer__social .main-footer__social-brandicon {
    font-size:46px;
    @media screen and (min-width: 768px) {
        font-size:56px;
    }
    position: absolute;
    left:50%;
    top:50%;
    transform: translateX(-50%) translateY(-50%);
}
.main-footer__social .main-footer__social-socialicon {
    color:#E2E7E9;
    font-size:24px;
    @media screen and (min-width: 768px) {
        font-size:28px;
    }
}