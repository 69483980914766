.trip-planer-slider .slick-track {
    display: flex;
}
.trip-planer-slider .slick-slide {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: auto;
}

/* hide elements on meeting location teaser in tripplaner */
.trip-planer-slider--meetinglocations .teaser--acco .teaser__acco-trust,
.trip-planer-slider--meetinglocations .teaser--acco .teaser__badge,
.trip-planer-slider--meetinglocations .teaser--acco .teaser__acco-category {
    display: none;
}
