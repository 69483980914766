.header-search-content {
    display: none;
}
body.is-search-open .header-search-content {
    display: block
}
.header-search-content {
    width: 375px;
    position: absolute;
    top:100%;
    right:-25px;
    margin-top:75px;
    @media screen and (min-width: 1680px) {
        width: 520px;
        right:-275px;
    }
}
.header-search-content-xs {
    position: relative;
    margin-top:1.5rem;
}
.header-search-form {
    position: relative;
}
.header-search-form .btn {
    position:absolute;
    right:0;
    top:0;
    bottom:0;
    color:#000;
    font-size:1.25rem;
    padding:.25rem 1.25rem;
    margin:0;
    text-indent: 0;
    width:auto;
    height:40px;
    min-width: 0;
    background: transparent;

    @media screen and (min-width: 768px) {
        font-size:1.5rem;
        height:60px;
        padding:0 2rem;
    }
}
.header-search-form .btn>.icon {
    position:absolute;
    top:50%;
    left:50%;
    transform: translateY(-50%) translateX(-50%);
}
.header-search-form .form-control {
    color:#000;
    font-family: var(--font-special);
    font-size:1rem;
    height:40px;
    padding:.5rem 2.5rem .5rem 1.125rem;
    border-radius:0;
    border:0;
    background: #fff;
    margin-bottom: 1rem;
    @media screen and (min-width: 768px) {
        height:60px;
        font-size:1.25rem;
        padding:1rem 2.5rem 1rem 1.125rem;
    }
}

@media screen and (min-width: 768px) {
    .header-search__toggle {
        position: relative;
        margin-left:4rem;
        min-width: 50px;
    }
    .header-search__toggle:before {
        content:'';
        width:2px;
        height:auto;
        background: #fff;
        position: absolute;
        left:-2rem;
        top:-.25rem;
        opacity: .5;
        bottom:-.125rem;
    }
}

@media screen and (max-width: 767px) {
    .header-search__toggle,
    .header-search {
        position: static !important;
    }
    .header-search-content {
        width: auto;
        position: fixed;
        top:70px;
        margin:0;
        left:0;
        right: 0;
        padding:.5rem 1rem 1rem 1rem;
    }
    header.is-affix .header-search-content {
        top:50px;
    }
}

@media screen and (max-width: 320px) {
    .header-search-content {
        width:320px;
    }
}