/*Text Helper*/
.text-default {
    color: var(--color-text-default);
}
.text-truncate {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.text-truncate.Arrange-sizeFill {
    max-width: 100px;
    /*text-overflow needs a max with if the element is in a table*/
}
.text-muted {
    color:var(--color-text-muted) !important;
}
.text-dark{
    color: var(--color-dark);
}
.text-highlight{
    color: var(--color-highlight);
}
.text-color-inherited {
    color: inherit;
}
a.text-color-inherited:hover,
a.text-color-inherited:focus {
    color: inherit;
    text-decoration: underline;
}

.text-color-inherited-block {
    color: inherit;
}

.text-color-inherited-block a:focus,
.text-color-inherited-block a:hover {
    color: inherit;
    text-decoration: underline;
}

.text-underline {
    text-decoration: underline;
}

@media screen and (max-width: 767px) {
    .text-left-on-xs {
        text-align: left;
    }
}

@media screen and (min-width: 768px) {
    .text-col--3 {
        column-count: 3;
    }
}
@media screen and (min-width: 768px) {
    .text-col--4 {
        column-count: 4;
    }
}
.icon-in-text {
    font-size: calc(13em / 16);
    vertical-align: -.07em;
    margin-right: 6px;
}
.icon-in-text--medium {
    font-size: calc(20em / 16);
    vertical-align: -.27em;
    margin-right: 4px;
}
.icon-in-text--medium.icon-unterirdische-bahn {
    font-size: .6em;
}
.icon-in-text--large {
    font-size: calc(23em / 16);
    vertical-align: -.27em;
}
.collapsed .icon-collapse-rotate {
    transform: rotate(0deg);
}
.icon-collapse-rotate {
    transform: rotate(45deg);
    transition: transform 140ms ease;
}

.img-full-width,
.img-full-width>img {
    width: 100%;
    height: auto;
}
.ankerinfo {
    display: block;
     padding-top: 40px;
     margin-top: -80px;
}

.link-w-icon {
    font-size:inherit;
    font-family: var(--font-special);
    color:var(--color-primary);
    display: inline-block;
}
.link-w-icon:hover,
.link-w-icon:focus {
    color:var(--color-text-default);
    text-decoration: none;
}
.link-w-icon > .icon {
    font-size:.5rem;
    position: relative;
    margin-right:.25rem;
    top:-.125em;
    display: inline-block;
}

.pointer-events-none {
    pointer-events: none;
}

.object-fit-cover {
    object-fit: cover;
}

/* backgrounds */
.bg-highlight{
    color:#fff;
    background: var(--color-highlight) !important;
}
.bg-white{
    background: #fff;
}
.bg-light-grey{
    background: var(--color-light-grey);
}
.bg-dark-grey{
    background: var(--color-dark-grey) !important;
}
.bg-yellow {
    background: var(--color-yellow) !important;
}
.bg-primary {
    background: var(--color-primary) !important;
}
.bg-green {
    background: var(--color-green) !important;
}
.bg-lightgreen {
    background: var(--color-light-green) !important;
}
.bg-lila {
    background: var(--color-lila) !important;
}



.alert-danger {
    color: #fff;
    background-color: var(--color-danger);
    border-color: var(--color-danger);
}
.alert-secondary {
    color: #fff;
    background-color: var(--color-secondary);
    border-color: var(--color-secondary);
}


/*Margins*/
.no-margin.no-margin {
    margin: 0;
}
.mt0 { margin-top: 0; }
.mt5 { margin-top: 5px; }
.mt10 { margin-top: 10px; }
.mt15 { margin-top: 15px; }
.mt20 { margin-top: 20px; }
.mt30 { margin-top: 30px; }
.mt40 { margin-top: 40px; }


@media screen and (max-width: 767px) {
    .mt0-on-xs {
        margin-top: 0;
    }
}

.mb0 { margin-bottom: 0; }
.mb5 { margin-bottom: 5px; }
.mb10 { margin-bottom: 10px; }
.mb15 { margin-bottom: 15px; }
.mb20 { margin-bottom: 20px; }
.mb30.mb30 { margin-bottom: 30px; }

.mb60 { margin-bottom: 40px; }
@media screen and (min-width: 768px) {
    .mb60 { margin-bottom: 60px; }
}

@media screen and (max-width: 767px) {
    .mt10-on-xs { margin-top: 10px; }
    .mb20-on-xs { margin-bottom: 20px; }
    .mb10-on-xs { margin-bottom: 10px; }
}

.mr15 { margin-right: 15px; }

.no-padding.no-padding { padding: 0 !important; }
.p10 { padding: 10px; }
.p20 { padding: 20px; }
.p15 { padding: 15px; }
.pl30 { padding-left: 30px; }
.pt50 { padding-top: 50px; }
.pb15 { padding-bottom: 15px; }
.pb50 { padding-bottom: 50px; }

/*Font sizes*/
.fz12 { font-size: 12px; }
.fz14 { font-size: 14px; }
.fz15 { font-size: 15px; }
.fz16 { font-size: 16px; }
.fz18 { font-size: 18px; }
.fz20 { font-size: 20px; }
.fz35 { font-size: 35px; }
.fz40 { font-size: 40px; }


.fs1 { font-size: 1rem; }
.fs15 { font-size: 1.5rem; }
.fs2 { font-size: 2rem; }
.fs25 { font-size: 2.5rem; }
.fs3 { font-size: 3rem; }

.lh-1 {
    line-height: 1;
}
.lh-1_5 { line-height: 1.5; }
.lh-1_75 { line-height: 1.75; }

.z-0 {
    z-index: 0;
}

/*Row Gutter Width*/
label.row {
    display: block;
    max-width: none;
}

/*Vertical center container*/
.center-container {
    text-align: center;
    font-size: 0;
}
.center-container:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}
.center-container__item.center-container__item {
    display: inline-block;
    vertical-align: middle;
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: auto;
}

/*bootstrap collapse visibility classes*/
.visible-on-collapsed {
    display: none;
}
.collapsed.visible-on-collapsed,
.collapsed .visible-on-collapsed {
    display: block;
}

/*js-styling*/
.js-go-to-link,
.isClickable,
.js-lightbox-group__item,
[data-toggle="collapse"] {
    cursor: pointer;
}
.disabled, .disabled a {
    cursor: not-allowed;
}

.checkmark-list>li {
    position: relative;
    margin:1rem 0;
    padding-left:1.75rem;
}
.checkmark-list>li:before {
    font-family: iconfont;
    content:var(--icon-check);
    position:absolute;
    left:0;
    top:0;
}


.inline-row {
    margin-left: -15px;
    margin-right: -15px;
}
.inline-row__item {
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
}
@media screen and (max-width: 767px) {
    .inline-row__item--block-on-xs {
        display: block;
        margin-bottom: 10px;
    }
}

.collapse-icon {
    display: inline-block;
    transform: rotate(180deg);
    transition: transform 140ms ease;
}
.collapsed .collapse-icon {
    transform: rotate(0deg);
}

.btn__icon.icon-arrow-down {
    font-size: 0.5em;
    vertical-align: 0.2em;
    margin-left: .3em;
}

.w-25 {width: 25px; }
.hidden {display:none;}
.break-word {word-wrap:break-word;}

/* border helpers */
.border-bottom-4 {
    border-bottom: solid 4px;
}


@media screen and (min-width: 768px) {
    .flex-acco-col {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}