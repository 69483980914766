.breadcrumb {
    padding: 0;
    margin-bottom:0;
    background:transparent;
    border-radius:0;
    @media screen and (max-width: 767px) {
        font-size:.75rem;
    }
}
.breadcrumb-item {
    display: inline-block;
}
.breadcrumb-item+.breadcrumb-item {
    padding-left: 2rem;
    position: relative;
    @media screen and (max-width: 767px) {
        padding-left: 1.5rem;
    }
}
.breadcrumb-item a {
    color: #758592;
}
.breadcrumb-item.active {
    color: var(--color-dark);
}
.breadcrumb-item + .breadcrumb-item:before {
    position: absolute;
    left:10px;
    top:7px;
    margin:0;
    padding:0;
    transform: rotate(45deg);
    display: inline-block;
    border:1px solid #758592;
    border-width:1px 1px 0 0;
    content: '';
    width:.5rem;
    height:.5rem; 
    @media screen and (max-width: 767px) {
        left:5px;
        top:4px;
    }
}