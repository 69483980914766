.content-intro__list>li {
    margin-left: 6px;
    margin-right: 6px;
    margin-top: 5px;
    padding: 0;
}
.content-intro__list>li:after {
    display: inline-block;
    margin: inherit;
    color: #c9c9c9;
}
@media screen and (min-width: 768px) {
    .content-intro__list>li {
        margin-left: 12px;
        margin-right: 0;
        margin-top: 0;
    }
    .content-intro__list>li:after {
        content: '|';
    }
}
.content-intro__list>li:last-child:after {
    display: none;
}
.content-intro__link {
    position: relative;
    text-decoration: none;
}
.content-intro__link:hover {
    text-decoration: none;
}

.content-intro__link:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    background: #d7d7d7;
    border-top: 1px solid #e1e1e1;
    z-index: -1;
}
.content-intro__link:hover:after {
    background: var(--color-secondary);
    border-top-color: inherit;
}

.content-intro__img + .content-intro__wysiwyg,
.content-intro__lead + .content-intro__wysiwyg {
    margin-top:1rem;
    @media screen and (min-width: 768px) {
        margin-top:2.5rem;
    }
}