@media screen and (max-width: 767px) {
    .oax .oax_ep_open .oax-ep-wrapper .alp-tpwiz-facts,
    .alp-x-elpro-slider-helptext {
        display: none;
    }
    .alp-tpwiz-elprocont .alp-tpwiz-elpro-subcont {
        margin-left: 0;
        padding-right:0;
    }
}

.oax .oax-ep-wrapper {
    z-index: 1!important;
}

.alp-tpwiz-elpro-container.alp-tpwiz-linref-edit-inactive.oax {
    min-height: 350px;
}