.content-wrapper {
    background: var(--color-light-grey);
    padding:1px 0;
    position: relative;
}


.slide {
    margin-top:2.5rem;
    margin-bottom:2.5rem;
    @media screen and (min-width: 768px) {
        margin-top:5rem;
        margin-bottom:5rem;
    }
}
.accordion--img .slide, /* slides in img-accordion */
.slide--sm {
    margin-top:1.5rem;
    margin-bottom:1.5rem;
    @media screen and (min-width: 768px) {
        margin-top:3rem;
        margin-bottom:3rem;
    }
}

/* content slides benötigen padding, wegen background-colors. da inhalte bereits vorhanden keine andere lösung möglich. */
.slide.color-box:not(.color-box--) {
    padding-top:2.5rem;
    padding-bottom:2.5rem;
    margin:0;
    @media screen and (min-width: 768px) {
        padding-top:5rem;
        padding-bottom:5rem;
        margin:0;
    }
}
.slide--sm.color-box:not(.color-box--) {
    padding-top:1.5rem;
    padding-bottom:1.5rem;
    @media screen and (min-width: 768px) {
        padding-top:3rem;
        padding-bottom:3rem;
    }
}

.slide.slide--contains-content-blocks {
    padding-bottom: 0;
}
.slide.slide--no-padding-top {
    padding-top: 0 !important;
}
.slide.slide--no-margin-top {
    margin-top: 0;
}
.slide.slide--no-margin-bottom {
    margin-bottom: 0;
}
.slide.slide--no-padding-bottom {
    padding-bottom: 0;
}
.slide.slide--no-padding {
    padding: 0 !important;
}

.slide__heading {
    position: relative;
    width:100%;
    /*margin:0;*/
    margin:0 auto;
    /*max-width: 825px;*/
    max-width: 1010px;
}
.slide__heading--100 {
    max-width: 100%;
}
.slide__heading--wide {
    max-width: 1000px;
}
.slide__heading--x-wide {
    max-width: 1500px;
}
.slide__title {
    margin-bottom:1rem;
    @media screen and (min-width: 768px) {
        margin-bottom:2.5rem;
    }
}

.slide__title-main,
.slide__title-sub {
    margin-bottom:1rem;
}

.slide--bordered {
    border-top: 2px solid var(--color-primary-bg);
    border-bottom: 2px solid var(--color-primary-bg);
}