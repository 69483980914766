.header-slide {
    position: relative;
    overflow: hidden;
}
@media screen and (min-width: 768px) {
    .header-slide {
        background: #b8d6ec;
    }
}
.header-slide__stage {
    max-width: 1400px;
    margin: auto;
    box-shadow: 7px 0 var(--color-summer), 62px 0 var(--color-primary), 77px 0 var(--color-secondary), 93px 0 var(--color-winter), 95px 0 #fff;
}

/*hide initially*/
.header-slide__stage > div + div {
    display: none;
}
.header-slide__item img {
    width: 100%;
}
.header-slide__img {
    width: 100%;
    height: auto;
}
@media screen and (min-width: 768px) {
    .header-slide__item {
        position: relative;
        height: 0;
        overflow: hidden;
        padding-top: calc(540 / 1280 * 100%);
    }
    .header-slide__img {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
    }
}

/*content*/
@media screen and (min-width: 768px) {
    .header-slide__content {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 22%;
    }
    .header-slide__container {
        width: 100%;
        padding: 0 100px;
    }
}
@media screen and (min-width: 1500px) {
    .header-slide__content {
        bottom: 15%;
    }
    .header-slide__container {
        width: 1300px;
        padding: 0 15px;
    }
}

@media screen and (min-width: 768px) {
    .header-slide__text-content {
        position: absolute;
        top: 60px;
        left: 75px;
        right: 75px;
        color: #ffffff;
        text-shadow: 0 0 20px rgba(0,0,0,0.3), 1px 1px 0 rgba(0,0,0,0.6);
    }
}
@media screen and (min-width: 1300px) {
    .header-slide__text-content {
        left: 0;
        right: 0;
    }
}

@media screen and (min-width: 768px) {
    .header-slide__text-content--right {
        text-align: right;
    }
    .header-slide__text-content--bottom {
        top: auto;
        bottom: 80px;
    }
    .header-slide__text-content--center{
        text-align: center;
        top: auto;
        bottom: 80px;
    }
}

.header-slide__text-title{
    margin-top: 20px;
    color: var(--color-primary);
}
@media screen and (min-width: 768px) {
    .header-slide__text-title{
        margin-top: 0;
        color: #ffffff;
    }
}



.header-slide__box {
    padding: 10px 15px;
    background: #fff;
    background: rgba(255, 255, 255, 0.95);
    width: 100%;
}
@media screen and (min-width: 768px) {
    .header-slide__box {
        padding: 30px;
        max-width: 460px;
        width: auto;
        text-shadow: none;
    }
}
.header-slide__box-hl {
    font-size: 22px;
}
.header-slide__box-meta {
    font-size: 14px;
}
@media screen and (min-width: 768px) {
    .header-slide__box-hl {
        font-size: 30px;
    }
    .header-slide__box-meta {
        font-size: 16px;
    }
}
.header_slide__progress-bar {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 5px;
    display: block;
    background-color: var(--color-primary);
    transform-origin: left center;
    transform: scaleX(1) translateZ(0);
}

@media screen and (max-width: 767px) {
    .header-slide .vjs-default-skin.vjs-default-skin .vjs-big-play-button {
        top: 0;
        margin-top: 38%;
    }
}
.header-slide__scroll-arrow {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -35px);
    width: 96px;
    height: 96px;
    background-color: #ffffff;
    border-radius: 50%;
    padding-top: 20px;
    text-align: center;
    color: var(--color-primary);
}
.header-slide__scroll-arrow:hover {
    color: var(--color-secondary);
}


.btn-discover{
    color: var(--color-primary);
    background: white;
    border: 1px solid var(--color-primary);
}

.btn-bg-primary{
    color: #ffffff;
    background: var(--color-primary);
}

.btn-bg-primary:hover{
    color: #ffffff;
}