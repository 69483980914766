.detail__collapse {
    color:inherit;
    font-family: var(--font-special);
    font-size:1.75rem;
    display: inline-block;
}
.detail__collapse--lg {
    font-size:inherit;
}
.detail__collapse:hover,
.detail__collapse:focus {
    text-decoration: none;
}
.detail__collapse>.icon {
    display: block;
    margin:1rem auto 0 auto;
    font-size:.75rem;
    transform: rotate(180deg);
    transition: transform .25s ease;
}
.detail__collapse--lg>.icon {
    @media screen and (min-width: 768px) {
        font-size:1rem;
    }
}
.detail__collapse.collapsed>.icon {
    transform: rotate(0deg);
}