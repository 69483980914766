/* text accordion */
.accordion {
    position: relative;
    background:#fff;
    padding:1rem 1rem;
    margin:0;
    @media screen and (min-width: 768px) {
        margin:0;
        padding:60px 90px;
    }
}
.accordion--small {
    padding:1.5rem 0;
    @media screen and (min-width: 768px) {
        padding:40px 60px;
    }
}
.accordion--unstyled {
    padding: 0 !important;
}

@media screen and (max-width: 767px) {
    .accordion--opening-contact {
        margin:0 -1rem;
    }
}
.accordion .card {
    position: relative;
    margin:0 auto;
    width:100%;
    background-color: transparent;
    border:0;
    border-radius:0;
    border-top:1px solid #C7CED2;
}

/*@media screen and (min-width: 768px) {
    .accordion:not(.accordion--img) .card {
        max-width: 800px;
    }
}*/

.accordion--unstyled .card {
    border: 0 !important;
}
.accordion:not(.accordion--img) .card:last-of-type {
    border-bottom:1px solid #C7CED2;
}
.accordion .card-header {
    padding: 0;
    margin:0;
    background-color: transparent;
    border-bottom: 0;
}
.accordion .card-header>a {
    display: block;
    position: relative;
    color:var(--color-dark);
    padding:1.5rem 2.5rem 1.5rem 1rem;
    font-size:1.25rem;
    font-family: var(--font-special);
    text-decoration: none;

    @media screen and (min-width: 768px) {
        font-size:1.5rem;
        font-size:1.5rem;
    }
}
.accordion--small .card-header>a {
    font-size:1.125rem;
    @media screen and (min-width: 768px) {
        font-size:1.25rem;
    }
}
.accordion--x-small .card-header>a {
    font-size: 1rem !important;
}
.accordion .card-header.card-header--small>a {
    padding: .85rem;
}
.accordion--unstyled .card-header>a {
    color: inherit;
}
.accordion .card-header>a:hover,
.accordion .card-header>a:focus {
    text-decoration: none;
    color:var(--color-dark);
}
.accordion--unstyled .card-header>a:hover,
.accordion--unstyled .card-header>a:focus {
    color: inherit;
}
.accordion .card-header>a:after {
    content:'';
    position: absolute;
    right:1.25rem;
    top:40%;
    width: 10px;
    height:10px;
    border:1px solid var(--color-dark);
    border-width:2px 2px 0 0;
    transform: rotate(-45deg);
    transition: transform .25s ease;

    @media screen and (min-width: 768px) {
        top:2rem;
        width: 16px;
        height:16px;
    }
}
.accordion--small .card-header>a:after,
.accordion--x-small .card-header>a:after{
    width: 10px !important;
    height:10px !important;
}
.accordion .card-header.card-header--small>a:after {
    @media screen and (min-width: 768px) {
        top: 1rem;
    }
}
.accordion--unstyled .card-header>a:after {
    border-color: inherit;
}
.accordion .card-header>a.collapsed:after {
    transform: rotate(135deg);
}
.accordion .card-body {
    padding:0 1rem 1.75rem 1rem;
}


/*img accordion*/
.accordion.accordion--img {
    padding:0;
    background:transparent;
}
.accordion--img .card {
    margin-bottom:1rem;
}
.accordion--img .card-header>a:after {
    display: none;
}
.accordion-img-header {
    position: relative;
    color:#fff;
    height:220px;
    background-position: 50% 50%;
    background-repeat:no-repeat;
    background-color:#ccc;
    background-size: cover;
    text-align: center;

    @media screen and (min-width: 768px) {
        height:540px;
    }
}
.accordion-img-header:before {
    content:'';
    position: absolute;
    left:0;
    right:0;
    height:20%;
    bottom:0;
    z-index:5;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#262626+0,262626+100&0+0,0.85+100 */
    background: -moz-linear-gradient(top, rgba(38,38,38,0) 0%, rgba(38,38,38,0.85) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(38,38,38,0) 0%,rgba(38,38,38,0.85) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(38,38,38,0) 0%,rgba(38,38,38,0.85) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00262626', endColorstr='#d9262626',GradientType=0 ); /* IE6-9 */
}
.accordion--img .card {
    max-width:100%;
    border:0;
}
.accordion--img .card-header>a {
    padding:0;
}
.accordion-img-header__body {
    position: absolute;
    left:20px;
    right:20px;
    top:50%;
    transform: translateY(-50%);
    text-shadow: 0 2px 4px rgba(0,0,0,.3);
    margin:0;
    z-index:10;
}
.accordion-img-header__scroller {
    content:'';
    display: none;
    width: 22px;
    height:22px;
    border:1px solid #fff;
    border-width:2px 2px 0 0;
    transform: rotate(135deg);
    position: absolute;
    left:50%;
    margin-left:-11px;
    bottom:20px;
    z-index:12;
}
a.collapsed .accordion-img-header__scroller {
    display: block;
}
.accordion-img__close {
    padding:1rem 1rem 0 0;
    font-size:1.5rem;
    line-height:1px;
    @media screen and (min-width: 768px) {
        padding:1.5rem 0 0 0;
    }
}