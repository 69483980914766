.modal {
    z-index: 99999;
}
.modal-mountains {
    position: relative;
    color: #ffffff;
}
.modal-mountains__icon {
    position: absolute;
    font-size: 35px;
    bottom: 25%;
    right: 8%;
    line-height: 0;
}
.modal-mountains + .modal-content {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}
.modal-mountains__circle-icon {
    font-size: 0.657em;
    position: absolute;
    left: -9px;
    bottom: -7px;
    border-bottom: none;
    z-index: 1;
}
.modal-mountains__circle-icon:after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background: var(--color-primary);
    border-radius: 50%;
}
.modal-mountains__circle-icon--yellow:after {
    background: var(--color-summer);
}