.trip-planer-add {
    color:inherit;
    background: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
    font-size:1.25rem;
    line-height: 1;
}
.is-campaign .trip-planer-add {
    display: none; /* hide wishlist if campaign page */
}
.trip-planer-add:hover {
    text-decoration: none;
    color: var(--color-highlight);
}
.trip-planer-add--abs {
    position: absolute;
    right:14px;
    bottom:10px;
    z-index:10;
    color:#fff;
    text-shadow: 0 0 6px rgba(0,0,0,0.5);
}
.trip-planer-add--abs-transparent {
    color:inherit;
    text-shadow: none;
}
/*.trip-planer-add--abs:before {
    content:'';
    position: absolute;
    right:-14px;
    bottom:-10px;
    width:95px;
    height:95px;
    z-index:-1;
    transform: rotate(90deg);
    !* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+10,000000+100&0+45,1+100 *!
    background: -moz-linear-gradient(45deg, rgba(0,0,0,0) 10%, rgba(0,0,0,0) 45%, rgba(0,0,0,.75) 100%); !* FF3.6-15 *!
    background: -webkit-linear-gradient(45deg, rgba(0,0,0,0) 10%,rgba(0,0,0,0) 45%,rgba(0,0,0,.75) 100%); !* Chrome10-25,Safari5.1-6 *!
    background: linear-gradient(45deg, rgba(0,0,0,0) 10%,rgba(0,0,0,0) 45%,rgba(0,0,0,.75) 100%); !* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ *!
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=1 ); !* IE6-9 fallback on horizontal gradient *!
}
.trip-planer-add--abs-lighter:before {
    background: -moz-linear-gradient(45deg, rgba(0,0,0,0) 10%, rgba(0,0,0,0) 45%, rgba(0,0,0,.35) 100%); !* FF3.6-15 *!
    background: -webkit-linear-gradient(45deg, rgba(0,0,0,0) 10%,rgba(0,0,0,0) 45%,rgba(0,0,0,.35) 100%); !* Chrome10-25,Safari5.1-6 *!
    background: linear-gradient(45deg, rgba(0,0,0,0) 10%,rgba(0,0,0,0) 45%,rgba(0,0,0,.35) 100%); !* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ *!
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=1 ); !* IE6-9 fallback on horizontal gradient *!
}
.trip-planer-add--abs-transparent:before {
    background: transparent;
}*/
.trip-planer-add.is-added .trip-planer-add__icon {
    color: var(--color-highlight);
}

.trip-planer-add__visible-added {
    display: none;
}
.trip-planer-add.is-added .trip-planer-add__visible-added {
    display: inline-block;
}
.trip-planer-add.is-added .trip-planer-add__hidden-added {
    display: none;
}

/* b2b
.b2b .trip-planer-add__icon:before {
    content:var(--icon-paperclip);
}*/