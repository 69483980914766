.acco-detail {
    color:var(--color-dark);
    position: relative;
}
.acco-detail__box {
    pointer-events: initial;
}
.acco-detail__title {
    line-height:1.1;
    margin-bottom:2rem;
    color:var(--color-green);
    word-wrap:break-word; /* kundenwunsch! */

    @media screen and (max-width:767px) {
        margin-bottom:1rem;
    }

    @media screen and (max-width: 1600px) {
        font-size: 30px;
    }

    @media screen and (min-width:768px) and (max-height: 900px) {
        font-size: 30px;
        margin-top:4rem;
        margin-bottom:1rem;
    }
}
.acco-detail__town {
    margin-bottom: 1.5rem;
}
.acco-detail__category {
    font-size:1.75rem;
    margin-bottom: 1.5rem;
    letter-spacing: 0;
    font-family: sans-serif;

    @media screen and (max-width:767px) {
        margin-bottom: .5rem;
    }

    @media screen and (min-width:768px) and (max-height: 750px) {
        margin-bottom:0;
    }
}

.acco-detail__category-img {
    display: inline-block;
    height:auto;
    width:25px;
    margin-right: -2px;
    @media screen and (min-width: 768px) {
        width:35px;
        position: relative;
        top:-.375rem;
    }
}
.detail-contact,
.detail-contact a,
.detail-contact span,
.acco-detail__contact,
.acco-detail__contact a,
.acco-detail__contact span{
    font-size:1rem;
    color:var(--color-default);
    font-family: var(--font-special);
}
html:not(.has-preloaded-fonts) .detail-contact,
html:not(.has-preloaded-fonts) .detail-contact a,
html:not(.has-preloaded-fonts) .detail-contact span,
html:not(.has-preloaded-fonts) .acco-detail__contact,
html:not(.has-preloaded-fonts) .acco-detail__contact a,
html:not(.has-preloaded-fonts) .acco-detail__contact span {
    font-size: calc(14rem / 16);
    line-height: 1.59;
    letter-spacing: calc(0.46em / 14);
}
.detail-contact a:hover,
.detail-contact a:focus,
.acco-detail__contact a:hover,
.acco-detail__contact a:focus{
    color:var(--color-primary);
    text-decoration: none;
}
.detail-contact>li>a,
.acco-detail__contact>li>a,
.detail-contact>li>span,
.acco-detail__contact>li>span {
    position: relative;
    display: block;
    padding:.5rem 0 .5rem 1.875rem;
}
.detail-contact>li>a>.icon,
.acco-detail__contact>li>a>.icon,
.detail-contact>li>span>.icon,
.acco-detail__contact>li>span>.icon {
    position: absolute;
    left:0;
    top:.625rem;
    font-size:1.25rem;
}
.detail-contact>li>a>.icon-email,
.acco-detail__contact>li>a>.icon-email {
    font-size:.9375rem;
}
.detail-contact>li>a>.icon-bed-2,
.detail-contact>li>span>.icon-bed-2 {
    top:.875rem;
    font-size:.75rem;
}
.acco-detail__arrival-link {
    display:none;

    @media screen and (min-width: 768px) {
        display:inline-block;
        position: relative;
        top: .375rem;
        margin:0 0 0 .5rem;
    }
}
.acco-detail__arrival-link > .icon {
    font-size:1.75rem;
    line-height:1px;
    color:var(--color-green);
}

.detail-contact--big,
.detail-contact--big a,
.detail-contact--big span{
    font-size:1.25rem;
}
.detail-contact--big>li>a,
.detail-contact--big>li>span {
    padding:.5rem 0 .5rem 2.625rem;
}
.detail-contact--big>li>a>.icon,
.detail-contact--big>li>span>.icon {
    top:.625rem;
    font-size:1.5rem;
}
.detail-contact--big>li>a>.icon-bed-2,
.detail-contact--big>li>span>.icon-bed-2 {
    top:.9375rem;
    font-size:1rem;
}


.acco-detail__rating {
    font-size:1.125rem;
    position: relative;
    top:-.75rem;
}

.acco-detail__back {
    margin-top:1rem;
    font-family: var(--font-special);
    display: inline-block;
}
html:not(.has-preloaded-fonts) .acco-detail__back {
    font-size: 13px;
    line-height: 1.6;
    letter-spacing: calc(0.6em / 13);
}
.acco-detail__back>.icon {
    font-size:1.75rem;
    line-height:1px;
    height: 1px;
    position: relative;
    top:.375rem;
    margin-right:.125rem;
}

.acco-inquiry {
    background-color: var(--color-light-grey);
    padding: 1rem;
    @media screen and (min-width: 768px) {
        padding: 1.5rem;
    }
}

.acco-room-details {
    padding:1rem;
    text-align:center;
    @media screen and (min-width: 768px) {
        background:#fff;
        text-align:left;
    }
}