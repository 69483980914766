.arrival-map-wrapper {
    position: relative;
}
.map-container {
    position: relative;
    overflow: hidden;
}
.arrival-map__map {
    position: relative;
    z-index: 0;

    @media screen and (min-width: 768px) {
        width:100%;
        float:right;
    }
}
@media screen and (min-width: 768px) {
    .arrival-map__overlay {
        position: absolute;
        left:0;
        right:0;
        top:50%;
        transform: translateY(-50%);
        pointer-events: none;
    }
    .arrival-map__col {
        pointer-events: initial;
    }
}
.arrival-map__hl {
    margin-bottom:1.75rem;
}
.arrival-map__address {
    line-height:1.75;
    margin-bottom:1.5rem;
    color: var(--color-dark);
}
.arrival-map__logo {
    max-width: 100px;
}


@media screen and (max-width: 767px) {
    .map-xs-overlay {
        position: absolute;
        left:0;
        right:0;
        top:0;
        bottom:0;
        margin-left: -24px;
        background: var(--color-black-60);
        z-index:940;
    }
    .map-xs-overlay--oa {
        margin-left: 0;
    }
    .map-xs-overlay .btn {
        position: absolute;
        left:50%;
        top:50%;
        transform: translateX(-50%) translateY(-50%);
    }
    .map-xs-overlay--oa .btn {
        margin-left: -24px;
    }
}