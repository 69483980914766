body {
    font-size: 17px;
    color: var(--color-text-default);
    line-height: calc(22 / 16);
    font-family: var(--font-default);
    font-weight: normal;
}

html:not(.has-preloaded-fonts) body {
    font-size: 16px;
    line-height: 1.86;
    letter-spacing: -0.003125em;
}

.font-default {
    font-family: var(--font-default) !important ;
}

.strong, strong, b, .bold {
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
}

.font-special {
    font-family: var(--font-special);
    font-weight: normal;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin-top: 0;
    margin-bottom:0;
    color: inherit;
    font-family: var(--font-special);
}
html:not(.has-preloaded-fonts) h1,
html:not(.has-preloaded-fonts) h2,
html:not(.has-preloaded-fonts) h3,
html:not(.has-preloaded-fonts) h4,
html:not(.has-preloaded-fonts) h5,
html:not(.has-preloaded-fonts) h6,
html:not(.has-preloaded-fonts) .h1,
html:not(.has-preloaded-fonts) .h2,
html:not(.has-preloaded-fonts) .h3,
html:not(.has-preloaded-fonts) .h4,
html:not(.has-preloaded-fonts) .h5,
html:not(.has-preloaded-fonts) .h6 {
    letter-spacing: -0.03em;
}

h1, .h1 {
    font-size: 40px;
}
h2, .h2 {
    font-size: 48px;
}
h3, .h3 {
    font-size: 26px;
}
h4, .h4 {
    font-size: 22px;
}
h5, .h5 {
    font-size: 20px;
}
h6, .h6 {
    font-size: 18px;
}



@media screen and (min-width: 1200px) {
    h1, .h1 {
        font-size: 60px;
    }
    body.lang-ru h1, body.lang-ru .h1 {
        font-size: 40px;
    }
}


@media screen and (max-width: 767px) {
    body {
        font-size: 15px;
    }
    html:not(.has-preloaded-fonts) body {
        font-size: 13px;
        line-height: 1.98;
        letter-spacing: calc(0.42em / 13);
    }
    h1, .h1 {
        font-size: 30px;
    }
    h2, .h2 {
        font-size: 26px;
    }
    h3, .h3 {
        font-size: 22px;
    }
    h4, .h4 {
        font-size: 20px;
    }
    h5, .h5 {
        font-size: 18px;
    }
    h6, .h6 {
        font-size: 16px;
    }
}


html:not(.has-preloaded-fonts) h3,
html:not(.has-preloaded-fonts) .h3 {
    font-size: 23px;
    line-height: 1.35;
    letter-spacing: calc(0.61em / 23);

    @media screen and (max-width: 767px) {
        font-size: 19px;
    }
}

