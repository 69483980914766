.corona-overlay {
    position: fixed;
    bottom:4.5rem;
    left:1rem;
    display: inline-block;
    z-index: 100;

    @media screen and (min-width: 768px) {
        bottom:6.5rem;
        left:3rem;
    }
}
.corona-overlay .icon {
    font-size:5.5rem;
    color:var(--color-highlight);

    @media screen and (min-width: 768px) {
        font-size:6.75rem;
    }
}
.corona-overlay:focus .icon,
.corona-overlay:hover .icon {
    color: #991832;
}
.corona-overlay--green .icon {
    color:#a9bf00;
}
.corona-overlay--green:focus .icon,
.corona-overlay--green:hover .icon {
    color: #94a900;
}
.corona-overlay__text {
    position: absolute;
    left:50%;
    top:50%;
    transform: translateX(-50%) translateY(-50%) rotate(-15deg);
    color:#fff;
    text-align: center;
    font-size:.9375rem;
    line-height:1.125;
    font-family: var(--font-special);
    text-decoration: none;
    margin: -0.25rem 0 0 .125rem;

    @media screen and (min-width: 768px) {
        font-size:1.125rem;
    }
}



/* size M */
@media screen and (min-width: 768px) {
    .corona-overlay--m .icon {
        font-size:8.5rem;
    }
    .corona-overlay--m .corona-overlay__text {
        font-size:1.5rem;
    }
}

@media screen and (max-width: 767px) {
    .corona-overlay--m .icon {
        font-size:6.25rem;
    }
    .corona-overlay--m .corona-overlay__text {
        font-size:1rem;
    }
}


/* size L */
@media screen and (min-width: 768px) {
    .corona-overlay--l .icon {
        font-size:10.375rem;
    }
    .corona-overlay--l .corona-overlay__text {
        font-size:1.875rem;
        margin: -0.375rem 0 0 .375rem;
    }
}

@media screen and (max-width: 767px) {
    .corona-overlay--l .icon {
        font-size:7.125rem;
    }
    .corona-overlay--l .corona-overlay__text {
        font-size:1.125rem;
    }
}

@media screen and (max-width: 767px) {
    .corona-overlay--xs-bottom {
        bottom: 0;
        width: 100%;
        left: 0;
        right: 0;
        text-align: center;
        padding: calc(12rem / 16) calc(10rem / 16) calc(8rem / 16);
    }
    .corona-overlay--green.corona-overlay--xs-bottom {
        background: rgba(169,191,0,.95);
    }
    .corona-overlay--xs-bottom > .icon {
        display: none;
    }
    .corona-overlay--xs-bottom .corona-overlay__text {
        position: relative;
        transform: none;
        top: 0;
        left: 0;
        margin: 0;
    }
}