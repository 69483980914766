.trip-planer-box {
    position: relative;
    max-width: 541px;
    height: 607px;
    margin: auto;
    background: url(static/img/tripplaner/teaser-bg.png) no-repeat top center;
    background-color: #d0d9b7;
    background-size: auto 607px;
}
.trip-planer-box--winter {
    background-image: url(static/img/tripplaner/teaser-bg-winter.png);
}
.trip-planer-box__slider,
.trip-planer-box__slider > .slick-list  {
    height: 100%;
}
.trip-planer-box__text {
    position: absolute;
    bottom: 85px;
    left: 0;
    right: 0;
    max-width: 400px;
    padding: 0 15px;
    margin: auto;
}

@media screen and (max-width: 767px) {
    .trip-planer-box__text {
        font-size: .9em;
    }
}
.trip-planer-box__btn {
    position: absolute;
    bottom: 30px;
    left: 0;
    right: 0;
}
.trip-planer-box__show-if-empty {
    visibility: hidden;
}
.trip-planer-box.is-empty .trip-planer-box__show-if-empty {
    visibility: visible;
}