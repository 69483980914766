.acco-header-img,
.acco-header-img__bg {
    height:50vh;
    position: relative;
    background-size:cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}
.acco-header-img__img-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.acco-header-img__img-wrapper img {
    object-fit: cover;
    height: 100%;
    width: 100%;
}
.acco-header-img--empty {
    background:var(--color-dark-grey);
}
.acco-header-img-wrapper {
    height: 50vh;
}
.acco-header-img__bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: auto;
}

@media screen and (min-width: 768px) {
    .acco-header-img-wrapper {
        width:62%;
        float:right;
        height: auto;
    }
    .acco-header-img {
        height:75vh;
    }
    .acco-header-img__overlay {
        position: absolute;
        left:0;
        right:0;
        top:50%;
        transform: translateY(-50%);
        pointer-events: none;
    }
}