.navbar-main>li>.nav-link.nav-link {
    display: block;
    color: #fff;
}

.navbar-main>li>.nav-item:hover>a,
.navbar-main>li>.nav-link:hover,
.navbar-main>li>.nav-link:focus,
.navbar-main>li>.active>.nav-link,
.navbar-main>li>.nav-link.active,
.navbar-main>li>.nav-link.show,
.navbar-main>li.show>a.nav-link {
    color: #F4D100;
}


/* dropdown */
.dropdown-menu {
    float: none;
    margin:0;
    font-size: 1rem;
    color: #fff;
    background-color:transparent;
    border:0;
    border-radius:0;
}
.dd-sub-list a,
.dd-list a {
    position: relative;
    display: block;
    padding:.5rem 1.125rem .5rem 0;
}

@media screen and (min-width: 768px){
    .dd-sub-list a.has-children,
    .dd-list a.has-children {
        display: inline-block;
    }
}
@media screen and (min-width: 768px) and (max-height: 800px) {
    .dd-list a {
        font-size: 15px;
        padding-top: .25rem;
        padding-bottom: .25rem;
    }
}
.dd-sub-list a {
    padding:.375rem 0;
}
.dd-sub-list a:hover,
.dd-list a:hover,
.dd-sub-list a:focus,
.dd-list a:focus,
.dd-sub-list a.active,
.dd-list>li>a.active-svg,
/*.dd-list a.active-svg,*/
.dd-list li.show>a,
.dd-list li.show-level>a {
    color: #F4D100 !important;
    text-decoration: none;
}
.dd-list a.has-children:after {
    position: absolute;
    top:1.125rem;
    right: 0;
    transform: rotate(45deg);
    display: inline-block;
    border:2px solid;
    border-width:2px 2px 0 0;
    content: '';
    width:.5rem;
    height:.5rem;
    margin-left: 5px;

    @media screen and (min-width: 768px){
        top:.9375rem;
    }
    @media screen and (max-height: 800px) and (min-width: 768px) {
        top:.625rem
    }
}