.cookie-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--color-black-85);
    color: #ffffff;
    font-size:13px;
    padding: .625rem .75rem;
    z-index: 99999;
}
.cookie-bar .cookie-bar__detail-btn {
    display: inline-block;
    text-indent: 0;
    height:auto;
    margin:.5rem;
    padding:0;
    font-size:inherit;
    color:inherit;
    font-family: inherit;
    background: transparent;
    min-width:0;
    text-decoration: underline;
    vertical-align: top;
}
.cookie-bar .cookie-bar__detail-btn:after {
    display: none;
}
.cookie-bar__text {
    text-align: center;
}
.cookie-bar__detail {
    margin-top: 0;
    text-align: center;
}
@media screen and (min-width: 768px) {
    .cookie-bar {
        display: flex;
        align-items: baseline;
    }
    .cookie-bar__text {
        display: block;
        flex: 0 1 auto;
        text-align: left;
    }
    .cookie-bar__detail,
    a.cookie-bar__detail {
        margin-top: 0;
        display: inline;
    }
    .cookie-bar__detail {
        flex: auto;
        text-align: left;
        margin-left: 1rem;
    }
    .cookie-bar .cookie-bar__detail-btn {
        margin:0 1rem 0 0;
        display: inline;
    }
}
.cookie-bar__buttons {
    margin-top: 0;
    text-align: center;
}
.cookie-bar__buttons .btn-primary:hover,
.cookie-bar__buttons .btn-primary:focus,
.cookie-bar__buttons .btn-primary.btn-primary {
    height:auto;
    text-indent:0;
    color:#fff;
    padding: .125rem 1.5rem;
    margin:0;
    text-transform: uppercase;
    background:var(--color-primary) !important;
}
.cookie-bar__buttons .btn-primary:after,
.cookie-bar__buttons .btn-primary:before {
    display:none;
}
@media screen and (min-width: 768px) {
    .cookie-bar__buttons {
        flex: none;
        margin:0 0 0 1rem;
        text-align: right;
    }
    .cookie-bar__accept {
        margin-left: .75rem;
    }
}

/* modal */
.cookie-modal__item {
    margin-bottom: .75rem;
}
.cookie-modal__item--all {
    margin-bottom: 1rem;
}
.cookie-modal__label {
    font-size: 1.125rem;
}
.cookie-modal__link {
    display: inline-block;
    margin-left: 4.625rem;
    text-decoration: underline;
}
.cookie-modal .close {
    position: absolute;
    font-size: 2.5rem;
    top: .5rem;
    right: .75rem;
    @media screen and (min-width: 768px) {
        top: .75rem;
    }
}

/* switch */
.cookie-modal__switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    vertical-align: middle;
    margin-right: .5rem;
}
.cookie-modal__switch-toggle {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
}
.cookie-modal__switch-toggle:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 34px;
}
input:checked + .cookie-modal__switch-toggle {
    background-color: var(--color-primary);
}
input:focus + .cookie-modal__switch-toggle {
    box-shadow: 0 0 2px var(--color-primary);
}
input:checked + .cookie-modal__switch-toggle:before {
    transform: translateX(26px);
}