.gallery {

}
.gallery__item {
    position: relative;
}

@media screen and (max-width: 767px) {
    .gallery__item {
        background: #FFFFFF;
        border: 1px solid #dcdcdc;
        border-top: none;
    }
    .gallery__caption {
        padding: 15px;
    }
}

@media screen and (min-width: 768px) {
    .gallery__caption {
        position: absolute;
        bottom: 35px;
        left: 35px;
        padding: 10px 15px;
        margin-right: 35px;
        background: #fff;
        background: rgba(255, 255, 255, .95);
    }
}



.mfp-content .mfp-title .gallery__caption {
    color: #6f6f6f;
}

/*.gallery__desc {*/
    /*margin-top: 10px;*/
/*}*/