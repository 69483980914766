.trip-planer-heart {
    position: relative;
}
.trip-planer-heart__bubble {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -21px;
    margin-left: 5px;
    height: 18px;
    width: 18px;
    color: #FFFFFF;
    border-radius: 50px;
    font-size: 10px;
    line-height: 18px;
    text-align: center;
}
.trip-planer-heart__bubble:empty {
    visibility: hidden;
}
.trip-planer-heart__bubble--small {
    width: 13px;
    height: 13px;
    line-height: 13px;
    margin-top: -17px;
    margin-left: 3px;
}