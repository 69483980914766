.login-state {
    position: relative;
    text-align: center;
}
.login-state__text {
    display: block;
    position: absolute;
    color: #5c5c5c;
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
    font-size: 12px;
    text-align: center;
    top: 50%;
    margin-top: 4px;
    left: 0;
    right: 0;
    line-height: 1;
}
.login-state__icon {
    line-height: 0;
    color: var(--color-summer);
    font-size: 14px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -18px;
    margin-top: -2px;
}
.login-state--logged-out .login-state__icon {
    color: var(--color-primary);
}
.login-state--logged-in:hover,
.login-state--logged-in:focus,
.login-state--logged-in:active,
.navbar-default .navbar-nav > li > .login-state--logged-in:hover,
.navbar-default .navbar-nav > li > .login-state--logged-in:focus,
.navbar-default .navbar-nav > li > .login-state--logged-in:active {
    color: var(--color-summer);
}