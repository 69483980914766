.newsletter-slide {
    position:relative;
    padding:30px 0;
    overflow: hidden;

    @media screen and (max-width: 767px) {
        //background:#fff !important;
    }
    @media screen and (min-width: 768px) {
        padding:50px 0;
        background-size:cover;
        background-repeat:no-repeat;
        background-position:50% 50%;
        background-color:#fff;
    }

    @media screen and (min-width: 1440px) {
        padding:150px 0;
    }
}
.is-campaign .newsletter-slide {
    display: none; /* hide newsletter-slide if campaign page */
}
.newsletter-slide__inner {
    position:relative;
    margin:0 auto;
    padding:1.5rem;
    width:100%;
    max-width:1010px;
    color:#fff;
    background-color: rgba(0,0,0,0.7);
    @media screen and (min-width: 768px) {
        padding:50px;
        //color:#fff;
        //background-color: rgba(0,0,0,0.6);
    }
}
.newsletter-slide__body {
    margin:0 auto;
    position:relative;
    width:100%;
    max-width:500px;
}
.newsletter-slide__hl {
    color:inherit;
    @media screen and (min-width: 768px) {
        margin-bottom:1.5rem;
    }
}

.newsletter-slide__icons {
    position: relative;
    display: inline-block;
    width:75px;
    height:75px;

    @media screen and (min-width: 768px) {
        width:100px;
        height:100px;
        margin-bottom:1.5rem;
    }
}

.newsletter-slide__icon,
.newsletter-slide__brandicon{
    display: inline-block;
    position: absolute;
    left:50%;
    top:50%;
    transform: translateY(-50%) translateX(-50%);
    font-size:3.5rem;
    line-height:1px;
    //color:var(--color-highlight);
    color:#fff;
    @media screen and (min-width: 768px) {
        color:#fff;
        font-size:5rem;
    }
}

.newsletter-slide__icon{
    font-size:2rem;
    //color:#fff;
    color:#000;
    top: 48%;
    left: 53%;
    @media screen and (min-width: 768px) {
        color:#000;
        font-size:2.75rem;
    }
}