.sticky-btns {
    position: fixed;
    right:10px;
    top:50vh;
    transform: translateY(-50%);
    z-index:950;
    pointer-events:none;
    transition: all .25s linear;
    font-family: sans-serif;
    letter-spacing: 0;

    @media screen and (min-width: 768px) {
        right:30px;
        top:50%;
    }
}
.sticky-btns a {
    display: block;
    margin:1rem 0;
    position: relative;
    line-height:1px;
    pointer-events:auto;
    @media screen and (min-height: 800px) {
        margin:2rem 0;
    }
}
.icon.trip-planer__brandicon {display: none}
.sticky-btns a .trip-planer__brandicon,
.sticky-btns a .sticky-btns__brandicon{
    display: inline-block;
    position: relative;
    font-size:36px;
    color:var(--color-highlight);

    @media screen and (min-width: 768px) {
        font-size:50px;
    }
}

.sticky-btns .trip-planer-add.is-added .trip-planer-add__icon {
    color:#fff;
}


.sticky-btns a.active .sticky-btns__brandicon
{
    color:#F4D100;
}
@media screen and (min-width: 768px) {
    .sticky-btns a.active .trip-planer__brandicon,
    .sticky-btns a:focus .trip-planer__brandicon,
    .sticky-btns a:hover .trip-planer__brandicon,
    .sticky-btns a:hover .sticky-btns__brandicon {
        color:#F4D100;
    }
}
.sticky-btns a .sticky-btns__icon,
.sticky-btns a .trip-planer-add__icon{
    position: absolute;
    left:50%;
    top:50%;
    transform: translateY(-50%) translateX(-50%);
    font-size:18px;
    color:#fff;

    @media screen and (min-width: 768px) {
        font-size:22px;
    }
}

.show-sticky-btns.opened .sticky-btns__icon:before {
    content:var(--icon-close);
}
.show-sticky-btns.opened .sticky-btns__brandicon {
    color:#F4D100;
}

/* sticky-btns transitions */
.sticky-btns{
    transform: translateY(-50px);
    transition: transform .500s cubic-bezier(0.53, 0.03, 0.38, 1.2) .150s;
}
.sticky-btns.opened {
    transform: translateY(-50%);
}
.sticky-btns .hidden-sticky-btn {
    visibility: hidden;
    opacity: 0;
    transition: visibility .100s .250s, opacity .250s ease-in;

}
.sticky-btns.opened .hidden-sticky-btn {
    visibility: visible;
    opacity: 1;
    transition: visibility .100s,
        opacity .600s ease-out .750s,
        transform .200s ease;
}

@media screen and (min-width: 768px) {
    .hidden-sticky-btn {
        transform: scale(1);
    }

    .hidden-sticky-btn:hover,
    .hidden-sticky-btn:focus {
        transform: scale(1.2);
    }
}
.warning-popup,
.nl-popup {
    position: fixed;
    text-align: center;
    transition: all 350ms ease;
    z-index: 999999; /* kundenwunsch popup über datepicker */
    top:50%;
    left:50%;
    width: 100%;
    max-width:700px;

    @media screen and (max-width: 767px) {
        right: 0;
        left: 0;
        transform:translateX(-1500px) translateY(-50%);
        z-index: 970;
    }

    @media screen and (min-width: 768px) {
        transform: translateX(-2500px) translateY(-50%);
        left:50% !important;
    }
}
.warning-popup.active,
.nl-popup.active {
    @media screen and (max-width: 767px) {
        transform:translateX(0) translateY(-50%);
    }
    @media screen and (min-width: 768px) {
        transform:translateY(-50%) translateX(-50%) !important;
    }
}
.sticky-overlay {
    position: fixed;
    text-align: center;
    transition: all 350ms ease;
    z-index: 960;
    opacity:0;
    display: block;

    @media screen and (max-width: 767px) {
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        transform:translateX(100%) translateY(0);
        z-index: 970;
    }

    @media screen and (min-width: 768px) {
        left:10px;
        right:100px;
        top:50%;
        transform:translateX(-2500px) translateY(-50%);
    }
    @media screen and (min-width: 1200px) {
        left:120px;
        right:120px;
    }
}
.sticky-overlay.sticky-overlay--full-width {
    left: 0 !important;
    right: 0 !important;
    @media screen and (min-width: 768px) {
        transition: all .65s ease;
    }
}
.sticky-overlay.active {
    opacity:1;
    transform:translateX(0) translateY(0);
    @media screen and (min-width: 768px) {
        transform:translateX(0) translateY(-50%);
    }
}
.nl-popup.active {
    @media screen and (max-width: 767px) {
        transform: translateX(0) translateY(10%);
        top: unset!important;
        z-index: 10000!important;
        bottom: 1.25rem;
    }
}
.sticky-overlay__close {
    font-size: .825rem;
    position: absolute;
    right:1rem;
    top:1rem;
    z-index: 11111;
    @media screen and (min-width: 768px) {
        right:2rem;
        top:2rem;
        font-size:1.5rem;
    }
}
.sticky-overlay__content {
    color:#fff;
    width:100%;
    position: relative;
    margin:0 auto;
    max-width:1260px;
    background-color: rgba(0,0,0,0.92);
    padding:2rem 1.5rem 4.5rem 1.5rem;
    min-height:100vh;
    max-height:100vh;
    overflow-y:auto;
    overflow-x:hidden;
    @media screen and (min-width: 768px) {
        padding:4rem 2rem;
        min-height:0;
    }
}
.sticky-overlay__content--black-bg {
    background-color: black;
}
.sticky-overlay__content--warning-popup,
.sticky-overlay__content--nl-popup {
    background-color: rgba(0,0,0,0.75);
    padding:2rem 1.5rem 0rem 1.5rem;
    min-height:unset;
    max-height:unset;
    overflow:hidden;
    @media screen and (min-width: 768px) {
        padding:2.75rem 4rem 0 4rem;
        min-height:unset;
    }
}
.sticky-overlay__content--nl-popup {
@media screen and (max-width: 767px) {
    padding: 0.5rem .75rem;
}
}
@media screen and (max-width: 360px) and (max-height: 640px) {
    .sticky-overlay__content--nl-popup small {
        font-size:.75rem;
        line-height:1rem;
        display: inline-block;
    }
}
.sticky-overlay__content--nl-popup {
    background-color: rgba(0,0,0,1);
}
.nl-popup__img {
    position: relative;
    margin-bottom:-2px;
    @media screen and (max-width: 767px) {
        display:none;
    }
}
.sticky-overlay--full-width .sticky-overlay__content {
    max-width: unset;
}
.sticky-overlay__content-inner {
    width:100%;
    position: relative;
    margin:0 auto;
    max-width:1050px;
}
.sticky-overlay__content-inner a:not(.btn) {
    text-decoration: underline;
}
.sticky-overlay__content-inner a:hover,
.sticky-overlay__content-inner a:focus {
    color:var(--color-primary);
}
.sticky-overlay__headline {
    font-size:1.75rem;
    font-family:var(--font-special);
    margin-bottom:1.75rem;
    @media screen and (min-width: 768px) {
        font-size:2rem;
        margin-bottom:2.75rem;
    }
    @media screen and (max-width: 360px) and (max-height: 640px) {
        margin-bottom:.75rem;
    }
}
.sticky-overlay__list a {
    color:#fff;
    text-decoration: none;
}
.sticky-overlay__list a:hover,
.sticky-overlay__list a:focus {
    text-decoration: underline;
}
.sticky-overlay__sub-headline {
    font-size:1.25rem;
    font-family:var(--font-special);
    margin-bottom:1.5rem;
    @media screen and (min-width: 768px) {
        font-size:1.5rem;
        margin-bottom:2rem;
    }
}
.sticky-overlay__content-col {
    padding-top:2.5rem;
    padding-bottom:2.5rem;
    @media screen and (min-width: 768px) {
        margin:1.5rem 0;
    }
}

.sticky-overlay__content-col--with-border,
.sticky-overlay__content-col + .sticky-overlay__content-col {
    border-top:1px solid rgba(255,255,255,.8);
    @media screen and (min-width: 768px) {
        border-top:0;
        border-left:1px solid rgba(255,255,255,.8);
    }
}

.sticky-overlay__img {
    display: block;
    width:110px;
    height:auto;
    position: relative;
    margin:0 auto 1rem auto;
}
.sticky-overlay__weather-txt {
    margin:.5rem 0;

    @media screen and (min-width: 768px) {
        font-size:1.25rem;
    }
}

.sticky-overlay__col-icon {
    font-size:2.5rem;
    margin-bottom:.5rem;
    @media screen and (min-width: 768px) {
        margin-bottom:1.5rem;
    }
}

.pisten-amount-1,
.pisten-amount-2,
.pisten-amount-3 {
    display: block;
    width:2rem;
    height:2rem;
    line-height:2rem;
    text-align: center;
    border:1px solid #fff;
    border-radius: 50%;
    position: absolute;
    left:-12px;
    top:-45px;
}
.pisten-amount-1:after,
.pisten-amount-2:after,
.pisten-amount-3:after {
    content:'';
    display: block;
    width:1px;
    height:1rem;
    background:#fff;
    position: absolute;
    left:50%;
    top:100%;
}
.pisten-amount-2 {
    left:50%;
    top:-15%;
}
.pisten-amount-3 {
    left: auto;
    top: auto;
    right: -12px;
    bottom: 1rem;
}

.sticky-overlay__list>li {
    margin:.5rem 0;
    @media screen and (min-width: 768px) {
        margin:1rem 0;
    }
}

.sticky-overlay__heading {
    font-size:1.25rem;
    margin-bottom:1.5rem;
}

.sticky-overlay__contact {
    display: block;
    position: relative;
}
.sticky-overlay__contact:hover,
.sticky-overlay__contact:focus {
    text-decoration: none;
}
.sticky-overlay__contact .icon {
    position: relative;
    margin-right:.75rem;
    top:2px;
}




/* weather + acco */
.sticky-bottom-container {
    position: fixed;
    left:0;
    right:0;
    bottom:0;
    pointer-events: none;
    z-index: 960;
    transform: translateY(0);
    transition: all .25s linear;
}
.sticky-bottom-container--hidden {
    transform: translateY(60px);
}
.sticky-bottom-inner {
    position: relative;
    width:100%;
    margin:0 auto;
    max-width:1920px;
}
.sticky-bottom-inner * {
    pointer-events:initial;
}
@media screen and (min-width: 768px) {
    .acco-overlay,
    .weather-overlay {
        display: block;
        line-height:1;
        font-family: var(--font-special);
        position: absolute;
        left:0;
        bottom:0;
        height:60px;
        width:280px;
        padding:.125rem 1.25rem;
        background-color: rgba(0,0,0,0.85);
    }
    .acco-overlay a,
    .weather-overlay a {
        text-decoration: none !important;
        color:#fff !important;
    }
    .weather-overlay__temp {
        font-size:2.5rem;
    }
    .weather-overlay__town {
        font-size:1.5rem;
    }
    .acco-overlay {
        left:auto;
        right:0;
        width:80px;
    }
    .acco-overlay .icon {
        font-size:1.25rem;
        line-height:1;
        position: absolute;
        left:50%;
        top:50%;
        transform: translateY(-50%) translateX(-50%);
    }
    .acco-overlay.active .icon:before {
        content:var(--icon-close-thin);
    }
}

.quickfinder {
    position: fixed;
    left:0;
    right:0;
    bottom:0;
    width:auto;
    height:60px;
    padding:.5rem 1.25rem;
    background-color: rgba(0,0,0,0.85);
    transform:translateY(150px);
    transition: all 250ms ease;

    @media screen and (min-width: 768px) {
        position: absolute;
        left:280px;
        right:80px;
    }
}
.quickfinder--header {
    display: none;
    @media screen and (min-width: 1200px) {
        position: absolute;
        display: inline-block;
        top: 50%;
        left: 50%;
        right: auto;
        bottom: auto;
        transform: translateX(-50%) translateY(-110%);
        z-index: 1000;
        background-color: rgba(0,0,0,.75);
        border-radius: 0;
        display: flex;
        align-items: center;
        padding: calc(20rem/16) calc(12rem/16);
        min-height: calc(120rem/16);
        height: auto;
        min-width: calc(800rem/16);
    }
}
.is-campaign .quickfinder--header {
    display: none; /* hide quickfinder if campaign page */
}
.quickfinder__title {
    color: white;
    font-size: calc(30rem/16);
    font-family: var(--font-special);
    text-align: center;
    margin-bottom: 6px;
    line-height: 1.1;
}
.quickfinder__inner {
    position: relative;
    margin:0 auto;
    width:100%;
    max-width:850px;
}
.quickfinder form {
    position: relative;
    margin:0 auto;
    max-width:1200px;
}
.quickfinder .form-control {
    padding: .375rem .75rem;
}
.quickfinder.active {
    transition: all 250ms ease;
    transform:translateY(0) translateX(0);
    visibility: visible;
}



@media screen and (max-width: 767px) {
    .quickfinder__btn {
        display: none;
        width:60px;
        height:60px;
        position: absolute;
        right:10px;
        bottom:10px;
        z-index:999;
        transition: all 250ms ease;
    }
    .quickfinder__btn .quickfinder__btn-icon,
    .quickfinder__btn .quickfinder__btn-brandicon{
        display: inline-block;
        position: absolute;
        left:50%;
        top:50%;
        transform: translateY(-50%) translateX(-50%);
        font-size:50px;
        line-height:1px;
        color:rgba(0,0,0,.8);
    }

    .quickfinder__btn .quickfinder__btn-icon{
        font-size:15px;
        color:#fff;
    }
    .quickfinder__btn.is-active{
        transform: translateY(-50px);
    }
    .quickfinder__btn.is-active .quickfinder__btn-icon:before{
        content:var(--icon-close-thin);
    }
}

.sticky-bottom-container .twitter-typeahead .tt-menu {
    top: auto !important;
    bottom: 100%;
}
