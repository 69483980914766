.slider--region {
    @media screen and (max-width: 767px) {
        margin:0 -1rem;
    }
}

@media screen and (max-width: 767px) {
    .region-map.region-map--regionen {
        margin-left: -95px;
        margin-right: -60px;
    }
    .region-map .disable-mobile-click {
        position: absolute;
        left:0;
        top:0;
        right:0;
        bottom:0;
        background: transparent;
        z-index:10;
    }
}

.region-map__overview {
    position: absolute;
    top: 30px;
    left: 0;
}


/* new teaser relaunch 2018 */

@media screen and (min-width: 768px) {
    .region-map-teaser__img-container {
        position: relative;
        margin:0 -.125rem;
    }
}
.region-map-teaser {
    position: relative;
}
.region-map-teaser__body {
    position: relative;
    top:-.125rem;
    padding:1rem;
    color:var(--color-dark);
    background:var(--color-dark-grey);
    font-size:.875rem;
}

.region-map-teaser:hover .region-map-teaser__body{
    background:#fff;
}
.region-map-teaser__label{
    font-family:var(--font-special);
    font-size:1.5rem;
    color:#fff;
    position: absolute;
    top:50%;
    left:50%;
    transform:translateY(-50%) translateX(-50%);
    text-shadow: 0 2px 4px rgba(0,0,0,0.3);
    text-align: center;
    white-space: nowrap;
    @media screen and (min-width: 768px) {
        padding:1rem 1.25rem;
        background:var(--color-light-grey);
        color:var(--color-text-default);
        font-size:1.375rem;
        border-radius: 3px;
        text-shadow:none;
    }
}

.region-map-teaser:hover .region-map-teaser__label>a,
.region-map-teaser__label>a,
.region-map-teaser__label>a:hover,
.region-map-teaser__label>a:focus{
    text-decoration: none;
    color:#fff;
    @media screen and (min-width: 768px) {
        color:var(--color-text-default);
    }
}

.region-map-teaser:hover .region-map-teaser__label{
    @media screen and (min-width: 768px) {
        background:#fff;
    }
}

.region-map-teaser__img {
    margin-bottom:.5rem;
    @media screen and (min-width: 768px) {
        border:.125rem solid transparent;
        margin-bottom:0;
    }
}