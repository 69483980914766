/*Season*/
.widget--best-season__month {
    position: relative;
    height: 132px;
    border-right: 1px solid #e2e2e2;
}
.widget--best-season__month:first-child {
    border-left: 1px solid #e2e2e2;
}
.widget--best-season__month-label {
    display: block;
    padding:1px 5px;
    position: absolute;
    left: 50%;
    bottom: -18px;
    width: 80px;
    font-size: 12px;
    transform-origin: left top;
    transform: rotate(-90deg) translateY(-50%);
    color: #fff;

    @media screen and (min-width: 1200px) {
        padding:4px 5px;
        bottom: -25px;
    }
}
.widget--best-season__month-label--highlight {
    background: var(--color-secondary);
}
.widget--best-season__month-sun {
    position: absolute;
    top: -1px;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 18px;
    color: #f4d100;
}
.widget--best-season__month-sun--low {
    top: 24px;
}
.widget--best-season__month-sun--medium {
    top: 7px;
}