/* no gutter */
.row.row--gutter-width-0 {
    margin-left:0;
    margin-right:0;
}
.row--gutter-width-0 > .col,
.row--gutter-width-0 > [class^="col"],
.row--gutter-width-0 > [class*=" col"] {
    padding-left:0;
    padding-right:0;
}


/* pixel gutters */
.row.row--gutter-width-10 {
    margin-left:-5px;
    margin-right:-5px;
}
.row--gutter-width-10 > .col,
.row--gutter-width-10 > [class^="col"],
.row--gutter-width-10 > [class*=" col"] {
    padding-left:5px;
    padding-right:5px;
}
.row.row--gutter-width-20 {
    margin-left:-10px;
    margin-right:-10px;
}
.row--gutter-width-20 > .col,
.row--gutter-width-20 > [class^="col"],
.row--gutter-width-20 > [class*=" col"] {
    padding-left:10px;
    padding-right:10px;
}
@media screen and (min-width: 768px) {
    .row.row--gutter-width-100 {
        margin-left:-50px;
        margin-right:-50px;
    }
    .row--gutter-width-100 > .col,
    .row--gutter-width-100 > [class^="col"],
    .row--gutter-width-100 > [class*=" col"] {
        padding-left:50px;
        padding-right:50px;
    }
}


/* rem gutters */
.row-gutter--1 {
    margin-left: -.125rem;
    margin-right: -.125rem;
}
.row-gutter--1 > .col,
.row-gutter--1 > [class^="col"],
.row-gutter--1 > [class*=" col"] {
    padding-left: .125rem;
    padding-right: .125rem;
}
.row-gutter--2 {
    margin-left: -.25rem;
    margin-right: -.25rem;
}
.row-gutter--2 > .col,
.row-gutter--2 > [class^="col"],
.row-gutter--2 > [class*=" col"] {
    padding-left: .25rem;
    padding-right: .25rem;
}
.row-gutter--3 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
}
.row-gutter--3 > .col,
.row-gutter--3 > [class^="col"],
.row-gutter--3 > [class*=" col"] {
    padding-left: .5rem;
    padding-right: .5rem;
}
.row-gutter--4 {
    margin-left: -.75rem;
    margin-right: -.75rem;
}
.row-gutter--4 > .col,
.row-gutter--4 > [class^="col"],
.row-gutter--4 > [class*=" col"] {
    padding-left: .75rem;
    padding-right: .75rem;
}




/*use a media query for bigger row gutters; otherwise the page is scrollable on mobile devices */
@media screen and (min-width: 768px) {
    .row-gutter--5 {
        margin-left: -1.5rem;
        margin-right: -1.5rem;
    }
    .row-gutter--5 > .col,
    .row-gutter--5 > [class^="col"],
    .row-gutter--5 > [class*=" col"] {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}



/* vertical gutter */
.vertical-gutter--1 {
    margin-top: -.25rem;
}
.vertical-gutter--1 > .vertical-gutter__item {
    margin-top: .25rem;
}
.vertical-gutter--2 {
    margin-top: -.5rem;
}
.vertical-gutter--2 > .vertical-gutter__item {
    margin-top: .5rem;
}
.vertical-gutter--3 {
    margin-top: -1rem;
}
.vertical-gutter--3 > .vertical-gutter__item {
    margin-top: 1rem;
}
.vertical-gutter--4 {
    margin-top: -1.5rem;
}
.vertical-gutter--4 > .vertical-gutter__item {
    margin-top: 1.5rem;
}
.vertical-gutter--5 {
    margin-top: -3rem;
}
.vertical-gutter--5 > .vertical-gutter__item {
    margin-top: 3rem;
}
