.twitter-typeahead {
    width: 100%;
}
.tt-dataset:empty {
    display: none;
}
.tt-suggestion__link:hover,
.tt-suggestion__link:focus,
.tt-selectable:hover,
.tt-selectable:focus,
.tt-selectable.tt-cursor {
    cursor: pointer;
    /*background: #f0f0f0;*/
}
.twitter-typeahead .tt-input,
.twitter-typeahead .tt-hint {
    width: 100%;
}
.tt-hint {
    color: #D8D8D8;
}
.tt-menu {
    width: 100%;
}
.tt-dataset {
    background: #fff;
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
    padding:.5rem 1.5rem;
    border-radius: 4px;
    box-shadow: 2px 6px 10px 1px rgba(0,0,0,.2);
}
.tt-suggestion {
    padding:.5rem 0;
    color:var(--color-text-default);
    border-top:1px solid #D8D8D8;
}
.tt-suggestion:first-of-type {
    border-top:0;
}
.tt-suggestion__link {
    padding:.5rem 0;
    font-family:var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
    font-size: 16px;
    display: block;
    text-decoration:none !important;
}

@media screen and (max-width: 767px) {
    .input-group > .twitter-typeahead {
        position: static !important;
    }
}