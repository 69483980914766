.img-with-subtitle__wrapper {
    background-color: var(--color-light-grey);
}
.img-with-subtitle__element {
    display: inline-block;
}
.img-with-subtitle__text {
    padding: .5rem 1rem;
    font-family: var(--font-special);
    color: var(--color-dark);
    font-size: 1rem;
    text-align: center;
}