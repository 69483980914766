.anlagen-icon {
    position: relative;
    top:.125rem;
    margin-left:.5rem;
    line-height:1px;
}

.anlagen-status {
    font-size:18px;
}
.anlagen-status--opened {
    color:#A9BF00;
}
.anlagen-status--closed {
    color:var(--color-highlight);
}

.accordion--anlagen .card {
    position: relative;
    background-color: transparent;
    border: 0;
    border-radius: 0;
}
.accordion--anlagen .card-header {
    padding:0;
    margin-bottom: 0;
    border-bottom: 0;
    background-color:transparent;
    text-align: center;
}
.accordion--anlagen .card-header>a {
    display: inline-block;
    color: #262626 !important;
    font-size:1.5rem;
    font-family: var(--font-special);
    padding: .25rem 0rem;
    border-bottom:2px solid var(--color-highlight);
}
.accordion--anlagen .card-header>a:after {
    display: block;
    right:-28px;
    top: 18px;
    width: 10px;
    height: 10px;
}
.accordion--anlagen .card-header>a.collapsed:after {
    top: 12px;
}
.accordion--anlagen .card-header>a.collapsed {
    border-color:transparent;
}
.accordion--anlagen .card-body{
    padding:0;
}


.nav-tabs--anlagen {
    border:0;
    border-color:transparent;
    margin-bottom:2.5rem;
}
.nav-tabs--anlagen .nav-item {
    border:0;
    background: transparent !important;
}
.nav-tabs--anlagen .nav-link {
    display: inline-block;
    padding:.5rem 0;
    color: #262626;
    font-size:1.5rem;
    font-family: var(--font-special);
    border-color:transparent;
    border-radius: 0;
    border-width:0 0 2px 0;
}
.nav-tabs--anlagen .nav-link:focus,
.nav-tabs--anlagen .nav-link:hover,
.nav-tabs--anlagen .nav-link.active {
    background: transparent;
    border-radius: 0;
    border-color:var(--color-highlight);
}


.anlage-difficulty {
    display: inline-block;
    width:22px;
    height:22px;
    border-radius:50%;
    @media screen and (max-width: 767px) {
        width:18px;
        height:18px;
    }
}

.anlage-difficulty--red {
    background:var(--color-highlight);
}
.anlage-difficulty--blue {
    background:#a9cde9;
}
.anlage-difficulty--black {
    background:#000;
}

@media screen and (max-width: 767px) {
    .swipe-container{
        overflow-x: auto;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
        white-space: nowrap;
        display: block;
        padding:1rem 0;
        margin-left:-3px;
    }
    .swipe-item{
        margin:1rem 3px;
        display: inline-block;
        position: relative;
        width:85%;
        color:var(--color-dark);
        padding:1rem 1.75rem;
        background: #fff;
        white-space: normal;
        -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.15);
        -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.15);
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.15);
    }
    .swipe-item__row {
        margin:1.25rem 0;
        white-space: normal;
    }
    .swipe-item__hl {
        text-align: center;
        margin:1.25rem 0;
        font-size:1.25rem;
        font-family: var(--font-special);
    }
    .swipe-item__hl .icon {
        color:var(--color-text-default);
    }
    .swipe-item__lbl {
        font-size:1.05rem;
        font-family: var(--font-special);
    }
}