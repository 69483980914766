.inquiry {

}
.inquiry__multiple-inquiry-form {
    padding: 20px;
    justify-content: space-between;
}
.inquiry__data-form {
    padding-bottom: 20px;
}


/* new */
.multiple-inquiry-item {
    padding: calc(18rem / 16) calc(20rem / 16);
}
.multiple-inquiry-item + .multiple-inquiry-item {
    margin-top: 1px;
}
.multiple-inquiry-item__title {
    font-size: calc(18rem / 16);
}
.multiple-inquiry-item__stars {
    @media screen and (max-width:768px) {
        display: inline-block;
        font-size: .7rem;
        transform: translateY(-25%);
    }
}
.multiple-inquiry-item__trustyou {
    text-align: center;
    font-size: calc(16rem / 16);
    line-height: 1;
}
.multiple-inquiry-item__trustyou__logo {
    vertical-align: 0;
}
.multiple-inquiry-btn {
    display: block;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translateY(100%);
    text-align: center;
    z-index: 50;
    transform-origin: left center;
    background: var(--color-primary);
    color: #fff;
    font-size: calc(14rem / 16);
    padding: calc(7rem / 16) calc(20rem / 16) calc(10rem / 16);
    opacity: 0;
    transition: transform 200ms ease-in-out, opacity 200ms ease-in-out;

    @media screen and (min-width: 768px) {
        top: 46%;
        right: auto;
        bottom: auto;
        border-bottom-left-radius: calc(4rem / 16);
        border-bottom-right-radius: calc(4rem / 16);
        transform: rotate(-90deg) translateX(-50%) translateY(-50%);
        font-size: calc(24rem / 16);
        padding: calc(10rem / 16) calc(42rem / 16) calc(19rem / 16);
    }
}
.is-active.multiple-inquiry-btn {
    transform: unset;
    @media screen and (min-width: 768px) {
        transform: rotate(-90deg) translateX(-50%) translateY(50%);
    }
    opacity: 1;
}
.multiple-inquiry-btn:hover {
    background: var(--color-primary-dark);
    color: #fff;
    text-decoration: none;
}
.multiple-inquiry-btn__count {
    /*position: absolute;
    right:0;
    top:0;
    display: inline-block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-size: 12px;
    margin-left:.5rem;
    color: #000;
    font-family: var(--font-default);
    background: #fff;
    border-radius: 50%;*/
    position: relative;
    top:-1px;
    font-size: 1rem;

    @media screen and (min-width: 768px) {
        transform: rotate(90deg);
        display: inline-block;
    }
}