/*.merklist.merklist.merklist{*/
    /**/
/*}*/

@media screen and (max-width: 767px) {
    .merklist__teaser-wrapper {
        margin-bottom: 40px;
    }
}
@media screen and (min-width: 768px) {
    .merklist__teaser-wrapper {
        margin-top: 40px;
    }
}
.merklist__teaser {
    position: relative;
    background: #ffffff;
    margin-bottom: 0;
    padding: 15px;
    max-height: 200px;
}
@media screen and (min-width: 768px) {
    .merklist__teaser {
        padding: 30px 20px 20px 20px;
        height: 200px;
    }
}