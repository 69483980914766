.img-checkbox.img-checkbox {
    position: relative;
}
.img-checkbox label {
    margin:0;
}

.img-checkbox__input {
    position: absolute;
    width: calc(1rem/16);
    height: calc(1rem/16);
    margin: calc(-1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}

.img-checkbox__box {
    border: 3px solid transparent;
    cursor: pointer;
}

.img-checkbox__img {
    opacity: 1;
    transition: opacity .2s ease;
    pointer-events: none;
}

.img-checkbox label:hover .img-checkbox__box,
.img-checkbox label:focus .img-checkbox__box{
    @media screen and (min-width: 768px) {
        border-color: var(--color-secondary);
    }
}

.img-checkbox__input:checked ~ .img-checkbox__box {
    border-color: var(--color-secondary);
    background-color: var(--color-secondary);
}

.img-checkbox__input:checked ~ .img-checkbox__box > .img-checkbox__img {
    opacity: .6;
}

.img-checkbox .img-checkbox__text {
    display: block;
    padding:.5rem .125rem;
    font-family: var(--font-special);
    font-size: .75rem;
    text-align: center;
    color:var(--color-dark);
    background-color: var(--color-light-grey);
}

.img-checkbox--overlay .img-checkbox__text {
    position: absolute;
    top: 50%;
    left: 0;
    right:0;
    transform: translateY(-50%);
    color: var(--color-white);
    font-size: 1.25rem;
    text-shadow: 2px 1px 3px var(--color-dark);
    pointer-events: none;
    word-break: break-all;
    background:transparent;

    @media screen and (max-width: 1399px) {
        font-size: .9375rem;
    }
}
