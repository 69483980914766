.meeting-location-rooms {
    overflow-x: hidden;
}
.meeting-location-rooms__item {
    text-align: center;
    margin:.5rem auto 1.625rem auto;
}
.meeting-location-rooms__item .icon {
    font-size:1.5rem;
    display: block;
    margin:.625rem auto;
}


.meeting-keyfacts {
    position: relative;
    display: inline-block;
    padding-left:1.125rem;
    margin:0 .5rem 0 0;
    font-size:.75rem;
    font-family: var(--font-default);
    color:var(--color-text-default);
    @media screen and (min-width: 768px) {
        font-size:1rem;
        margin:0 1.75rem 0 0;
        padding-left:1.5rem;
    }
}
.meeting-keyfacts .icon {
    position: absolute;
    left:0;
    top:0;
    font-size:.9375rem;
    @media screen and (min-width: 768px) {
        font-size:1.125rem;
    }
}