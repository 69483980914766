.btn {
    white-space: nowrap;
    position: relative;
    font-size: 1rem;
    text-align: center;
    font-family: var(--font-special);
    color: var(--color-primary);
    padding:.5rem 0rem;
    border:0;
    border-radius:0;
    box-shadow:none !important;
    @media screen and (min-width: 768px) {
        font-size: 1.125rem;
    }
}
html:not(.has-preloaded-fonts) .btn {
    font-size: calc(15rem / 16);
    line-height: 1.62;
    letter-spacing: calc(-0.03em / 15);
}
.btn--transparent {
    background: transparent;
}
.btn.btn.btn--nostyle {
    background: transparent;
    margin:0;
    min-width:0;
    text-indent: 0;
}
.btn:not(.btn-secondary) {
    text-indent:46px;
    margin-right:48px;
    min-width:130px;
    height:40px;
}
.btn-block:not(.btn-secondary) {
    width:calc(100% - 48px);
}
.btn-block--lg {
    @media screen and (min-width: 768px) {
        display: block;
    }
}
.btn:not(.btn-secondary):after {
    content:'';
    display: block;
    width:48px;
    height:40px;
    position: absolute;
    right:-48px;
    top:0;
}
.btn-scale-tiny {
    transform: scale(.75, .75);
}
.btn-scale-tiny--xs {
    @media screen and (max-width: 767px) {
        transform: scale(.75, .75);
    }
}

@media screen and (max-width: 1200px) {
    .btn--narrow {
        padding-left:.125rem;
        padding-right:.125rem;
    }
}

.btn--loading{
    cursor:progress;
}


/* fancy buttons base */
.btn.btn-primary,
.btn.btn-primary--colored,
.btn.btn-success,
.btn.btn-white,
.btn.btn-highlight,
.btn.btn-green,
.btn.btn-lightblue,
.btn.btn-lightgreen,
.btn.btn-yellow,
.btn.btn-lila,
.btn.btn-default{
    background-color:transparent !important;
    background-size:468px 40px;
    background-position:0 50%;
    background-repeat:no-repeat;
}
.btn.btn-primary:after,
.btn.btn-primary--colored:after,
.btn.btn-success:after,
.btn.btn-white:after,
.btn.btn-highlight:after,
.btn.btn-green:after,
.btn.btn-lightblue:after,
.btn.btn-lightgreen:after,
.btn.btn-yellow:after,
.btn.btn-lila:after,
.btn.btn-default:after{
    background-size:48px 40px;
}


/* primary + success + colored */
.btn.btn-primary,
.btn.btn-primary--colored,
.btn.btn-success{
    color:var(--color-primary);
    background-image: url(static/img/relaunch2018/buttons/primary-left.svg);
}
.btn.btn-primary:after,
.btn.btn-primary--colored:after,
.btn.btn-success:after {
    background-image: url(static/img/relaunch2018/buttons/primary-right.svg);
}
.btn.btn-primary--colored,
.btn.btn-primary:focus,
.btn.btn-primary:hover,
.btn.btn-success:focus,
.btn.btn-success:hover {
    color:#fff;
    background-image: url(static/img/relaunch2018/buttons/primary-left-filled.svg);
}
.btn.btn-primary--colored:after,
.btn.btn-primary:focus:after,
.btn.btn-primary:hover:after,
.btn.btn-success:focus:after,
.btn.btn-success:hover:after {
    background-image: url(static/img/relaunch2018/buttons/primary-right-filled.svg);
}
.btn.btn-primary--colored:focus,
.btn.btn-primary--colored:hover {
    color:var(--color-primary);
    background-image: url(static/img/relaunch2018/buttons/primary-left.svg);
}
.btn.btn-primary--colored:focus:after,
.btn.btn-primary--colored:hover:after {
    background-image: url(static/img/relaunch2018/buttons/primary-right.svg);
}

/* overwrite orange ctas for special pages */

.has-grey-ctas .btn.btn-primary,
.has-grey-ctas .btn.btn-primary--colored,
.has-grey-ctas .btn.btn-success{
    color:var(--color-text-default);
    background-image: url(static/img/relaunch2018/buttons/default-left.svg);
}
.has-grey-ctas .btn.btn-primary:after,
.has-grey-ctas .btn.btn-primary--colored:after,
.has-grey-ctas .btn.btn-success:after {
    background-image: url(static/img/relaunch2018/buttons/default-right.svg);
}
.has-grey-ctas .btn.btn-primary--colored,
.has-grey-ctas .btn.btn-primary:focus,
.has-grey-ctas .btn.btn-primary:hover,
.has-grey-ctas .btn.btn-success:focus,
.has-grey-ctas .btn.btn-success:hover {
    color:#fff;
    background-image: url(static/img/relaunch2018/buttons/default-left-filled.svg);
}
.has-grey-ctas .btn.btn-primary--colored:after,
.has-grey-ctas .btn.btn-primary:focus:after,
.has-grey-ctas .btn.btn-primary:hover:after,
.has-grey-ctas .btn.btn-success:focus:after,
.has-grey-ctas .btn.btn-success:hover:after {
    background-image: url(static/img/relaunch2018/buttons/default-right-filled.svg);
}
.has-grey-ctas .btn.btn-primary--colored:focus,
.has-grey-ctas .btn.btn-primary--colored:hover {
    color:var(--color-text-default);
    background-image: url(static/img/relaunch2018/buttons/default-left.svg);
}
.has-grey-ctas .btn.btn-primary--colored:focus:after,
.has-grey-ctas .btn.btn-primary--colored:hover:after {
    background-image: url(static/img/relaunch2018/buttons/default-right.svg);
}


/* white + colored */
.btn.btn-white {
    color:#fff;
    background-image: url(static/img/relaunch2018/buttons/white-left.svg);
}
.btn.btn-white:after {
    background-image: url(static/img/relaunch2018/buttons/white-right.svg);
}
.btn.btn-white--colored,
.btn.btn-white:focus,
.btn.btn-white:hover {
    color:var(--color-dark);
    background-image: url(static/img/relaunch2018/buttons/white-left-filled.svg);
}
.btn.btn-white--colored:after,
.btn.btn-white:focus:after,
.btn.btn-white:hover:after {
    background-image: url(static/img/relaunch2018/buttons/white-right-filled.svg);
}
.btn.btn-white--colored:focus,
.btn.btn-white--colored:hover {
    color:#fff;
    background-image: url(static/img/relaunch2018/buttons/white-left.svg);
}
.btn.btn-white--colored:focus:after,
.btn.btn-white--colored:hover:after {
    background-image: url(static/img/relaunch2018/buttons/white-right.svg);
}


/* default */
.btn.btn-default {
    color:var(--color-text-default);
    background-image: url(static/img/relaunch2018/buttons/default-left.svg);
}
.btn.btn-default:after {
    background-image: url(static/img/relaunch2018/buttons/default-right.svg);
}
.btn.btn-default--colored,
.btn.btn-default:focus,
.btn.btn-default:hover {
    color:#fff;
    background-image: url(static/img/relaunch2018/buttons/default-left-filled.svg);
}
.btn.btn-default--colored:after,
.btn.btn-default:focus:after,
.btn.btn-default:hover:after {
    background-image: url(static/img/relaunch2018/buttons/default-right-filled.svg);
}
.btn.btn-default--colored:focus,
.btn.btn-default--colored:hover {
    color:var(--color-text-default);
    background-image: url(static/img/relaunch2018/buttons/default-left.svg);
}
.btn.btn-default--colored:focus:after,
.btn.btn-default--colored:hover:after {
    background-image: url(static/img/relaunch2018/buttons/default-right.svg);
}


/* highlight */
.btn.btn-highlight {
    color:var(--color-highlight);
    background-image: url(static/img/relaunch2018/buttons/highlight-left.svg);
}
.btn.btn-highlight:after {
    background-image: url(static/img/relaunch2018/buttons/highlight-right.svg);
}
.btn.btn-highlight--colored,
.btn.btn-highlight:focus,
.btn.btn-highlight:hover {
    color:#fff;
    background-image: url(static/img/relaunch2018/buttons/highlight-left-filled.svg);
}
.btn.btn-highlight--colored:after,
.btn.btn-highlight:focus:after,
.btn.btn-highlight:hover:after {
    background-image: url(static/img/relaunch2018/buttons/highlight-right-filled.svg);
}
.btn.btn-highlight--colored:focus,
.btn.btn-highlight--colored:hover {
    color:var(--color-highlight);
    background-image: url(static/img/relaunch2018/buttons/highlight-left.svg);
}
.btn.btn-highlight--colored:focus:after,
.btn.btn-highlight--colored:hover:after {
    background-image: url(static/img/relaunch2018/buttons/highlight-right.svg);
}


/* green */
.btn.btn-green {
    color:var(--color-green);
    background-image: url(static/img/relaunch2018/buttons/green-left.svg);
}
.btn.btn-green:after {
    background-image: url(static/img/relaunch2018/buttons/green-right.svg);
}
.btn.btn-green--colored,
.btn.btn-green:focus,
.btn.btn-green:hover {
    color:#fff;
    background-image: url(static/img/relaunch2018/buttons/green-left-filled.svg);
}
.btn.btn-green--colored:after,
.btn.btn-green:focus:after,
.btn.btn-green:hover:after {
    background-image: url(static/img/relaunch2018/buttons/green-right-filled.svg);
}
.btn.btn-green--colored:focus,
.btn.btn-green--colored:hover {
    color:var(--color-green);
    background-image: url(static/img/relaunch2018/buttons/green-left.svg);
}
.btn.btn-green--colored:focus:after,
.btn.btn-green--colored:hover:after {
    background-image: url(static/img/relaunch2018/buttons/green-right.svg);
}


/* lightblue */
.btn.btn-lightblue {
    color:var(--color-light-blue);
    background-image: url(static/img/relaunch2018/buttons/lightblue-left.svg);
}
.btn.btn-lightblue:after {
    background-image: url(static/img/relaunch2018/buttons/lightblue-right.svg);
}
.btn.btn-lightblue--colored,
.btn.btn-lightblue:focus,
.btn.btn-lightblue:hover {
    color:#fff;
    background-image: url(static/img/relaunch2018/buttons/lightblue-left-filled.svg);
}
.btn.btn-lightblue--colored:after,
.btn.btn-lightblue:focus:after,
.btn.btn-lightblue:hover:after {
    background-image: url(static/img/relaunch2018/buttons/lightblue-right-filled.svg);
}
.btn.btn-lightblue--colored:focus,
.btn.btn-lightblue--colored:hover {
    color:var(--color-light-blue);
    background-image: url(static/img/relaunch2018/buttons/lightblue-left.svg);
}
.btn.btn-lightblue--colored:focus:after,
.btn.btn-lightblue--colored:hover:after {
    background-image: url(static/img/relaunch2018/buttons/lightblue-right.svg);
}


/* lightgreen */
.btn.btn-lightgreen {
    color:var(--color-light-green);
    background-image: url(static/img/relaunch2018/buttons/lightgreen-left.svg);
}
.btn.btn-lightgreen:after {
    background-image: url(static/img/relaunch2018/buttons/lightgreen-right.svg);
}
.btn.btn-lightgreen--colored,
.btn.btn-lightgreen:focus,
.btn.btn-lightgreen:hover {
    color:#fff;
    background-image: url(static/img/relaunch2018/buttons/lightgreen-left-filled.svg);
}
.btn.btn-lightgreen--colored:after,
.btn.btn-lightgreen:focus:after,
.btn.btn-lightgreen:hover:after {
    background-image: url(static/img/relaunch2018/buttons/lightgreen-right-filled.svg);
}
.btn.btn-lightgreen--colored:focus,
.btn.btn-lightgreen--colored:hover {
    color:var(--color-light-green);
    background-image: url(static/img/relaunch2018/buttons/lightgreen-left.svg);
}
.btn.btn-lightgreen--colored:focus:after,
.btn.btn-lightgreen--colored:hover:after {
    background-image: url(static/img/relaunch2018/buttons/lightgreen-right.svg);
}


/* yellow */
.btn.btn-yellow {
    color:var(--color-yellow);
    background-image: url(static/img/relaunch2018/buttons/yellow-left.svg);
}
.btn.btn-yellow:after {
    background-image: url(static/img/relaunch2018/buttons/yellow-right.svg);
}
.btn.btn-yellow--colored,
.btn.btn-yellow:focus,
.btn.btn-yellow:hover {
    color:#fff;
    background-image: url(static/img/relaunch2018/buttons/yellow-left-filled.svg);
}
.btn.btn-yellow--colored:after,
.btn.btn-yellow:focus:after,
.btn.btn-yellow:hover:after {
    background-image: url(static/img/relaunch2018/buttons/yellow-right-filled.svg);
}
.btn.btn-yellow--colored:focus,
.btn.btn-yellow--colored:hover {
    color:var(--color-yellow);
    background-image: url(static/img/relaunch2018/buttons/yellow-left.svg);
}
.btn.btn-yellow--colored:focus:after,
.btn.btn-yellow--colored:hover:after {
    background-image: url(static/img/relaunch2018/buttons/yellow-right.svg);
}


/* lila */
.btn.btn-lila {
    color:var(--color-lila);
    background-image: url(static/img/relaunch2018/buttons/lila-left.svg);
}
.btn.btn-lila:after {
    background-image: url(static/img/relaunch2018/buttons/lila-right.svg);
}
.btn.btn-lila--colored,
.btn.btn-lila:focus,
.btn.btn-lila:hover {
    color:#fff;
    background-image: url(static/img/relaunch2018/buttons/lila-left-filled.svg);
}
.btn.btn-lila--colored:after,
.btn.btn-lila:focus:after,
.btn.btn-lila:hover:after {
    background-image: url(static/img/relaunch2018/buttons/lila-right-filled.svg);
}
.btn.btn-lila--colored:focus,
.btn.btn-lila--colored:hover {
    color:var(--color-lila);
    background-image: url(static/img/relaunch2018/buttons/lila-left.svg);
}
.btn.btn-lila--colored:focus:after,
.btn.btn-lila--colored:hover:after {
    background-image: url(static/img/relaunch2018/buttons/lila-right.svg);
}



/* button with icon */
.btn--with-icon .icon {
    font-size:1rem;
    position: relative;
    margin-right:.75rem;
    text-indent: 0;
    top:2px;
}



/* default secondary button */
.btn-secondary {
    font-family: var(--font-default);
    white-space: normal;
    padding:.25rem 1.5rem;
    height: calc(2.25rem + 2px);
    background:var(--color-primary);
    color:#fff;
    @media screen and (min-width: 768px) {
        font-size:1rem;
    }
}
.btn-secondary:hover,
.btn-secondary:focus {
    background:var(--color-primary-dark);
}

.btn--filter-toggle .icon {
    font-size: calc(16rem/16);
    position: absolute;
    left: calc(-26rem/16);
    top: 53%;
}