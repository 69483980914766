.wysiwyg ul,
.wysiwyg ol,
.list-default,
.list-arrow {
    padding: 0;
}

.wysiwyg li,
.list-default li,
.list-arrow li {
    list-style-type: none;
}

/*.list-arrow li.active {*/
    /*text-decoration: underline;*/
/*}*/

/*ul*/
.wysiwyg ul li,
ul.list-default li {
    padding-left: 20px;
    text-indent: -9px;
}
.wysiwyg ul li:before,
ul.list-default li:before {
    position: relative;
    top:-3px;
    content: "";
    width:6px;
    height:6px;
    display: inline-block;
    margin-right: 9px;
    border:1px solid var(--color-primary);
    border-width:2px 2px 0 0 ;
    transform: rotate(45deg);
}

/*ol*/
.wysiwyg ol,
ol.list-default,
ol.list-default ol { counter-reset: item }

.wysiwyg ol li:before,
ol.list-default li:before {
    display: inline-block;
    min-width: 25px;
    content: counter(item) ". ";
    counter-increment: item;
    color: var(--color-primary);
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
}

/*list-arrow*/
.list-arrow li,
.list-arrow__item {
    padding-left: 16px;
    text-indent: -16px;
    margin-bottom: 8px;
}
.list-arrow li:before,
.list-arrow__item:before {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 3px 0 3px 4px;
    border-color: transparent transparent transparent var(--color-primary);
    vertical-align: middle;
    margin-right: 12px;
}

/*list inline border*/
.list-inline--border>li {
    padding-left: 0;
}
.list-inline--border>li:before {
    content: '|';
    margin-right: 8px;
    font-size: 1.05em;
}
.list-inline--border>li:first-child {
    padding-left: 5px;
}
.list-inline--border>li:first-child:before {
    content: '';
    margin-right: 0;
    display: none;
}

.list-check {
    list-style-type: none;
    padding-left: 15px;
}
.list-check li {
    padding-left: 15px;
    text-indent: -15px;
    margin-bottom: 6px;
}
.list-check li:before {
    content: "\E00E";
    margin-right: 14px;
    color: #a9bf00;

    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}
.text-col--3 .list-check li:before,
.text-col--4 .list-check li:before {
    /* fix for chrome otherwise the icons are wrongly positioned eg: https://www.suedtirol.info/de/unterkuenfte/alle-unterkuenfte/villa-thaler_a_3040461*/
    transform: none;
}
.list-check--color-secondary li:before {
    color: var(--color-secondary);
}
.list-check .list-check__error:before {
    color: var(--color-highlight);
    content: '\E025';
}

.list-brand-icon {
    padding: 0;
    list-style-type: none;
}
.list-brand-icon li {
    text-indent: -10px;
    padding-left: 22px;
    margin-bottom: 6px;
}
.list-brand-icon li:before {
    content: "\E005";
    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--color-primary);
    font-size: 7px;
    vertical-align: 0.4em;
    margin-right: 10px;
}