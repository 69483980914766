.arrow-link {
    display: inline-block;
    text-decoration: none;
    text-indent: -1.21em;
    padding-left: 1.21em;
}
.arrow-link:before {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 3px 0 3px 4px;
    border-color: transparent transparent transparent var(--color-primary);
    margin-right: 10px;
    vertical-align: .1em;
    transition: transform 100ms ease-out;
}
.arrow-link:hover {
    text-decoration: none;
}
.arrow-link:hover:before {
    transform: translateX(2px);
}