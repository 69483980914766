@media screen and (max-width: 767px) {
    .nav-open .navbar-toggler .icon:before {
        content:var(--icon-close-thin);
    }
    .navbar-cta {
        padding:0 .5rem;
        position: relative;
        top:10px;
        white-space: nowrap;
    }
    .navbar-cta__item--login {
        min-width: 40px;
    }
    .navbar-cta__item--tripplaner {
        min-width: 50px;
    }
    header.is-affix .navbar-cta {
        transform: translateY(12px);
    }
    .navbar-cta__list {
        font-size: 0;
    }
    .navbar-cta__list.navbar-cta__list > li {
        position: relative;
        margin:0;
    }
    .navbar-cta a {
        display: inline-block;
        padding:.75rem .75rem;
        line-height:1px;
        color:#fff;
        position: relative;
    }
    .navbar-cta a.navbar-toggler {
        width:55px;
        min-height: 46px;
        text-align:center;
    }
    .navbar-cta a.navbar-cta__item>.icon {
        position: relative;
        font-size:1.25rem;
        line-height:1;
        min-height: 1em;
        min-width: 1em;
    }
    .navbar-cta a.navbar-cta__item>.icon-person {
        font-size:.865rem;
        top:-8px;
    }
    .navbar-cta a.navbar-cta__item>.icon-heart {
        min-width: calc(24em / 20);
        line-height: 0;
    }
    .navbar-cta__count {
        position: absolute;
        right:0;
        top:4px;
        display: inline-block;
        width:18px;
        height:18px;
        line-height:18px;
        text-align: center;
        font-size:10px;
        font-family: var(--font-default);
        color:#fff;
        background:var(--color-highlight);
        border-radius: 50%;
    }
    .navbar-cta__count:empty {
        display:none;
    }
    .navbar-cta__login-label {
        position: absolute;
        right:0;
        left:0;
        top:50%;
        display: inline-block;
        text-align: center;
        font-size:12px;
        line-height:1;
        font-family: var(--font-special);
        color:#fff;
    }
    .navbar-cta__login-label.navbar-cta__login-label--logout {display: none}
    .logged-in .navbar-cta__login-label.navbar-cta__login-label--login {display: none}
    .logged-in .navbar-cta__login-label.navbar-cta__login-label--logout {display: inline-block}


    /* booking */
    .navbar-booking-images {
        position: relative;
        top:16px;
    }
    .is-affix .navbar-booking-images {
        top:34px;
    }
    .navbar-booking-images__img-trust {
        display: none;
    }
    .navbar-booking-images__img-booking {
        position: relative;
        display: inline-block;
        width:auto;
        height:20px;
    }

    .nav-additional {
        padding:1rem 1rem 2rem 2rem;
    }

    /* weather */
    .nav-weather {
        color:#fff;
        line-height:1;
    }
    .nav-weather a {
        color:#fff;
        text-decoration: none;
    }
    .nav-weather__temp {
        white-space: nowrap;
        font-size:2.5rem;
    }
    .nav-weather__town {
        font-size:1.5rem;
    }

    /* nav */
    .main-nav {
        position: absolute;
        left:0;
        right:0;
        bottom:auto;
        padding-bottom:50px;
        top:calc(100% + 1px);
        background: var(--color-black-85);
        overflow-y: scroll;
        transform: translateX(-110%);
        height: 400px;
        height: calc(90vh);
        height: calc(100vh - 70px);
    }
    body.nav-open .main-nav {
        transform: translateX(0);
    }
    .navbar-main>li>.nav-link.nav-link {
        display: block;
        font-size:1.25rem;
        padding:.75rem 2rem;
    }

    /* dropdown */
    .dd-heading a {
        /*display: none;*/
        display: block;
        padding: .75rem 0;
    }
    .dd-heading a:hover,
    .dd-heading a:focus {
        color: #F4D100;
        text-decoration: none;
    }
    .dropdown-menu {
        padding: 0 2rem;
    }
    .dd-sub-list {
        display:none;
        padding-left:1rem;
    }
    li.show-level ul {
        display: block;
    }
    .dd-list a, .dd-sub-list a {
        padding: .75rem 0;
    }


    /* lang */
    .xs-lang-select {
        position: relative;
        overflow-x: hidden;
    }
    .xs-lang-select__select {
        display: inline-block;
        height:30px;
        width: 120px;
        color:#fff !important;
        background:transparent !important;
        border:0 !important;
        box-shadow: none !important;
        outline:0 !important;
        padding:0 .75rem 0 0 !important;
        font-size:1.25rem;
        line-height:1;
        font-family: var(--font-special);
        text-align:center;
        margin-left:15px;
    }
    .xs-lang-select:after {
        content:'';
        position: absolute;
        right:8px;
        top:7px;
        margin:0;
        width:8px;
        height:8px;
        border:1px solid #fff;
        border-width:1px 1px 0 0 ;
        transform: rotate(135deg);
    }
}

@media screen and (max-width: 767px) and (max-height: 600px) {
    .nav-additional {
        padding-bottom:0;
    }
    .header-search-content-xs {
        margin-top: .5rem;
    }
}