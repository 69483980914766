.header-img-container {
    position: relative;
    overflow: hidden;
}
.header-img-container--fixed {
    position: fixed;
    left:0;
    top:0;
    right:0;
    bottom:0;
}
.header-img-container .slick-arrow {
    display: none !important;
}
.header-img {
    position: relative;
    height:calc(100vh - 60px);
    background-size:cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;

    @media screen and (min-width: 768px) {
        height:100vh;
    }

    /* iphone X  100% height without browserbar */
    @media only screen
    and (device-width : 375px)
    and (device-height : 812px)
    and (-webkit-device-pixel-ratio : 3) {
        height:88vh;
    }
}
.header-img.header-img--small {
    height:55vh;
}
.header-img__img-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.header-img__img-wrapper img {
    object-fit: cover;
    height: 100%;
    width: 100%;
}
@media all and (-ms-high-contrast:none) {
    /* IE11 hack */
    *::-ms-backdrop, .header-img__img-wrapper img {
        position: absolute;
        top:0;
        left: 50%;
        transform: translateX(-50%);
        width: auto;
    }
}



.fullscreen-video {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

}

.fullscreen-video__video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media screen and (min-width: 768px) {
        width: auto;
        height: auto;
        min-width: 100%;
        min-height: 100%;
    }

    @media screen and (max-width: 767px) {
        width:auto;
        min-width:100%;
        height:100vh;
    }

}




.header-img__main-overlay {
    position: absolute;
    left:40px;
    right:40px;
    top:35%;
    transform: translateY(-50%);
    text-align:center;

    @media screen and (min-width: 768px) {
        right:auto;
        left:50%;
        width:800px;
        margin-left:-400px;
    }
    @media screen and (min-width: 1200px) {
        width:1100px;
        margin-left:-550px;
        //top: 50%;
        transform: translateY(10%);
    }
    @media screen and (min-width: 1340px) {
        //top: 60%;
    }
}

.header-img__main-overlay.header-img__main-overlay--bottom {
    top:auto;
    bottom:6rem;
    transform: translateY(0);
    @media screen and (min-width: 768px) {
        bottom:10rem;
    }
}

.header-img__main-overlay.header-img__main-overlay--top {
    top:6rem;
    transform: translateY(0);
    @media screen and (min-width: 768px) {
        top:10rem;
    }
}




@keyframes fadeIn {
    0% {
        transform: translateY(-30%);
        opacity: 0;
    }
    100% {
        transform: translateY(-50%);
        opacity: 1;
    }
}
.header-img__hl {
    color:inherit;
    font-size:40px;
    font-family: var(--font-special);
    line-height:1;
    margin: 0 0 1.5rem 0;
    text-shadow: 0 2px 4px rgba(0,0,0,0.5);
    @media screen and (min-width: 768px) {
        font-size:75px;
        margin: 0 0 2rem 0;
    }
    @media screen and (min-width: 1440px) {
        font-size:100px;

    }
}
html:not(.has-preloaded-fonts) .header-img__hl {
    font-size: 36px;
    letter-spacing: 0.04em;
    line-height: 1.1;

    @media screen and (min-width: 768px) {
        font-size: 70px;
        line-height: 1.07;
        letter-spacing: calc(0.25em / 70);
    }
    @media screen and (min-width: 1440px) {
        font-size: 92px;
        line-height: 1.09;
        letter-spacing: calc(0.6em / 92);
    }

}
.header-img__txt {
    font-size:1.25rem;
    margin: 0 0 1.5rem 0;
    font-family: var(--font-special);
    text-shadow: 0 2px 4px rgba(0,0,0,0.5);
    @media screen and (min-width: 768px) {
        font-size:2rem;
        line-height:1.1;
        margin: 0 0 3rem 0;
    }
}
html:not(.has-preloaded-fonts) .header-img__txt {
    font-size: 17px;
    line-height: 1.65;
    letter-spacing: 0.04411em;

    @media screen and (min-width: 768px) {
        font-size:2rem;
        line-height:1.1;
        letter-spacing: -0.03em;
    }
}
.header-img-container .slider,
.slider.slider--vh-100 {
    height:calc(100vh - 60px);

    @media screen and (min-width: 768px) {
        height: 100vh;
    }
}

.header-img__scroll-hint {
    position: absolute;
    left:50%;
    bottom:1.75rem;
    margin-left:-22px;
    display: block;
    width:44px;
    height:64px;
    padding-top: 20px;
    text-align: center;
    color:#fff !important;
    font-size:1.25rem;
    letter-spacing: 0;
    font-family: sans-serif;
    line-height: 1;

    @media screen and (min-width: 768px) {
        bottom:30px;
        font-size:1.75rem;
    }
}

.header-img__scroll-hint--qf-opened {
    bottom:1.75rem;
    transition: all 250ms ease;
    @media screen and (min-width: 768px) {
        bottom:55px;
    }
}


@-moz-keyframes sroller {
    0%, 20%, 50%, 80%, 100% {
        -moz-transform: translateY(0);
        transform: translateY(0);
    }
    40% {
        -moz-transform: translateY(-20px);
        transform: translateY(-20px);
    }
    60% {
        -moz-transform: translateY(-10px);
        transform: translateY(-10px);
    }
}
@-webkit-keyframes sroller {
    0%, 20%, 50%, 80%, 100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    40% {
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px);
    }
    60% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
    }
}
@keyframes sroller {
    0%, 20%, 50%, 80%, 100% {
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    40% {
        -moz-transform: translateY(-20px);
        -ms-transform: translateY(-20px);
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px);
    }
    60% {
        -moz-transform: translateY(-10px);
        -ms-transform: translateY(-10px);
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
    }
}
.sroller__animation {
    display: inline-block;
    animation: sroller 2s infinite;
}