.link-list {
    margin-top: 35px;
    margin-bottom: 20px;
}
.link-list__icon {
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    background: #9d9d9c;
    color: #ffffff;
    vertical-align: middle;
    line-height: 47px;
    margin-right: 15px;
}
.link-list__icon:before {

}
.link-list__list {
    display: inline-block;
}
.link-list__list a {
    text-decoration: underline;
}