.svg-map__active-layer,
.svg-map__active-sub-layer {
    opacity: 0;
    transition: opacity 110ms ease;
    cursor: pointer;
}
.svg-map__active-layer {
    fill: var(--color-secondary);
}
.svg-map__active-layer.is-hovered,
.svg-map__active-layer:hover {
    opacity: 1;
    fill: var(--color-secondary);
}

.acco-svg-map__region {
    fill: #F0F1F1;
    stroke: #FFFFFF;
    stroke-width: 0.5;
    stroke-linejoin: round;
}
.acco-svg-map__region-active {
    fill: var(--color-secondary);
}