@media screen and (min-width: 768px) {
    /* cta links */
    .navbar-cta__list > li {
        position: relative;
        margin:0 .25rem !important;
    }
    .navbar-cta {
        position: relative;
    }
    .navbar-cta a.navbar-cta__item {
        color:#fff;
        height:40px;
        padding:0 .75rem;
        display: inline-block;
        text-align: center;
        line-height: 40px;
        margin-top:30px;
    }
    .navbar-cta__item--login {
        min-width: 47px;
    }
    .navbar-cta__item--tripplaner {
        min-width: 54px;
    }
    .navbar-cta a.navbar-cta__item:hover,
    .navbar-cta a.navbar-cta__item:focus {
        color: #F4D100;
    }
    .navbar-cta a.navbar-cta__item>.icon {
        position: relative;
        top:.25rem;
        font-size:1.5rem;
        line-height:1;
        display: inline-block;
        min-height: 1em;
        min-width: 1em;
    }
    .navbar-cta a.navbar-cta__item>.icon-person {
        font-size:1.25rem;
        top:-6px;
        display: inline-block;
    }
    .navbar-cta a.navbar-cta__item > .icon-heart {
        min-width: calc(29em / 24);
    }
    .navbar-cta__count {
        position: absolute;
        right:0;
        top:30%;
        display: inline-block;
        width:18px;
        height:18px;
        line-height:18px;
        text-align: center;
        font-size:10px;
        font-family: var(--font-default);
        color:#fff;
        background:var(--color-highlight);
        border-radius: 50%;
    }
    .navbar-cta__count:empty {
        display:none;
    }
    .navbar-cta__login-label {
        position: absolute;
        right:0;
        left:0;
        bottom:6px;
        display: inline-block;
        text-align: center;
        font-size:12px;
        line-height:1;
        font-family: var(--font-special);
        color:inherit;
    }
    .navbar-cta__login-label.navbar-cta__login-label--logout {display: none}
    .logged-in .navbar-cta__login-label.navbar-cta__login-label--login {display: none}
    .logged-in .navbar-cta__login-label.navbar-cta__login-label--logout {display: inline-block}


    /* booking */
    .navbar-booking-images {
        position: relative;
        top:32px;
    }
    .navbar-booking-images__img-booking,
    .navbar-booking-images__img-trust {
        position: relative;
        display: inline-block;
        width:auto;
        height:24px;
    }
    .navbar-booking-images__img-trust {
        margin-left:2.75rem;
        top:-.25rem;
        height:32px;
    }

    /* nav */
    .nav-container {
        position: relative;
        width: 100%;
        padding-right:175px;
    }
    .navbar-main>li>.nav-link.nav-link {
        line-height:100px;
        padding:0 .25rem;
        font-size:1rem;
    }
    /*.navbar-nav .nav-item:first-child .nav-link {
        padding-left:0;
    }*/



    /* dropdown */
    .dd-overlay {
        display: none;
    }
    .nav-open .dd-overlay {
        display: block;
        position: fixed;
        top:100px;
        left:0;
        right:0;
        bottom:0;
        background-color: rgba(0,0,0,.85);
        z-index:850;
    }
    .dd-overlay .close-nav {
        color:#fff;
        font-size:22px;
        position: absolute;
        right:1rem;
        top:1rem;
    }
    .dd-overlay .close-nav:hover,
    .dd-overlay .close-nav:focus {
        color: #F4D100;
    }
    .dropdown-menu {
        position: absolute;
        top: 100%;
        left: 0;
        /*right:0;*/
        min-width:1000px;
        padding:2rem 0;
    }
    .dropdown-menu--extended {
        min-width:680px;
    }
    header .dropdown-menu,
    header .dropdown-menu .container {
        pointer-events: none;
    }
    header .dropdown-menu .container a,
    header .dropdown-menu .container button {
        pointer-events: initial;
    }
    .dd-heading {
        margin-bottom:1rem;
        font-size: 1.25rem;
    }
    .dd-heading a {
        color:inherit;
    }
    .dd-heading a:hover,
    .dd-heading a:focus {
        color: #F4D100;
        text-decoration: none;
    }
    .dd-container {
        position: relative;
    }
    .dd-list {
        width:235px;
    }
    .dd-list > li {
        position: relative;
    }
    .dd-list > li.show-level {
        position: static;
    }
    .dd-sub-list {
        position: absolute;
        left:235px;
        top:0;
        width:235px;
        display:none;
    }
    .dd-sub-list a {
        padding:.375rem .25rem;
    }
    li.show-level ul {
        display: block;
    }


    /* seasons collapse */
    .dd-seasons {
        margin-left:2rem;
    }
    .dd-seasons .nav-tabs {
        width:100%;
        height:auto;
        border:0;
    }
    .dd-seasons .nav-tabs li {
        width:46%;
        display: inline-block;
    }
    .dd-seasons .nav-tabs li + li {
        margin-left:4%;
    }
    .dd-seasons .nav-tabs li>a.nav-link {
        color:#fff;
        background: transparent !important;
        padding:0;
        margin:0;
        border:1px solid;
        border-width:0 0 1px 0;
    }
    .dd-seasons .nav-tabs .nav-item.show .nav-link, .dd-seasons .nav-tabs .nav-link.active {
        color: #F4D100;
    }
    .dd-seasons__list {
        column-count: 2;
        column-gap: 30px;
    }
    .dd-seasons__list li{
        display: block;
    }
    .dd-seasons__list li > a{
        display: block;
        padding:.5rem 0;
    }
    .dd-seasons__list li > a:hover,
    .dd-seasons__list li > a:focus{
        color: #F4D100;
        text-decoration: none;
    }
    .dd-seasons__toggler {
        display: block;
        color:#fff;
        margin-bottom:1rem;
        font-size: 1.25rem;
        border-bottom:1px solid;
        text-decoration: none !important;
    }
    .dd-seasons__toggler:after {
        display: none;
    }
    .dd-seasons__toggler:hover,
    .dd-seasons__toggler:focus {
        color: #F4D100;
    }
    .dd-seasons__heading {
        margin:.5rem 0 1rem 0;
        font-size: 1.25rem;
    }
    .dd-seasons__heading a:hover,
    .dd-seasons__heading a:focus {
        color: #F4D100;
        text-decoration: none;
    }
    .dd-seasons__img {
        margin:1rem 0;
    }

}


@media screen and (max-height: 900px) {
    .dd-seasons__img {display:none;}
}

@media screen and (min-width: 768px) and (max-height: 800px) {
    .dd-sub-list a {
        font-size: 15px;
        padding:.25rem;
    }
}

.dd-map {
    display: none;
}

html:not(.has-preloaded-fonts) .navbar-main>li>.nav-link.nav-link {
    letter-spacing: -.02em;
}

@media screen and (min-width: 1100px) {
    .navbar-main>li>.nav-link.nav-link {
        padding:0 .5rem;
        font-size:1.125rem;
    }
    html:not(.has-preloaded-fonts) .navbar-main>li>.nav-link.nav-link {
        font-size: 1rem;
        letter-spacing: .025em;
    }
}
@media screen and (min-width: 1280px) {
    .nav-container {
        padding-right:250px;
    }
    .navbar-main>li>.nav-link.nav-link {
        padding:0 1rem;
        font-size:1.25rem;
    }
    html:not(.has-preloaded-fonts) .navbar-main>li>.nav-link.nav-link {
        font-size: 1.1rem;
        letter-spacing: .041em;
    }
    .dd-map {
        display: block;
    }
}



@media screen and (max-width: 1679px) {
    .navbar-nav .nav-item.dropdown--extended{
        position: static;
    }
}