.custom-select {
    padding: .75rem 2.8rem .75rem .75rem;
    min-width: 115px;
}
.custom-select__wrapper {
    display: flex;
    position: relative;
    flex-direction: column;
}

.custom-select__select {
    position: relative;
}

.custom-select__select:after {
    content: var(--icon-arrow-down);
    font-family: iconfont;
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    font-size: .5rem;
}
.text-dark .custom-select__select:after {
    color:var(--color-text-default);
    background-color: #fff;
    padding-right: 1rem;
    right: .125rem;
}
.custom-select__select:after {
    pointer-events: none;
}

.custom-select__label {
    display: inline-block;
    margin-bottom: .25rem;
}