/*embed responsives*/
.embed-responsive-footer:before {
    padding-top: 16.09375%;
    @media screen and (max-width: 767px) {
        margin: 0 0 0 -70%;
        width: 250%;
    }
}
.embed-responsive.embed-responsive-3by2:before {
    padding-top: 66.666666667%;
}
.embed-responsive.embed-responsive-teaser:before {
    padding-top: 66.666666667%;
    @media screen and (max-width: 767px) {
        padding-top: 140%;
    }
}
.embed-responsive.embed-responsive-acco-result-map:before {
    padding-top: 50%;
    @media screen and (max-width: 767px) {
        padding-top: 125%;
    }
}
.teaser--big .embed-responsive.embed-responsive-teaser:before {
    padding-top: 67%;
    @media screen and (max-width: 767px) {
        padding-top: 75%;
    }
}
.teaser--big-grid .embed-responsive.embed-responsive-teaser:before {
    padding-top: 62%;
    @media screen and (max-width: 767px) {
        padding-top: 75%;
    }
}
.teaser--regular-two-col .embed-responsive.embed-responsive-teaser:before,
.teaser--high .embed-responsive.embed-responsive-teaser:before {
    padding-top: 125.5%;
    @media screen and (max-width: 767px) {
        padding-top: 75%;
    }
}
.embed-responsive.embed-responsive-pisten:before {
    padding-top: 32.25806451612903%;
}
.embed-responsive.embed-responsive-2by1:before {
    padding-top: calc(1/2*100%);
}
.embed-responsive.embed-responsive-1by3:before {
    padding-top: calc(1/3*100%);
}
.embed-responsive.embed-responsive-1by1:before {
    padding-top: 100%;
}
.embed-responsive.embed-responsive-40:before {
    padding-top: 40%;
}
.embed-responsive.embed-responsive-anlagen:before {
    padding-top: 28%;
    @media screen and (max-width: 767px) {
        padding-top: 66%;
    }
}
.embed-responsive.embed-responsive-map:before {
    padding-top: 50%;
    @media screen and (max-width: 767px) {
        padding-top: 100%;
    }
}

.embed-responsive.embed-responsive-map-lg:before {
    padding-top: 100%;
    @media screen and (min-width: 768px) {
        padding-top: 80%;
    }
    @media screen and (min-width: 1400px) {
        padding-top: 75%;
    }
    @media screen and (min-width: 1600px) {
        padding-top: 65%;
    }
}

.embed-responsive.embed-responsive-acco-map:before {
    padding-top: 100%;
    @media screen and (min-width: 768px) {
        padding-top: 40%;
    }
    @media screen and (min-width: 1400px) {
        padding-top: 35%;
    }
    @media screen and (min-width: 1600px) {
        padding-top: 25%;
    }
}


.embed-responsive.embed-responsive-6by5:before {
    padding-top: calc(5/6*100%);
}


@media screen and (max-width: 767px) {
    .embed-responsive.embed-responsive-1by1-on-xs:before {
        padding-top: calc(100%);
    }
}


.embed-responsive.embed-responsive-svgmap:before {
    padding-top:61.02176383183288%;
}
