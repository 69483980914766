/*.color-box--spring,
.color-box--autumn,
.color-box--darkgrey,
.color-box--summer,
.color-box--winter,
.color-box--christkindl,
.color-box--christkindl-dark {
    background: #E2E7E9;
}*/
.color-box--darkgrey {
    background: #E2E7E9;
}
.color-box--winter {
    background: #eef5fb;
}
.color-box--spring {
    background: var(--color-primary-bg);
}
.color-box--white {
    background: #fff;
}
.color-box--summer {
    background: #fdf6cc;
}
.color-box--christkindl {
    background: #f0e9eb;
}
.color-box--christkindl-dark {
    background: rgba(220, 189, 199, 0.6);
}
.color-box--highlight {
    color:#fff;
    background: var(--color-highlight);
}


.color-box__hl {
    color: var(--color-primary);
}

.color-box--spring .color-box__hl{
    color: var(--color-spring);
}
.color6f{
    color: #6f6f6f;
}
.orange{
    background-color: #fff;
}
.color-box--summer .color-box__hl{
    color: var(--color-summer);
}
.color-box--autumn .color-box__hl{
    color: var(--color-autumn);
}
.color-box--winter .color-box__hl{
    color: var(--color-winter);
}