.custom-checkbox.custom-checkbox {
    position: relative;
    margin-bottom: 0;
    min-height: 20px;
    display: inline-block;
    vertical-align: -0.3em;
}
.custom-checkbox.custom-checkbox--subcategory {
    display: block;
    padding:0 0 .5rem 1.875rem;
}
.custom-checkbox-subcategory__toggle {
    position: relative;
    padding:.5rem;
    display: inline-block;
}
.custom-checkbox-subcategory__toggle:after {
    display: block;
    content: "";
    position: relative;
    top:1px;
    margin-left:.5rem;
    width: 10px;
    height: 10px;
    border: 1px solid #262626;
    border-width: 1px 1px 0 0;
    transform: rotate(-45deg);
    transition: transform .25s ease;
    @media screen and (max-width: 767px)  {
        border-color: #fff;
        top:5px;
    }
}

.custom-checkbox-subcategory__toggle.collapsed:after {
    transform: rotate(135deg);
    top:-1px;
}


.custom-checkbox label {
    margin-bottom: 0;
    display: flex  ;
    align-items:center;
    @media screen and (max-width: 767px) {
        align-items: flex-start;
        text-align: left;
    }
}

.custom-checkbox__input,
.custom-checkbox--with-bg__input {
    position: absolute;
    width: calc(1rem / 16);
    height: calc(1rem / 16);
    margin: calc(-1rem / 16);
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.custom-checkbox__box,
.custom-checkbox--with-bg__box {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.1rem;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    border: 1px solid var(--color-default);
    border-radius: 5px;
    color: var(--color-secondary);
    background:#fff;

    @media screen and (max-width: 767px) {
        display: inline-flex;
    }
}

@media screen and (max-width: 767px) {
    .custom-checkbox__text {
        position: relative;
        top:-4px;
    }
    .custom-checkbox__text--align-center {
        top: auto;
    }
}

.custom-checkbox__text a {
    text-decoration: underline;
}

.custom-checkbox__box:before,
.custom-checkbox--with-bg__box:before {
    visibility: hidden;
}

.custom-checkbox label:hover .custom-checkbox__box,
.custom-checkbox label:focus .custom-checkbox__box,
.custom-checkbox__input:checked ~ .custom-checkbox__box {
    border-color: var(--color-text-muted);
}

.custom-checkbox__input:checked ~ .custom-checkbox__box:before {
    visibility: visible;
    transform: translate(3px, -3px);
}

.custom-checkbox .has-error {
    margin-bottom: .2rem;
    padding: .2rem;
}

.has-error .custom-checkbox__box {
    border: 1px solid var(--color-danger);
}

.custom-checkbox__fake-checkbox {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 1px solid #bccdda;
    background: #fff;
    border-radius: 6px;
}

.has-error .custom-checkbox__fake-checkbox {
    border-color: #ab1537;
}

.custom-checkbox__fake-checkbox__icon {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 10px;
    visibility: hidden;
    transform: translateY(-50%);
}

.custom-checkbox__input:checked + .custom-checkbox__fake-checkbox > .custom-checkbox__fake-checkbox__icon {
    visibility: visible;
}

.custom-checkbox--full-width {
    width: 100%;
}

/*.custom-checkbox-visible-on-checked {*/
/*override all other display styles*/
/*display: none !important;*/
/*}*/
/*.custom-checkbox__input:checked + .custom-checkbox + .custom-checkbox-visible-on-checked {*/
/*override all other display styles*/
/*display: block !important;*/
/*}*/

/*small checkbox*/
.custom-checkbox.custom-checkbox--small {
    padding-left: 25px;
}

.custom-checkbox--small .custom-checkbox__fake-checkbox {
    width: 15px;
    height: 15px;
    border-radius: 5px;
}

.custom-checkbox--small .custom-checkbox__fake-checkbox__icon {
    font-size: 7px;
}


/*checkbox with bg*/
.custom-checkbox.custom-checkbox--with-bg {
    display: block;
    padding: 8px 11px;
    color: var(--color-dark);
    background-color: var(--color-light-grey);
    font-family: var(--font-special);
}
.custom-checkbox--with-bg__box {
    display: inline-flex;
    font-size: 1rem;
    color: var(--color-green);
}
.custom-checkbox--with-bg__label {
    display: block;
    margin-bottom: 0;
}
.custom-checkbox--with-bg__input:checked~ .custom-checkbox--with-bg .custom-checkbox--with-bg__box:before {
    visibility: visible;
    transform: translate(3px, -3px);
}
.custom-checkbox--with-bg__input:checked~ .custom-checkbox--with-bg {
    background-color: rgba(169, 191, 0, .6);
}


.custom-checkbox__input ~ .custom-checkbox__btn {
    color:#fff;
    background-image: url(static/img/relaunch2018/buttons/primary-left-filled.svg);
}
.custom-checkbox__input ~ .custom-checkbox__btn:after {
    background-image: url(static/img/relaunch2018/buttons/primary-right-filled.svg);
}
.custom-checkbox__input:checked ~ .custom-checkbox__btn .custom-checkbox__btn__active {
    display: block;
}
.custom-checkbox__btn__active,
.custom-checkbox__input:checked ~ .custom-checkbox__btn .custom-checkbox__btn__inactive {
    display: none;
}

.custom-checkbox__input ~ .custom-checkbox__btn .custom-checkbox__btn__inactive {
    color: white;
}