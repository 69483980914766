.teaser {
    position: relative;
    cursor: pointer;
}
.teaser:not(.teaser--regular) {
    border: .25rem solid transparent;
}

@media screen and (min-width: 768px) {
    .teaser-matrix .teaser--regular {
        height:100%;
        border: .25rem solid transparent;
    }
}

.slider:not(.slider--with-padding) .teaser {
    padding:0 .25rem;
}
.teaser:not(.teaser--regular):after {
    content:'';
    position: absolute;
    left:0;
    right:0;
    top:50%;
    bottom:0;
    z-index:5;
    pointer-events: none;
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.99) 99%,rgba(0,0,0,1) 100%);
    opacity: .75;
}
.teaser.teaser--high:after {
    top:0;
    bottom:50%;
    transform: scaleY(-1);
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.99) 99%,rgba(0,0,0,1) 100%);
}
.teaser-wide,
.teaser--regular {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
}
.teaser .embed-responsive {
    background:var(--color-light-grey);
}
.teaser__title {
    color:inherit;
    font-size:1rem;
    line-height:1;
    margin:0;
    font-family:var(--font-special);
    @media screen and (min-width: 768px) {
        font-size:1.5rem;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
    .teaser-matrix .teaser--regular p {
        display: none;
    }
}

.teaser--acco .teaser__title {
    font-family:var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
}

html:not(.has-preloaded-fonts) .teaser--acco .teaser__title {
    @media screen and (min-width: 768px) {
        font-size: 1.375rem ;
    }
    line-height: 1.1;
    letter-spacing: -0.0022727em;
}
.teaser--regular-two-col .teaser__title,
.teaser--big .teaser__title {
    font-size:1.5rem;
    @media screen and (min-width: 768px) {
        font-size:2rem;
    }
    @media screen and (min-width: 1200px) {
        font-size:2.75rem;
    }
}
.teaser--high .teaser__title {
    @media screen and (max-width: 767px) {
        font-size:1.5rem;
    }
}
.teaser--regular .teaser__title {
    @media screen and (max-width: 767px) {
        font-size:1.375rem;
    }
}
.teaser__title > a {
    color:inherit;
    text-decoration: none;
}
.teaser__meta {
    margin-bottom: .5rem;
    color:var(--color-text-default);
}

.teaser__keyfacts {
    text-align: center;
    font-size:.875rem;
    border-top:1px solid var(--color-dark-grey);
    padding-top:1.25rem;
    margin-top:1rem;
}
.teaser__keyfacts .icon {
    font-size:1.25rem;
    @media screen and (min-width: 768px) {
        font-size:1.875rem;
    }
}
.teaser__keyfacts .teaser__keyfacts-lbl {
    color:var(--color-text-muted);
}


.teaser__opening {
    text-align: left;
    padding-top:.875rem;
    margin-top:.875rem;
    font-size:.9375rem;
    color:var(--color-text-muted);
    border-top:1px solid var(--color-dark-grey);

    @media screen and (min-width: 768px) {
        font-size:1rem;
    }
}
.teaser__opening .icon {
    position: relative;
    font-size:1.375rem;
    margin-right:.5rem;
    top: .3125rem;
}
.teaser__opening--open .icon {
    color:#A9BF00;
}
.teaser__opening--closed .icon{
    color:var(--color-highlight);
}



.teaser--big .trip-planer-add {
    font-size:1.625rem;
    @media screen and (min-width: 768px) {
        font-size:2rem;
    }
}
.teaser__legend-badge {
    position: absolute;
    top:0;
    left:0;
    z-index:10;
    width:100%;
    height:100px;
    overflow: hidden;
    pointer-events: none;
}

.teaser__legend-badge-inner {
    display: block;
    position: absolute;
    top: -10px;
    left: -45px;
    width: 130px;
    height: 55px;
    line-height: 55px;
    text-align: center;
    transform: rotate(-45deg);
    transform-origin: 50% 50%;
    box-shadow: 2px 6px 10px 1px rgba(0,0,0,0.2);
    pointer-events: initial;
}

.teaser__legend-badge-text {
    position: relative;
    top:15%;
    padding: 5px;
    font-family: var(--font-special);
    color:#fff;
    font-size: 0.875rem;
    text-shadow: 2px 1px 3px var(--color-dark);
}

.teaser__legend-badge-inner > .icon {
    padding:5px;
    position: relative;
    top:50%;
    color:#fff;
    transform: translateY(-80%) rotate(45deg);
    font-size:1.125rem;
    display: inline-block;
    text-shadow: 2px 1px 3px var(--color-dark);
}
.teaser__legend-badge-inner > img {
    position: relative;
    top:50%;
    transform: translateY(-80%) translateY(-4px) rotate(45deg);
    display: inline-block;
    width:1.5rem;
}
.teaser--big .teaser__legend-badge-inner > img {
    width:1.75rem;
    transform: translateY(-80%) rotate(45deg);
}

.teaser__meeting-location-details {
    text-align: center;
    margin-top:.75rem;
}

.teaser__meeting-location-details .icon {
    font-size:1.5rem;
    display: block;
    margin-bottom:.625rem;
}
.teaser__meeting-location-details .icon-bed-2 {
    font-size:1.125rem;
    margin-top:.375rem;
}

.teaser__cta-body,
.teaser__body {
    color:#fff;
    position: absolute;
    right:0;
    left:0;
    bottom:0;
    padding: .875rem;
    z-index:10;
    line-height:1.35;
}
.teaser--high .teaser__body {
    bottom:auto;
    top:0;
}

@media screen and (max-width: 767px) {
    .teaser--high .teaser__cta-body,
    .teaser--big .teaser__body,
    .teaser--high .teaser__body {
        padding: 1.25rem;
    }
}
@media screen and (min-width: 768px) {
    .teaser--big .teaser__body {
        padding: 2rem;
    }
    .teaser--high .teaser__body {
        padding: 1.5rem;
    }
    .teaser--high .teaser__cta-body {
        padding: 1.5rem;
    }
}
.teaser--regular .teaser__body {
    color:var(--color-dark);
    position: relative;
    right:auto;
    left: auto;
    bottom:auto;
    padding:1rem;
    background:#fff;
    @media screen and (min-width: 768px) {
        padding:1.5rem;
        display: flex;
        flex: 1 0 auto;
        flex-direction: column;
    }
}
.teaser__body p {
    margin:.5rem 0 0 0;
    line-height:1.35;

    @media screen and (min-width: 768px) {
        margin:1rem 0 0 0;
    }
}
.teaser__img-badge {
    position: absolute;
    top: 100%;
    padding: 3px 18px;
    transform: translateY(-50%);
    border: 1px solid;
    border-left: none;
    text-transform: uppercase;
    font-size: 13px;
    line-height: calc(22 / 13);
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
    background: #ffffff;
    border-radius: 0 8px 8px 0;
}
.teaser__category-badge {
    display: inline-block;
    position: absolute;
    top: .75rem;
    left:.75rem;
    padding: .5rem .625rem;
    font-size: .875rem;
    line-height: 1;
    background: #ffffff;
    color:var(--color-text-muted);
    border-radius: .25rem;
}
.teaser__category-badge-holder {
    position: absolute;
    top: .75rem;
    left:.75rem;
}
.teaser__category-badge--multiple {
    position: relative;
    top: auto;
    left:auto;
    margin:0 .125rem .25rem 0;
}
.teaser__placeholder-icon {
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    text-align: center;
    z-index: 0;
    font-size: 60px;
    color: #b9b9b9;
    text-shadow: -1px -1px 0 rgba(0,0,0,.4);
}
.teaser__img-container .embed-responsive {
    /*prevents chrome from half pixel border*/
    transform: translateY(0);
    overflow: visible;
    z-index: 1;
}
.teaser__label {
    position: absolute;
    left: -17px;
    top: 19px;
    padding: 8px 35px;
    font-size: 18px;
    background: #f4d100;
    color: #FFFFFF;
    transform: rotate(-6deg);
}
.teaser__tripplaner {
    display: block;
    margin-left:.75rem;
}

.teaser__badge {
    position: absolute;
    left: 0;
    top: .75rem;
    display: inline-block;
    border-radius: 0 6px 6px 0;
    background:rgba(255,255,255,.85);
    padding:.5rem 1.5rem;
    z-index:10;
    color: var(--color-primary);
    font-family: var(--font-special);
    @media screen and (max-width: 767px) {
        padding:.25rem 1.125rem;
    }
}
.teaser__badge--small {
    font-size:15px;
}
.teaser__badge + .teaser__badge {
    top:3.75rem;
    @media screen and (max-width: 767px) {
        top:3rem;
    }
}
.teaser__badge--with-checkbox {
    padding-left:1rem;
    color:var(--color-primary);
    @media screen and (max-width: 767px) {
        padding:.25rem 1.125rem .05rem 1.125rem;
    }
}
.teaser__badge--booking {
    color:var(--color-green);
}
/*.teaser__badge--with-checkbox .custom-checkbox {
    position: relative;
    top:.25rem;
}*/
/*.teaser__badge--outline {
    color: var(--color-primary);
    border:1px solid var(--color-primary);
    border-left:0;
}
.teaser__badge--full {
    color:#fff;
    background:var(--color-primary);
}*/

/*teaser--hasMap*/
.teaser__map {
    position: absolute;
    bottom: -10px;
    right: 0;
    z-index: 1;
}
.teaser--hasMap .teaser__img-container {
    overflow: visible;
}

.list-skiicons {
    padding-left: 0;
    margin-left: 0;
    list-style-type: none;
}
.list-skiicons > li {
    position: relative;
    padding-left:30px;
}
.list-skiicons > li .icon {
    position: absolute;
    left:0;
    top:50%;
    transform: translateY(-50%);
}

/* acco teaser */
.teaser__acco-category {
    font-family: sans-serif; /*prevent CLS jump*/
    line-height: 0;
    font-size:1.125rem;
    letter-spacing:-2px;

    @media screen and (min-width: 768px) {
        font-size:1.5rem;
        letter-spacing:-1px;
    }

    @media screen and (min-width: 1400px) {
        white-space: nowrap;
    }
}
.teaser__acco-category-img {
    display: inline-block !important;
    height:auto;
    width:20px;
    position: relative;
    top:-.25rem;
    @media screen and (min-width: 768px) {
        width:31px;
        margin-right: -2px;
    }
}

.teaser__acco-category--small {
    font-size:1rem;

    @media screen and (min-width: 768px) {
        font-size:1.125rem;
    }
}
.teaser__acco-category--small .teaser__acco-category-img {
    width:16px;
    @media screen and (min-width: 768px) {
        width:25px;
    }
}
.teaser__acco-category-superior {
    position: relative;
    top:-.5rem;
    font-size:1.25rem;
}
.teaser__acco-trust {
    min-height: 2.4em;

    @media screen and (min-width: 768px) {
        font-size:1.125rem;
        line-height:1;
        min-height: calc(30 / 18 * 1em);
    }
}
.teaser__acco-town {
    @media screen and (min-width: 768px) {
        font-size:1.125rem;
    }
}
html:not(.has-preloaded-fonts) .teaser__acco-town {
    @media screen and (min-width: 768px) {
        font-size: 1.0547rem;
    }
    line-height: 1.4;
}
.teaser .hover-bg {
    position: relative;
}
.teaser .hover-bg:after {
    pointer-events: none;
    content:'';
    display:block;
    position: absolute;
    left:0;
    top:0;
    right:0;
    bottom:0;
    background: rgba(0,0,0,0);
    transition: all 250ms linear;
}
.teaser:hover .hover-bg:after {
    background: rgba(0,0,0,.15);
}
/* teasermatrix*/
@media screen and (max-width: 767px) {
    .teaser-matrix {
        position: relative;
        margin:0 -1.25rem;
    }
    .teaser-matrix .teaser-matrix__default-teaser {
        margin:.75rem 1.5rem;
    }
}
.slide__heading + .teaser-slider,
.slide__heading + .teaser-matrix {
    margin-top:2rem;
    @media screen and (min-width: 768px) {
        margin-top:3rem;
    }
}
/*.teaser-matrix__row + .teaser-matrix__row {
    margin-top:.25rem;
} */
.teaser-slider-more,
.teaser-matrix-more {
    margin-top:3rem;
}
.teaser-matrix__text {
    padding:0 1.5rem;

    @media screen and (min-width: 768px) {
        padding:0 3rem 0 .5rem;
    }
}


.press-teaser {
    padding:1rem;
    background:#fff;
    /*border:1px solid var(--color-text-muted);*/
    background:var(--color-dark-grey);
}
.press-teaser:hover {
    background:#fff;
}
.press-teaser a:hover {
    color:inherit;
    text-decoration: none;
}


/*teaser-wide*/
.teaser-wide {
    position: relative;
    background:#fff;

    @media screen and (max-width: 767px) {
        margin:0 -1rem;
    }
}
.teaser-wide.teaser-wide--x-margin-0 {
    margin:0;
}
.teaser-wide__title {
    font-size:.875rem;
    line-height:1;
    margin:0 0 .5rem 0;
    font-family:var(--font-special);
    color:var(--color-dark);
    @media screen and (min-width: 768px) {
        font-size:1.75rem;
        margin:.25rem 0 1rem 0;
    }
}
.teaser-wide__title a {
    position: relative;
}
.teaser-wide__title--small {
    @media screen and (min-width: 768px) {
        font-size:1.375rem;
    }
}
.teaser-wide__body {
    font-size:.875rem;
    padding:.75rem;
    color: var(--color-dark);
    @media screen and (min-width: 768px) {
        font-size:1rem;
        padding:1rem;
    }
}
.is-not-expandable .teaser-wide__title > a {
    pointer-events: none;
}

.teaser-wide__title > a {
    color:inherit;
    text-decoration: none;
}

.teaser-wide__contactimg {
    @media screen and (max-width: 767px) {
        margin-bottom:1rem;
    }
}
.teaser-wide__bottom {
    padding:.5rem 1rem;
    position: relative;
    background: #fff;
}
.teaser-wide--has-border {
    box-shadow: 0 0 0 3px var(--color-primary);
}


.expandable-teaser.is-expandable .teaser-wide__collapse {
    height:145px;
    position: relative;
    overflow: hidden;
    @media screen and (max-width: 767px) {
        height:50px;
    }
}

.expandable-teaser--with-price.is-expandable .teaser-wide__collapse {
    height:135px;
    @media screen and (max-width: 767px) {
        height:50px;
    }
}

.expandable-teaser.is-collapsed .teaser-wide__collapse {
    height:auto !important;
    overflow: visible;
}
.expandable-teaser.is-expandable .teaser-wide__collapse:before {
    content:'';
    height:80px;
    position: absolute;
    bottom:0px;
    left:0;
    right:0;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+1,1+100 */
    background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 1%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 1%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 1%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
    @media screen and (max-width: 767px) {
        height:30px;
    }
}
.teaser-wide__body.is-collapsed .teaser-wide__collapse:before {
    display: none;
}
.expandable-teaser .icon--expand {
    display: none;
}
.expandable-teaser.is-expandable .icon--expand {
    display: inline-block;
    font-size:1.25rem;
    position: absolute;
    top:.125rem;
    right: 1.5rem;
    transform: rotate(-90deg);
}
.expandable-teaser.is-collapsed .icon--expand {
    transform: rotate(90deg);
}
/* teaser hover */
.teaser__img {
    transition: transform 250ms ease-in-out;
    transform-origin: center;
}
.teaser:hover .teaser__img {
    transform: scale(1.03);
}
.teaser__img-slider {
    line-height: 0;
}
.teaser__img-slider:not(.slick-initialized) > div + div {
    display: none;
}