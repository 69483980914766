.linklist-container {
    padding:2.25rem 1.25rem 1.25rem 1.25rem;
    @media screen and (min-width: 768px) {
        padding:3.25rem 1.5rem 1.5rem 1.5rem;
    }
}
.linklist__heading {
    font-size:1.75rem;
    font-family: var(--font-special);
    text-align: center;
    margin-bottom:2rem;

    @media screen and (min-width: 768px) {
        font-size:2.5rem;
    }
}
.linklist__heading .icon {
    display: block;
    margin:0 auto 1rem auto;
    font-size:3rem;
}
.linklist__item {
    margin-top:1.5rem;
    margin-bottom:1.5rem;
}
.linklist__item:hover,
.linklist__item:focus {
    text-decoration: none;
    color:var(--color-dark);
}