.nav-tabs {
    border: 0;
}
.nav-tabs--with-margin {
    margin:1.5rem 0;

    @media screen and (min-width: 768px) {
        margin:3.5rem 0;
    }
}
.nav-tabs .nav-link {
    position: relative;
    display: inline-block;
    padding: 0 0 .125rem 0;
    margin: 0;
    border: 0;
    border-radius: 0;
    font-size:.875rem;
    font-family: var(--font-special);
    color:var(--color-dark);

    @media screen and (min-width: 768px) {
        font-size:1.25rem;
    }
}
.nav-tabs .nav-link.active {
    cursor: text;
}
.nav-tabs .nav-link + .nav-link {
    margin-left:2rem;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active, .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
    color:var(--color-dark);
    background-color: transparent;
    border: 0;
}
.nav-tabs .nav-item.show .nav-link:after, .nav-tabs .nav-link.active:after, .nav-tabs .nav-link:hover:after, .nav-tabs .nav-link:focus:after {
    content:'';
    position: absolute;
    left:0;
    right: 0;
    bottom:0;
    height:2px;
    width:auto;
    background:var(--color-highlight);
}
