.pagination {
    display: block;
    position: relative;
    margin:2rem auto;
}
.pagination > li,
.page-item {
    display: inline-block;
}
.page-link {
    display: inline-block;
    font-size:.875rem;
    padding: 1.125rem .875rem;
    margin:.125rem;
    line-height: 1px;
    color: var(--color-text-muted);
    background-color: #fff;
    border: 0;
}
.page-link:hover,
.page-link:focus {
    color: var(--color-dark);
    text-decoration: none;
    background-color: #fff;
}
.page-link a:hover,
.page-link a:focus {
    color: var(--color-dark);
    text-decoration: none;
}
.page-link--icon {
    border-radius: 0 !important;
    font-size: 1.125rem;
    color:#A9BF00;
    padding: 1rem .875rem 1.25rem .875rem;
}