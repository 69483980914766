.header-lang .header-lang-content {
    display: none;
}
.header-lang.active .header-lang-content {
    display: block
}

.header-lang__toggle {
    font-size:1.5rem;
}
html:not(.has-preloaded-fonts) .header-lang__toggle {
    font-size: 1.4rem;
    letter-spacing: -0.03em;
}

.header-lang__toggle:hover,
.header-lang__toggle:focus {
    color:#fff;
    text-decoration: none;
}
/*.header-lang__toggle:after {
    content:'';
    position: absolute;
    right:0;
    top: 55px;
    margin:0;
    width:8px;
    height:8px;
    border:1px solid #fff;
    border-width:1px 1px 0 0 ;
    transform: rotate(135deg);
}*/
.header-lang-content {
    position: absolute;
    top:100%;
    width:70px;
    right:-10px;
    padding:1rem .25rem;
    background: var(--color-black-85);
    margin-top:26px;
}
.header-lang-content a {
    text-align: center;
    display: inline-block;
    padding: .25rem 1.25rem;
    color: #fff;
}
.header-lang-content a:hover,
.header-lang-content a:focus {
    text-decoration: none;
    color: #F4D100;
}