header {
    position: fixed;
    left:0;
    right:0;
    top:0;
    z-index:900;
    height: 100px;
    background: linear-gradient(180deg, rgba(0,0,0,.45) 0%, rgba(0,0,0,0) 100%);
    font-family: var(--font-special);
    transition: all 250ms ease;
}
header.is-affix {
    background:var(--color-black-60);
}
.toggled-overlay header,
.nav-open header {
    background:var(--color-black-85);
    border-bottom:1px solid #fff;
}
.navbar-brand {
    position: absolute;
    right:0;
    top:-16px;
    margin: 0;
    padding: 0;
    display: inline-block;
    width:160px;
@media screen and (min-width: 1200px) {
    width:200px;
}
}
.navbar-brand--default {
@media screen and (min-width: 1200px) {
    min-height: 151px;
}
}
.navbar-brand--white {
    top:23px;
    display: none;

@media screen and (min-width: 1200px) {
    top:18px;
    min-height: 55px;
}
}

.navbar-brand--default-old {
    top:8px;
}
.toggled-overlay .navbar-brand--default,
body.nav-open .navbar-brand--default,
header.is-affix .navbar-brand--default {
    display: none;
}
.toggled-overlay .navbar-brand--white,
body.nav-open .navbar-brand--white,
header.is-affix .navbar-brand--white {
    display: inline-block;
}


/* mobile */
@media screen and (max-width: 767px) {
    header {
        height: 70px;
    }
    header.is-affix {
        transform: translateY(-20px);
    }
    header .navbar {
        position: static;
    }
    .nav-container {
        padding-right:120px;
    }
    /*    .navbar-brand:before {
            content:'';
            position: absolute;
            left: 3px;
            right: 3px;
            top: -1px;
            height:35px;
            background: #fff;
        }*/
    header.is-affix .navbar-brand:before {
        display: none;
    }
    body.nav-open .navbar-brand:before,
    header.is-affix .navbar-brand:before {
        display: none;
    }
    .toggled-overlay .navbar-brand--white,
    body.nav-open .navbar-brand--white,
    header.is-affix .navbar-brand--white {
        transform: translateY(15px);
    }
    header.is-affix .navbar-brand--white {
        transform: translateY(25px);
    }
    .navbar-brand {
        right:15px;
        top: 0;
        transform: translateY(-10px);
        width:110px;
    }
    .navbar-brand--default {
        min-height: 87px;
    }
    .navbar-brand--white {
        min-height: 31px;
    }
    .navbar-brand--default-old {
        top:8px;
        transform: translateX(0);
    }
}
