/* row--same-height */
.row--same-height__item {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
}
/*
 * the .row--same-height selector has to be after
 * .row--same-height__item to increase the specificity
 * in case of chaining the two selectors
 * (which is needed for nested rows)
 */
.row--same-height,
.row--same-height.slick-slider .slick-track {
    display: flex;
    flex-direction: row;
}
.row--same-height {
    flex-wrap: wrap;
}
.row--same-height.slick-slider {
    display: block;
}
.row--same-height:before,
.row--same-height:after {
    display: none;
}

.row--same-height > [class^="col"],
.row--same-height > [class*=" col"] {
    display: flex;
    flex-direction: column;
    height: auto;
}




/* teaser */
.slider--same-height-teasers .slick-slide {
    flex-grow: 1;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    height: auto;
}

.slider--same-height-teasers .slick-slide > div {
    display: flex;
    flex: 1 0 auto;
    height: auto;
}

.slider--same-height-teasers .teaser {
    display: flex;
    flex-direction: column;
    flex: auto;
}

.slider--same-height-teasers .teaser .teaser__body {
    display: flex;
    flex-direction: column;
    flex: auto;
    height: auto;
}

.slider--same-height-teasers .teaser {
    height:100%;
}