.campaing-header {
    /*background:#fff;*/
}
.campaing-header__back {
    margin-top:2.25rem;
    color:#fff !important;
    font-family: var(--font-special);
    display: inline-block;

    @media screen and (max-width: 767px) {
        margin-top:1.75rem;
    }
}

header.is-affix .campaing-header__back {
    @media screen and (max-width: 767px) {
        margin-top:2.125rem;
    }
}
.campaing-header__back>.icon {
    font-size:1.75rem;
    line-height:1px;
    position: relative;
    top:.375rem;
    margin-right:.125rem;
}