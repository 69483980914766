@font-face {
    font-family: 'KievitWebPro';
    src: url('static/fonts/KievitWebPro.woff2') format('woff2'),
    url('static/fonts/KievitWebPro.woff') format('woff');
    font-weight: normal;
    font-display: swap;
}
@font-face {
    font-family: 'KievitWebPro-Bold';
    src: url('static/fonts/KievitWebPro-Bold.woff2') format('woff2'),
    url('static/fonts/KievitWebPro-Bold.woff') format('woff');
    font-weight: bold;
    font-display: swap;
}
@font-face {
    font-family: 'suedtirolnext-regular-webfont';
    src: url('static/fonts/suedtirolnext-regular-webfont.woff2') format('woff2'),
    url('static/fonts/suedtirolnext-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-display: fallback;
}