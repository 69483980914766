.quick-filter {
    margin-bottom: .625rem;
    border: 0;
    box-shadow: none;
    background: #fff;
    color: #ACB6BD;
    position: relative;
    padding: calc(9rem / 16) calc(15rem / 16) calc(9rem / 16) calc(33rem / 16);
    cursor: pointer;
    transition: background 200ms ease-in-out, color 200ms ease-in-out;
    outline: 0 !important;

    @media screen and (max-width: 767px) {
        font-size:.875rem;
        padding: calc(8rem / 16) calc(15rem / 16) calc(8rem / 16) calc(33rem / 16);
    }
}
.quick-filter--rounded {
    border-radius: .25rem;
}


/* outline */
.quick-filter--outline {
    background: transparent;
    color:var(--color-primary);
    border:2px solid var(--color-primary);
    border-radius: .25rem;
}
li:hover .quick-filter--outline:not(.quick-filter--disabled),
.quick-filter.quick-filter--outline:hover,
.quick-filter.quick-filter--outline:focus {
    color:#fff;
    background:var(--color-primary);
    border:2px solid var(--color-primary);
}
.quick-filter--outline .quick-filter__nr {
    color: #fff;
    background:var(--color-primary);
}
li:hover .quick-filter--outline:not(.quick-filter--disabled) .quick-filter__nr,
.quick-filter.quick-filter--outline:focus .quick-filter__nr,
.quick-filter.quick-filter--outline:hover .quick-filter__nr {
    color: var(--color-primary);
    background:#fff;
}

.search-quickfilter__cat .quick-filter__nr.quick-filter__nr {
    position: absolute;
    right:0;
    top:0;
    color: #fff;
    background:var(--color-primary);
}
.search-quickfilter__cat .quick-filter--active .quick-filter__nr.quick-filter__nr {
    background:var(--color-secondary);
}

/* outline active */
li:hover .quick-filter--outline.quick-filter--active .quick-filter__nr,
.quick-filter--outline.quick-filter--active:hover .quick-filter__nr,
.quick-filter--outline.quick-filter--active:focus .quick-filter__nr,
.quick-filter--outline.quick-filter--active .quick-filter__nr {
    color: var(--color-secondary);
}
li:hover .quick-filter--outline.quick-filter--active,
.quick-filter--outline.quick-filter--active:hover,
.quick-filter--outline.quick-filter--active:focus,
.quick-filter--outline.quick-filter--active {
    background: var(--color-secondary);
    color: #fff;
    border:2px solid var(--color-secondary);
}


/* outline diabled */
.quick-filter--outline.quick-filter--disabled .quick-filter__nr,
.quick-filter--outline.quick-filter--disabled .quick-filter__nr {
    color: #fff;
    background: #ACB6BD;
}
.quick-filter--outline.quick-filter--disabled {
    cursor: not-allowed;
    pointer-events: none;
    color: #ACB6BD;
    opacity: .75;
    border:2px solid #ACB6BD;
}



.quick-filter--md {
    padding: calc(14rem / 16) calc(20rem / 16) calc(14rem / 16) calc(33rem / 16);
}
.quick-filter:hover {
    background: #ACB6BD;
    color: #fff;
}
.quick-filter:not(:last-child) {
    margin-right: calc(10rem / 16);
}
.quick-filters--right .quick-filter {
    margin-left: calc(10rem / 16);
    margin-right: 0;
}
.quick-filter__icon {
    font-size: calc(12rem / 16);
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
}
.quick-filter--active .quick-filter__icon:before {
    content:var(--icon-checkmark);
}
.quick-filter__nr {
    font-size: calc(10rem / 16);
    position: relative;
    top:-2px;
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    text-align: center;
    color: #fff;
    background:var(--color-text-muted);
    border-radius: 50%;
    margin-left: .5rem;
}
.quick-filter--active .quick-filter__nr,
.quick-filter:focus .quick-filter__nr,
.quick-filter:hover .quick-filter__nr {
    color:var(--color-text-default);
    background: #fff;
}

/* subcategory dropdown */
.search-quickfilter__list:hover .search-quickfilter__dropdown {
    display: block;
}
.search-quickfilter__dropdown {
    display: none;
    z-index: 30;
    position: absolute;
    top:100%;
    margin-top:-.625rem;
    padding:.25rem .5rem;
    left:0;
    text-align: left;
    min-width: 13rem;
    /*border: 2px solid var(--color-primary);*/
    border-radius: calc(4rem/16);
    background: #fff;
    box-shadow: 2px 6px 10px 1px rgba(0,0,0,0.35);
}
.search-quickfilter__dropdown.is-open {
    display: block;
}
.search-quickfilter__cat a {
    display: block;
    position: relative;
    padding:.25rem 2.25rem .25rem .5rem;
    margin:.25rem 0;
    font-size: calc(16rem/16);;
    color:var(--color-primary);
    text-decoration: none;
    white-space: nowrap;
}
.search-quickfilter__cat a.is-selected {
    color:var(--color-secondary);
}
.search-quickfilter__cat a:hover,
.search-quickfilter__cat a:focus {
    color:var(--color-text-default);
}

.search-quickfilter__cat-icon {
    font-size: .625rem;
    margin-right: .5rem;
}
.search-quickfilter__cat a.is-selected .search-quickfilter__cat-icon:before {
    content:var(--icon-checkmark);
}


/* mobile subfilter */
.xs-search-checkboxgroup {
    font-size: .95rem;
    margin:.5rem 0 1rem 0;
}