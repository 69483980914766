.mountain-weather {
    position: relative;
    height: 0;
    padding-top: calc(266/534*100%); /*bg image size*/
    background-image: url(static/img/content-widgets/mountain-weather.png);
    background-size: cover;
}
.mountain-weather--small {
    font-size: .85em;
}
.mountain-weather__line {
    position: absolute;
    right: 30px;
    left: 30px;
    top: 0;
    padding-bottom: 2px;
    border-bottom: 1px solid #b3b1b1;
    transform: translateY(-50%);
}
.mountain-weather__line:nth-child(1) {
    top: 12%;
}
.mountain-weather__line:nth-child(2) {
    top: 34%;
}
.mountain-weather__line:nth-child(3) {
    top: 56%;
}
.mountain-weather__line:nth-child(4) {
    top: 78%;
}

.mountain-weather__temp {
    color: #333333;
    line-height: 1;
}
@media screen and (min-width: 768px) {
    .mountain-weather__temp {
        font-size: calc(24 / 16 * 1em);
    }
}
.mountain-weather__wind {
    position: absolute;
    top: 15%;
    left: 50%;
    width: 110px;
    text-align: center;
    transform: translateX(-50%);
    font-size: calc(14 / 16 * 1em);
}

@media screen and (min-width: 768px) {
    .mountain-weather__wind {
        top: 20%;
        font-size: calc(18 / 16 * 1em);
    }
}
.mountain-weather__wind-icon {
    font-size: 40px;
}
.mountain-weather--small .mountain-weather__wind-icon {
    font-size: 30px;
}
@media screen and (min-width: 768px) {
    .mountain-weather__wind-icon {
        font-size: 63px;
    }
    .mountain-weather--small .mountain-weather__wind-icon {
        font-size: 40px;
    }
}