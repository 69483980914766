.google-map {
    position: relative;
    /*height: 350px;*/
    background: #f0f1f1;
    z-index: 0;
}
.google-map__controls {
    position: absolute;
    top: 9px;
    left: 8px;
}
.google-map__controls > .btn {
    margin-bottom: 10px;
}
.google-map__control-box {
    position: absolute;
    top: 9px;
    left: 8px;
    background: #fff;
    padding: 5px;
    padding-right: 10px;
    font-size: .8em;
    border-radius: 2px;
}
/*.google-map.is-fullscreen,*/
.google-map.is-fullscreen .google-map__canvas{
    position: fixed;
    /*header size*/
    top: 205px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
}
.google-map--fullscreen {
    position: fixed;
    /*header size*/
    top: 205px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 91;
}
.google-map--fullscreen>.google-map__canvas {
    width: 100%;
    height: 100%;
}

/*@media screen and (max-width: 767px) {
    .google-map.row--same-height__item__variable-height {
        height: 300px;
    }
}*/
.google-map__overlay {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 800px;
    padding: 20px;
    max-width: 100%;
    background: #fff;
}

/*leaflet*/
.leaflet-popup-content-wrapper.leaflet-popup-content-wrapper {
    border-radius: 0;
}

/*leaflet marker cluster*/
.marker-cluster-small {
    background-color: var(--color-success);
}
.marker-cluster-small div {
    background-color: #fff;
}

.marker-cluster-medium {
    background-color: var(--color-success);
}
.marker-cluster-medium div {
    background-color: #fff;
}

.marker-cluster-large {
    background-color: var(--color-success);
}
.marker-cluster-large div {
    background-color: #fff;
}

/* IE 6-8 fallback colors */
.leaflet-oldie .marker-cluster-small {
    background-color: rgb(181, 226, 140);
}
.leaflet-oldie .marker-cluster-small div {
    background-color: rgb(110, 204, 57);
}

.leaflet-oldie .marker-cluster-medium {
    background-color: rgb(241, 211, 87);
}
.leaflet-oldie .marker-cluster-medium div {
    background-color: rgb(240, 194, 12);
}

.leaflet-oldie .marker-cluster-large {
    background-color: rgb(253, 156, 115);
}
.leaflet-oldie .marker-cluster-large div {
    background-color: rgb(241, 128, 23);
}

.marker-cluster {
    background-clip: padding-box;
    border-radius: 20px;
}
.marker-cluster div {
    width: 30px;
    height: 30px;
    margin-left: 5px;
    margin-top: 5px;

    text-align: center;
    border-radius: 15px;
    font: 12px "Helvetica Neue", Arial, Helvetica, sans-serif;
}
.marker-cluster span {
    line-height: 30px;
}
.leaflet-cluster-anim .leaflet-marker-icon, .leaflet-cluster-anim .leaflet-marker-shadow {
    transition: transform 0.3s ease-out, opacity 0.3s ease-in;
}
.leaflet-cluster-spider-leg {
    transition: stroke-dashoffset 0.3s ease-out, stroke-opacity 0.3s ease-in;
}
