/*Datepicker*/
.ui-datepicker {
    font-family: inherit;
    background: #ffffff;
    border: 1px solid #d4d4d4;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.27);
    /*border-radius: 2px;*/
    padding: 0;
    width: 395px;
    /*override inline style*/
    z-index: 99999 !important;

    @media screen and (max-width: 767px) {
        width: 280px;
    }
}

/* show initial calendar with dates */
.datepicker-active-dates .ui-datepicker.ui-datepicker {
    display: block;
    width: 100%;
}

.ui-datepicker .ui-datepicker-header {
    border-bottom: 0;
    font-size: 1rem;
    padding: 0.6em;
    @media screen and (max-width: 767px) {
        font-size: .875rem;
    }
}
/*.ui-datepicker .ui-datepicker-title {
    color: var(--color-secondary);
}*/
.ui-datepicker table {
    width: 94%;
    margin:5px auto 10px auto;
    font-size: 1rem;
    table-layout: fixed; /*equal sizes*/
    @media screen and (max-width: 767px) {
        font-size: .875rem;
    }
}


/*@media screen and (max-width: 767px) {*/
    /*.ui-datepicker {*/
        /*max-width: 280px;*/
    /*}*/
/*}*/

.ui-datepicker td {
    color: #9d9d9c;
    padding: 1px;
    width: 1%; /*equal sizes*/
}
.ui-datepicker td a,
.ui-datepicker td span {
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
}
.ui-datepicker.ui-datepicker th {
    font-weight: normal;
    font-family: var(--font-default);
    color: #333;
}


.ui-datepicker table .ui-state-hover {
    color: #000;
}
.ui-datepicker table .ui-state-highlight {
    background: var(--color-light-grey);
}
.ui-datepicker table .ui-state-available>a {
    background: var(--color-light-grey);
    color: #000;
    border-radius: 0;
}
.ui-datepicker table .ui-state-highlight>a {
    background: var(--color-highlight);
    color: #fff;
}
.ui-datepicker table .ui-state-available .ui-state-hover,
.ui-datepicker table .ui-state-available .ui-state-highlight,
.ui-datepicker table .ui-state-available .ui-state-active {
    background: var(--color-primary);
    color: #ffffff;
    border-radius: 0;
}

.ui-datepicker .ui-state-disabled {
    color: #dddddd;
}
.ui-datepicker .is-datefrom,
.ui-datepicker .is-dateto,
.ui-datepicker .is-in-range{
    color: #fff;
    background-color: rgba(197,211,96,1)
}
.ui-datepicker .is-datefrom{
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
}
.ui-datepicker .is-datefrom .ui-state-highlight{
    background: transparent !important;
}
.ui-datepicker .is-dateto{
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
}

.ui-datepicker .ui-datepicker-next,
.ui-datepicker .ui-datepicker-prev {
    text-align: center;
    line-height: 1.8em;
    cursor: pointer;
    top: 10px;
    color: var(--color-secondary);
}
.ui-datepicker-next:hover,
.ui-datepicker-prev:hover {
    text-decoration: none;
}

.ui-datepicker-prev:before {
    font-family: iconfont;
    content: var(--icon-arrow-left);
}

.ui-datepicker-next {
    transform:scaleX(-1);
}
.ui-datepicker-next:after {
    font-family: iconfont;
    content: var(--icon-arrow-left);
}
