@media screen and (max-width: 767px) {
    .booking-bar-container {
        background: var(--color-secondary);
        color: #FFFFFF;
    }
}

.booking-bar {
    position: relative;
    /*color: #ffffff;*/
    padding: 20px 0;
    border-radius: 8px;
}
.booking-bar--full-width {
    max-width: 100%;
}

@media screen and (min-width: 768px) {
    .booking-bar {
        padding-left: 40px;
        padding-right: 40px;
        background: var(--color-secondary);
        color: #ffffff;
        /*padding: 20px 40px;*/
        /*border-radius: 2px;*/
    }
    .booking-bar--narrow {
        padding-left: 20px;
        padding-right: 20px;
    }
}

/*.booking-bar-inquiry {
    background-color:#f0f0f0;
    padding: 1rem;
    margin-bottom: 2rem;
}
.booking-bar-inquiry .form-group {
    margin-bottom: 0;
}
.booking-bar-inquiry .form-control {
    background-color: #FFFFFF;
}*/
