.trip-planer-teaser {
    position: relative;
    max-width: 248px;
    margin: auto;
    margin-top: 155px;
    border: none;
    min-height: 270px;
}
.trip-planer-teaser:before {
    content: '';
    display: block;
    position: absolute;
    bottom: 96.3%;
    left: 50%;
    margin-left: -35px;
    width: 71px;
    height: 120px;
    background: url("static/img/tripplaner/teaser-hacken.png");
    z-index: 1;
}
.trip-planer-teaser:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -35px;
    left: 50%;
    margin-left: -185px;
    width: 370px;
    height: 7px;
    background: url("static/img/tripplaner/shadow.png");
    z-index: 1;
}