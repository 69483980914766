a {
    color: inherit;
}

textarea {
    resize: vertical;
}

abbr[title] {
    border-bottom: none;
}

/* -> Der Luki wars */
.panel-idm>.panel-heading{
    background-color: #de7000;
}

.hr--dark {
    border-color: #c2c7ca;
}

dt {
    font-weight: inherit;
}