.form-group--inline {
    display: inline-block;
}

form label {
    margin-bottom:.25rem;
}

.form-control--salutation {
    min-width: 140px;
}

.form-control {
    padding: .75rem;
    height: auto;
    color: var(--color-text-default);
    border: 1px solid #fff;
    border-radius: 0;
    background: #fff;
    -webkit-appearance: none;
}
.hasDatepicker {
    cursor: pointer;
}
.form-control--lg {
    font-size: 1.25rem;
    @media screen and (max-width: 767px) {
        font-size: 1.1rem;
    }
}
.form-control.form-control--lightgrey {
    background-color: var(--color-light-grey);
    color: var(--color-dark);
}
.form--dark .form-control {
    background-color: #f0f0f0;
    border: 1px solid #f0f0f0;
}
.form-control--has-border {
    border: 1px solid black;
}
@media screen and (max-width: 767px) {
    .form-control.form-control--xs-transparent {
        background:transparent;
        background-color:transparent;
        border-color:transparent;
        color:#fff;
        padding-left:0 !important;
    }
    .form-control.form-control--xs-transparent-bordered {
        border-bottom:1px solid #fff;
        padding-left:1rem !important;
    }
}

/*.form-control:disabled, .form-control[readonly] {
    background: #fff;
}*/

.form-control:active,
.form-control:focus {
    border-color: transparent;
}

/*Form controls with icons*/
.form-control-wrapper {
    position: relative;
}

.form-control-wrapper--with-icon .form-control {
    padding-right: 35px;
}

.form-control-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    z-index: 2;
    pointer-events: none;
    font-size: 1rem;
    line-height:1px;
    transform: translateY(-50%);
}

/*Errors*/
.has-error .form-control {
    border-color: var(--color-danger);
}
.has-error .form-control-icon {
    margin-top:-17px;
}

.form-group--transparent .has-error .form-control {
    border-color: var(--color-danger-light);
}

.form-errors {
    color: var(--color-danger);
}
.parsley-errors-list.filled {
    list-style: none;
    padding: .2rem 0 .4rem;
    margin: 0;
    color: var(--color-danger);
    text-align: right;
}
.form-errors--centered > .parsley-errors-list {
    text-align: center;
}

.form-group--transparent .form-errors > .parsley-errors-list,
.form-errors--light > .parsley-errors-list {
    color: var(--color-danger-light);
}

.custom-checkbox .form-errors > .parsley-errors-list {
    text-align: left;
    @media screen and (min-width: 768px) {
        text-align: right;
        padding: 0 0 0 .4rem;
    }
}

/* floating labels */
.form-group {
    position: relative;
}
.filter-form .form-group {
    margin-bottom: .5rem;
}
.floating-label__label {
    color: #9b9b9b;
    position: absolute;
    left: 1.25rem;
    top: .875rem;
    transition: transform 200ms ease-in-out;
    transform-origin: left top;
    pointer-events: none;
    z-index: 1;
}

.floating-label__input,
.floating-label__textarea,
.floating-label__select,
.custom-select.floating-label__select {
    padding: 1.25rem .75rem .75rem 1.125rem;
}

.floating-label__select,
.custom-select.floating-label__select {
    padding-top: 1.2rem;
}

.floating-label__input:focus ~ .floating-label__label,
select ~ .floating-label,
textarea:focus ~ .floating-label,
textarea.has-value ~ .floating-label,
.floating-label__input.has-value ~ .floating-label__label,
.floating-label__input:-webkit-autofill ~ .floating-label__label,
.floating-label__input:-webkit-autofill:focus ~ .floating-label__label {
    transform: translateY(-33%) scale(.66);
    color: var(--color-text-default) !important;

    /*@media screen and (min-width: 768px) {
        transform: translateY(-62%) scale(.68);
    }*/
}

/* floating label in textarea */
textarea ~ .floating-label__label {
    top: 1rem;

    /*@media screen and (min-width: 768px) {
        top: 1.5rem;
    }*/
}

/* transparent version */

.form-group--login .floating-label__input ,
.form-group--transparent .floating-label__input {
    background: transparent;
    height: 50px;
    border: 1px solid #fff;
    color: #fff;
    padding: 1.5rem .75rem .5rem 1.125rem;
}

.form-group--login .floating-label__label,
.form-group--transparent .floating-label__label {
    color: #fff !important;
}

.form-group--transparent .form-control {
    background: transparent;
    color: #fff;
}

.form-group--transparent .form-control option:not(:disabled) {
    color: #000;
}

.form-group--transparent .form-control:active, .form-control:focus {
    border-color: #fff;
}

.form-group--transparent .custom-checkbox__box {
    background: transparent;
    border-color: #fff;
}