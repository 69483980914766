.custom-radio.custom-radio {
    position: relative;
    padding-left: 30px;
    margin-bottom: 0;
    line-height: 20px;
}
.custom-radio.custom-radio--no-padding {
    padding-left: 0;
}
.custom-radio.custom-radio--md-large {
    padding-left: 0;
    @media screen and (min-width: 768px) {
        padding-left: 56px;
    }
}
.custom-radio label {
    margin-bottom: 0;
}

.form-inline .custom-radio.custom-radio {
    padding-left: 30px;
}
.custom-radio__input {
    display: none;
}
.custom-radio__fake-radio {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 1px solid #bccdda;
    background: #fff;
    border-radius: 50%;
}
.has-error .custom-radio__fake-radio {
    border-color: #ab1537;
}
.custom-radio__fake-radio__dot {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 8px;
    width: 8px;
    background: var(--color-primary);
    border-radius: 50%;
    transform: translate(-50%, -50%);
    visibility: hidden;
}
.custom-radio__input:checked + .custom-radio__fake-radio > .custom-radio__fake-radio__dot {
    visibility: visible;
}
.custom-radio--full-width {
    width: 100%;
}

.custom-radio--color-secondary .custom-radio__fake-radio__dot {
    background: var(--color-secondary);
}

.custom-radio:before,
.custom-radio:after {
    content: " ";
    display: table;
}.custom-radio:after {
     clear: both;
 }
.custom-radio__input {
    position: absolute;
    width: calc(1rem/16);
    height: calc(1rem/16);
    margin: calc(-1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
}

.custom-radio__box {
    position: relative;
    float: left;
    margin-right: calc(7rem/16);
    overflow: hidden;
    background: #ffffff;
    width: calc(16rem/16);
    height: calc(16rem/16);
    margin-top: .1em;
    border: 1px solid #fff;
    border-radius: 50%;
}
.custom-radio--grey .custom-radio__box {
    background: var(--color-light-grey);
}
.custom-radio__box:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    height: 60%;
    border-radius: 50%;
    background: var(--color-secondary);
    visibility: hidden;
}
.custom-radio label:hover > .custom-radio__box,
.custom-radio__input:checked + .custom-radio__box{
    border-color: #97b3c8;
}

.custom-radio__input:checked ~ .custom-radio__box:before {
    visibility: visible;
}
.custom-radio__input:focus ~ .custom-radio__box {
    border-color: var(--color-primary);
}
.has-error .custom-radio__box {
    border-color: var(--color-danger);
}
