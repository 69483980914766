.wysiwyg {
    overflow-x: auto;
    min-height: .01%;
}
.wysiwyg a {
    text-decoration: underline;
}
.wysiwyg p,
.wysiwyg ul,
.wysiwyg ol {
    line-height:1.75;
    margin-bottom: 2rem;
}

.wysiwyg p:last-child,
.wysiwyg ul:last-child,
.wysiwyg ol:last-child {
    margin-bottom: 0;
}

.wysiwyg h1, .wysiwyg .h1 {
    margin:0 0 1.5rem 0;
    @media screen and (min-width: 768px) {
        margin:0 0 2rem 0;
    }
}
.wysiwyg h2, .wysiwyg .h2,
.wysiwyg h3, .wysiwyg .h3,
.wysiwyg h4, .wysiwyg .h4 {
    margin:2rem 0 1.5rem 0;
    @media screen and (min-width: 768px) {
        margin:2rem 0 2rem 0;
    }
}
.wysiwyg h5, .wysiwyg .h5,
.wysiwyg h6, .wysiwyg .h6{
    margin:2rem 0 1.25rem 0;
    @media screen and (min-width: 768px) {
        margin:2rem 0 1.5rem 0;
    }
}

.wysiwyg img {
    max-width: 100%;
    height: auto !important; /*override inline style from wysiwyg editor*/
}
.color-box .wysiwyg h1,
.color-box .wysiwyg h2,
.color-box .wysiwyg h3,
.color-box .wysiwyg h4,
.color-box .wysiwyg h5,
.color-box .wysiwyg h6 {
    margin-top:0 !important;
}
.lead {
    font-size: inherit;
    line-height:1.75;
    font-weight: inherit;
}
html:not(.has-preloaded-fonts) .lead {
    line-height: 1.86;

    @media screen and (max-width: 767px) {
        line-height: 2;
    }
}


@media screen and (min-width: 768px) {
    .wysiwyg .multicol-list {
        column-count: 2;
        column-gap: 30px;
    }
    .wysiwyg .multicol-list--3 {
        column-count: 3;
        column-gap: 30px;
    }
    .wysiwyg .multicol-list--4 {
        column-count: 4;
        column-gap: 30px;
    }
}