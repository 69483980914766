.table td, .table th {
    padding: .75rem .5rem;
    vertical-align: top;
    border: 1px solid #C7CED2;
    border-width:0 0 1px 0;
    @media screen and (min-width: 768px) {
        padding: 1.75rem 1rem;
    }
}
.table thead th {
    font-size:18px;
    vertical-align: top;
    font-weight:normal;
    font-family: var(--font-special);
    border-bottom: 1px solid #C7CED2;
}
.table--opening-hours {
    position: relative;
    margin-top:-1rem;
}
.table--opening-hours td, .table--opening-hours th {
    font-size:1rem;
    padding: .25rem;
    vertical-align: middle;
    @media screen and (min-width: 768px) {
        padding: .25rem;
    }
}
.table--opening-hours th {
    font-size:1.125rem;
    vertical-align: middle;
}
.opening-status {
    margin-top:.25rem;
    font-size:.875rem;
}
.opening-text--open,
.opening-status--open {
    color:#A9BF00;
}
.opening-text--closed,
.opening-status--closed {
    color:var(--color-highlight);
}
@media screen and (max-width: 767px) {
    .table--mobile-block,
    .table--mobile-block tbody,
    .table--mobile-block tr,
    .table--mobile-block td,
    .table--mobile-block th {
        display: block;
    }
    .table--mobile-block {
        border: none;
    }
    .table--mobile-block thead {
        display: none;
    }
    .table--mobile-block>tbody>tr + tr {
        margin-top: 20px;
    }
    .table--mobile-block>tbody>tr>td {
        position: relative;
        padding: 0;
        padding-left: 150px;
        text-align: left;
        border: none;
        margin-bottom: 5px;
    }
    .table--mobile-block>tbody>tr>td:before {
        font-family: var(--font-default-bold);
        font-weight: var(--font-default-bold-weight);
        content: attr(data-title)':';
        position: absolute;
        top: 0;
        left: 0;
        right: 150px;
        bottom: 0;
    }
}

/* anlagen */
.table--anlagen td,
.table--anlagen th {
    text-align: center;
}
.table--anlagen td {
    font-size:.875rem;
    @media screen and (min-width: 768px) {
        font-size:.9375rem;
    }
}
.table--anlagen td:first-child,
.table--anlagen th:first-child {
    text-align:left;
}