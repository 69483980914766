.pimcore_area_header-slider {
    position: relative;
}

.heart-campaign {
    display: inline-block;
    text-align: center;
    text-decoration: none !important;
    position: relative;
    margin-top:5rem;
    z-index:10;

    @media screen and (max-width: 767px) {
        margin-top:2rem;
    }

    @media screen and (max-height: 800px) {
        margin-top:2rem;
    }
}
.heart-campaign__heart {
    display: block;
    margin: 0 auto;
    width: 141px;
    height:123px;
    background: url(static/img/relaunch2018/Sued_Herz_Outline_white.png) 50% 50% no-repeat;
    background-size: contain;
    z-index:10;

    @media screen and (max-width: 767px) {
        width: 100px;
        height:100px;
    }

    @media screen and (max-height: 800px) {
        width: 100px;
        height:100px;
    }
}

.heart-campaign__txt {
    color:#fff;
    text-shadow: 0 2px 4px rgba(0,0,0,.5);
}